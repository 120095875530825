import {Component} from 'react';
import axios from "axios"
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import myconfig from "../../myconfig";
import nt from "../../img/companylogo.jpg";
import orginal from "../../img/orginal.png";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { StickyContainer, Sticky } from 'react-sticky';
import Select from "react-select";
import style2 from "../../style2.css"

let source = axios.CancelToken.source();

class Finance_document extends Component {
    constructor() {
        super();
        this.state = {
            wiz: 0,
            data: {},
            company_profile: {},
            datas:[],
        };
    }

    componentDidMount() {
        this.fetchdata()
    }

    fetchdata = () => {
        let ref_no = new URLSearchParams(this.props.location.search).get("ref_no");
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_fright_for_finance_document;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            ref_no:ref_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(res.data.data==null){
                    this.setState({
                        data: {},
                        company_profile: {},
                        wiz:2,
                    })
                }
                else
                {
                    this.setState({
                        data: res.data.data,
                        company_profile: res.data.company_profile,
                        tap_no: res.data.tap_no,
                        datas: res.data.datas,
                        wiz:1,
                    })
                }

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    wiz_0 = () => {
        return (
            <div style={{minHeight: "100vh"}}>
                <p>Loading...</p>
            </div>
        )
    }
    wiz_1 = () => {
        let total=0;
        let tdata=this.state.datas.map(v=>{
            total=total+(v.amount*1);
            return <tr>
                <td> {v.payment_type}</td>
                <td> {v.req_by}</td>
                <td> {v.app_by}</td>
                <td> {v.ref_no}</td>
                <td> {v.amount_con}</td>
            </tr>
        })
        let tdata2=null;
        if(total>0) {
            tdata2 = <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-right"><strong>Total: </strong></td>
                <td> <strong>{Number(total).toFixed(2)}</strong></td>
            </tr>
        }
        return (
            <>
                <div className="row no-print">
                    <a href="" onClick={event => {
                        event.preventDefault();
                        window.print()
                    }}>Print</a>
                </div>
                <div id="section_to_print_3">
                    <div className="row">

                        <div className="col-2 text-center">
                            <img className="img w-50" src={nt}></img>
                        </div>
                        <div className="col-6 text-center">
                            <strong>{this.state.company_profile.company_name}</strong>
                        </div>
                        <div className="col-4 text-center">
                        </div>
                        <div className="col-12 text-center">
                            <br/>
                            <strong>TRAVEL ADVANCE PAYMENT REQUEST FORM</strong>
                            <h5>(ORIGINAL)</h5>
                        </div>
                        <div className="col-1">
                            <strong>Date:</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.dt}
                        </div>
                        <div className="col-1">
                            <strong>Plate No:</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.plate_no}
                        </div>
                        <div className="col-1">
                            <strong>Driver Name:</strong>
                        </div>
                        <div className="col-2">
                            {this.state.data.driver_name}
                        </div>
                        <div className="col-1">
                            <strong>FO #</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.fright_no}
                        </div>
                        <div className="col-1">
                            <strong>TAP #</strong>
                        </div>
                        <div className="col-1">
                            {this.state.tap_no}
                        </div>
                        <div className="col-1">
                        </div>
                        <br/>
                        <div className="col-12">
                            <table className="table table-bordered table-striped">
                                <tr>
                                    <th className="col-3">Payment Reason</th>
                                    <th className="col-2">Request By</th>
                                    <th className="col-2">Approved By</th>
                                    <th className="col-2">Reference Code</th>
                                    <th className="col-3">Payment Amount</th>
                                </tr>
                                {tdata}
                                {tdata2}
                            </table>
                            <br/>
                        </div>
                        <div className="col-3">
                            <p>Prepared By: </p>
                            <p><strong>{this.state.data.pre_by}</strong></p>
                        </div>
                        <div className="col-3">
                            <p>Signature</p>
                            <p>-----------------------</p><br/>
                        </div>
                        <div className="col-3">
                            <p>Approved By: </p>
                            <p>-----------------------</p>
                        </div>
                        <div className="col-3">
                            <p>Signature</p>
                            <p>-----------------------</p><br/>
                        </div>
                        <div className="col-12">
                            <hr/>
                        </div>
                    </div>
                    <div className="row" style={{fontSize:"14px"}}>
                        <div className="col-2 text-center">
                            <img className="img w-50" src={nt}></img>
                        </div>
                        <div className="col-6 text-center">
                            <strong>{this.state.company_profile.company_name}</strong>
                        </div>
                        <div className="col-4 text-center">
                        </div>
                        <div className="col-12 text-center">
                            <br/>
                            <strong>TRAVEL ADVANCE PAYMENT REQUEST FORM</strong>
                            <h5>(Copy)</h5>
                        </div>
                        <div className="col-1">
                            <strong>Date:</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.dt}
                        </div>
                        <div className="col-1">
                            <strong>Plate No:</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.plate_no}
                        </div>
                        <div className="col-1">
                            <strong>Driver Name:</strong>
                        </div>
                        <div className="col-2">
                            {this.state.data.driver_name}
                        </div>
                        <div className="col-1">
                            <strong>FO #</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.fright_no}
                        </div>
                        <div className="col-1">
                            <strong>TAP #</strong>
                        </div>
                        <div className="col-1">
                            0001
                        </div>
                        <div className="col-1">
                        </div>
                        <br/>
                        <div className="col-12">
                            <table className="table table-bordered table-striped">
                                <tr>
                                    <th className="col-3">Payment Reason</th>
                                    <th className="col-2">Request By</th>
                                    <th className="col-2">Approved By</th>
                                    <th className="col-2">Reference Code</th>
                                    <th className="col-3">Payment Amount</th>
                                </tr>
                                {tdata}
                                {tdata2}
                            </table>
                            <br/>
                        </div>
                        <div className="col-3">
                            <p>Prepared By: </p>
                            <p><strong>{this.state.data.pre_by}</strong></p>
                        </div>
                        <div className="col-3">
                            <p>Signature</p>
                            <p>-----------------------</p><br/>
                        </div>
                        <div className="col-3">
                            <p>Approved By: </p>
                            <p>-----------------------</p>
                        </div>
                        <div className="col-3">
                            <p>Signature</p>
                            <p>-----------------------</p><br/>
                        </div>
                        <div className="col-12">
                            <hr/>
                        </div>
                    </div>
                    <div className="row" style={{fontSize:"14px"}}>
                        <div className="col-2 text-center">
                            <img className="img w-50" src={nt}></img>
                        </div>
                        <div className="col-6 text-center">
                            <strong>{this.state.company_profile.company_name}</strong>
                        </div>
                        <div className="col-4 text-center">
                        </div>
                        <div className="col-12 text-center">
                            <br/>
                            <strong>TRAVEL ADVANCE PAYMENT REQUEST FORM</strong>
                            <h5>(Copy)</h5>
                        </div>
                        <div className="col-1">
                            <strong>Date:</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.dt}
                        </div>
                        <div className="col-1">
                            <strong>Plate No:</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.plate_no}
                        </div>
                        <div className="col-1">
                            <strong>Driver Name:</strong>
                        </div>
                        <div className="col-2">
                            {this.state.data.driver_name}
                        </div>
                        <div className="col-1">
                            <strong>FO #</strong>
                        </div>
                        <div className="col-1">
                            {this.state.data.fright_no}
                        </div>
                        <div className="col-1">
                            <strong>TAP #</strong>
                        </div>
                        <div className="col-1">
                            0001
                        </div>
                        <div className="col-1">
                        </div>
                        <br/>
                        <div className="col-12">
                            <table className="table table-bordered table-striped">
                                <tr>
                                    <th className="col-3">Payment Reason</th>
                                    <th className="col-2">Request By</th>
                                    <th className="col-2">Approved By</th>
                                    <th className="col-2">Reference Code</th>
                                    <th className="col-3">Payment Amount</th>
                                </tr>
                                {tdata}
                                {tdata2}
                            </table>
                            <br/>
                        </div>
                        <div className="col-3">
                            <p>Prepared By: </p>
                            <p><strong>{this.state.data.pre_by}</strong></p>
                        </div>
                        <div className="col-3">
                            <p>Signature</p>
                            <p>-----------------------</p>
                        </div>
                        <div className="col-3">
                            <p>Approved By: </p>
                            <p>-----------------------</p>
                        </div>
                        <div className="col-3">
                            <p>Signature</p>
                            <p>-----------------------</p><br/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    wiz_2 = () => {
        return (
            <div style={{minHeight: "100vh"}}>
                <p>No fright order found.</p>
            </div>
        )
    }

    render() {
        let dis = null;
        if (this.state.wiz === 0)
            dis = this.wiz_0()
        else if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <div className="container-fluid">
                    {dis}
                </div>
            </>
        )
    }
}

export default Finance_document;
