import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Welcome from "./Componet/Welcome";
import Login from "./Componet/Login";
import D_operation from "./Componet/Public_Dashboard/D_operation";
import User from "./Componet/User";
import ObjUser from "./Componet/ObjUser";
import User_template from "./Componet/User_template";
import Client_mgt from "./Componet/Management/Client_mgt";
import Client_type from "./Componet/Management/Client_type";
import Cargo_type from "./Componet/Management/Cargo_type";
import Admin_script from "./Componet/Management/Admin_script";
import Kpi_umo from "./Componet/Kpiplan/Kpi_umo";
import Kpi_description from "./Componet/Kpiplan/Kpi_description";
import Kpi_data from "./Componet/Kpiplan/Kpi_data";
import Vehicle from "./Componet/Assets/Vehicle";
import Driver from "./Componet/Assets/Driver";
import Trailer from "./Componet/Assets/Trailer";
import Location from "./Componet/Assets/Location";
import Road_route from "./Componet/Assets/Road_route";
import Road_route_open from "./Componet/Assets/Road_route_open";
import Fuel_rate from "./Componet/Assets/Fuel_rate";
import Status_delivery from "./Componet/Status/Status_delivery";
import Status_delivery_detail from "./Componet/Status/Status_delivery_detail";
import Order_open from "./Componet/Order/Order_open";
import Order_accept_fin from "./Componet/Order/Order_accept_fin";
import Order_current_status from "./Componet/Order/Order_current_status";
import Order_update from "./Componet/Order/Order_update";
import Assign_own from "./Componet/Operation/Assign_own";
import Update_allocate from "./Componet/Operation/Update_allocate";
import Document_mgt from "./Componet/Operation/Document_mgt";
import Void_fo from "./Componet/Operation/Void_fo";
import Main_temp from "./Componet/Operation/Main_temp";
import Delete_own from "./Componet/Operation/Delete_own";
import Veh_ava from "./Componet/Operation/Veh_ava";
import Cancel_allocate from "./Componet/Operation/Cancel_allocate";
import Op_refund_req from "./Componet/OP_Refund/Op_refund_req";
import Op_refund_app from "./Componet/OP_Refund/Op_refund_app";
import Op_trip_cancel_req from "./Componet/OP_Refund/Op_trip_cancel_req";
import Op_trip_cancel_app from "./Componet/OP_Refund/Op_trip_cancel_app";
import Op_trip_extend_req from "./Componet/OP_Refund/Op_trip_extend_req";
import Op_trip_extend_app from "./Componet/OP_Refund/Op_trip_extend_app";
import Op_reconcile from "./Componet/Opex/Op_reconcile";
import Follow_up from "./Componet/Controal/Follow_up";
import Follow_up_2 from "./Componet/Controal/Follow_up_2";
import Follow_up_hisotry from "./Componet/Controal/Follow_up_hisotry";
import Follow_up_eta from "./Componet/Controal/Follow_up_eta";
import Follow_up_customer from "./Componet/Controal/Follow_up_customer";
import Follow_up_report from "./Componet/Controal/Follow_up_report";
import Follow_up_loading_unloading from "./Componet/Controal/Follow_up_loading_unloading";
import Follow_up_transit from "./Componet/Controal/Follow_up_transit";
import Follow_up_wfo from "./Componet/Controal/Follow_up_wfo";
import Follow_up_stoppage from "./Componet/Controal/Follow_up_stoppage";
import Follow_up_manual from "./Componet/Controal/Follow_up_manual";
import Follow_up_movement from "./Componet/Controal/Follow_up_movement";
import Follow_up_distance_calc from "./Componet/Controal/Follow_up_distance_calc";
import Follow_up_wizard from "./Componet/Controal/Follow_up_wizard";
import Os_company from "./Componet/Outsource/Os_company";
import Os_vehicle from "./Componet/Outsource/Os_vehicle";
import Os_driver from "./Componet/Outsource/Os_driver";
import Os_trailer from "./Componet/Outsource/Os_trailer";
import Assign_sub_contractor_req from "./Componet/Outsource/Assign_sub_contractor_req";
import Assign_sub_contractor_app from "./Componet/Outsource/Assign_sub_contractor_app";
import Sub_contractors from "./Componet/Outsource/Sub_contractors";

import Menu from "./Componet/Menu/Menu";
import D_served from "./Componet/Daily/D_served";
import D_not_served from "./Componet/Daily/D_not_served";
import D_sale from "./Componet/Daily/D_sale";
import D_sale_detail from "./Componet/Daily/D_sale_detail";
import Sync_item from "./Componet/Menu/Sync_item";
import Sync_si_unit from "./Componet/Menu/Sync_si_unit";
import Waiter from "./Componet/Menu/Waiter";
import Recipe_mgt from "./Componet/Menu/Recipe_mgt";
import D_sale_per_user from "./Componet/Daily/D_sale_per_user";




import Coc_mgt from "./Componet/Management/Coc_mgt";


import Other_cost from "./Componet/Assets/Other_cost";

import Status_type from "./Componet/Assets/Status_type";
import Vehicle_status from "./Componet/Controal/Vehicle_status";
import Opex_cost_req from "./Componet/Opex/Opex_cost_req";
import Opex_cost_app from "./Componet/Opex/Opex_cost_app";
import Opex_fuel_req from "./Componet/Opex/Opex_fuel_req";
import Opex_fuel_app from "./Componet/Opex/Opex_fuel_app";
import Opex_fuel_giver from "./Componet/Opex/Opex_fuel_giver";
import Opex_cost_refund from "./Componet/Opex/Opex_cost_refund";
import Opex_fuel_refund from "./Componet/Opex/Opex_fuel_refund";
import Fright_document from "./Componet/Operation/Fright_document";

import Opex_summuary from "./Componet/Opex/Opex_summuary";
import Opex_detail from "./Componet/Opex/Opex_detail";
import Opex_cost_payment_all from "./Componet/Opex/Opex_cost_payment_all";

import Finance_document from "./Componet/Operation/Finance_document";
import Opex_cost_payment_validator from "./Componet/Opex/Opex_cost_payment_validator";
import Report_generator from "./Componet/ReportGenerator/Report_generator";
import Order_status from "./Componet/Order/Order_status";
import Order_detail from "./Componet/Order/Order_detail";
import Tg_status_sum from "./Componet/Telegram/Tg_status_sum";
import Tg_status_det from "./Componet/Telegram/Tg_status_det";
import Tg_status_single from "./Componet/Telegram/Tg_status_single";
import Tg_stop_at_client from "./Componet/Telegram/Tg_stop_at_client";
import Pre_assign_req from "./Componet/Operation2/Pre_assign_req";
import Pre_assign_app from "./Componet/Operation2/Pre_assign_app";
import Assign_vehicle from "./Componet/Operation2/Assign_vehicle";
import Opex_cost from "./Componet/Opex/Opex_cost";
import Opex_fuel from "./Componet/Opex/Opex_fuel";
import Pre_assign_exe from "./Componet/Operation2/Pre_assign_exe";
import Refund_to_company_req from "./Componet/Refund/Refund_to_company_req";
import Refund_to_company_app from "./Componet/Refund/Refund_to_company_app";
import Pos_refund_req from "./Componet/Refund/Pos_refund_req";
import Pos_refund_app from "./Componet/Refund/Pos_refund_app";
import Neg_refund_req from "./Componet/Refund/Neg_refund_req";
import Neg_refund_app from "./Componet/Refund/Neg_refund_app";
import Opex_report from "./Componet/Opex/Opex_report";
import Order_weight_req from "./Componet/Order/Order_weight_req";
import Order_weight_app from "./Componet/Order/Order_weight_app";
import Opex_reconcile from "./Componet/Opex/Opex_reconcile";
import Invoice_prepare from "./Componet/Operation/Invoice_prepare";
import payment_settlement from "./Componet/Operation/Payment_settlement";
import Payment_settlement from "./Componet/Operation/Payment_settlement";
import Opp_manage from "./Componet/Operation2/Opp_manage";
import Fo_manage from "./Componet/Operation2/Fo_manage";
import Opex_balance_sheet from "./Componet/Opex/Opex_balance_sheet";
import Order_add_expence_req from "./Componet/Order/Order_add_expence_req";
import Order_add_expence_app from "./Componet/Order/Order_add_expence_app";
import Order_add_expence_set from "./Componet/Order/Order_add_expence_set";
import Report_master_trip from "./Componet/Report/Report_master_trip";
import Report_order_allocation from "./Componet/Report/Report_order_allocation";
import Update_assign_vehicle from "./Componet/Operation2/Update_assign_vehicle";
import Report_status_update from "./Componet/Report/Report_status_update";
import Report_current_status from "./Componet/Report/Report_current_status";
import Report_status from "./Componet/Report/Report_status";
import Mobile_current_status from "./Componet/Mobile/Mobile_current_status";
import Assign_history from "./Componet/Operation2/Assign_history";
import Audit_assignment from "./Componet/Audit/Audit_assignment";
import Mobile_home from "./Componet/Mobile/Mobile_home";
import Mobile_customer_report from "./Componet/Mobile/Mobile_customer_report";







function App() {
    return (
        <>
            <BrowserRouter basename='/'>
                <Switch>
                    <Route path="/" exact component={Welcome}/>
                    <Route path="/d_operation" exact component={D_operation}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/user" exact component={User}/>
                    <Route path="/objuser" exact component={ObjUser}/>
                    <Route path="/menu" exact component={Menu}/>
                    <Route path="/user_template" exact component={User_template}/>
                    <Route path="/client_mgt" exact component={Client_mgt}/>
                    <Route path="/client_type" exact component={Client_type}/>
                    <Route path="/cargo_type" exact component={Cargo_type}/>
                    <Route path="/admin_script" exact component={Admin_script}/>
                    <Route path="/kpi_umo" exact component={Kpi_umo}/>
                    <Route path="/kpi_description" exact component={Kpi_description}/>
                    <Route path="/kpi_data" exact component={Kpi_data}/>
                    <Route path="/vehicle" exact component={Vehicle}/>
                    <Route path="/driver" exact component={Driver}/>
                    <Route path="/trailer" exact component={Trailer}/>
                    <Route path="/location" exact component={Location}/>
                    <Route path="/road_route" exact component={Road_route}/>
                    <Route path="/road_route_open" exact component={Road_route_open}/>
                    <Route path="/fuel_rate" exact component={Fuel_rate}/>
                    <Route path="/status_delivery" exact component={Status_delivery}/>
                    <Route path="/status_delivery_detail" exact component={Status_delivery_detail}/>
                    <Route path="/order_open" exact component={Order_open}/>
                    <Route path="/order_accept_fin" exact component={Order_accept_fin}/>
                    <Route path="/order_current_status" exact component={Order_current_status}/>
                    <Route path="/order_update" exact component={Order_update}/>
                    <Route path="/assign_own" exact component={Assign_own}/>
                    <Route path="/update_allocate" exact component={Update_allocate}/>
                    <Route path="/document_mgt" exact component={Document_mgt}/>
                    <Route path="/veh_ava" exact component={Veh_ava}/>
                    <Route path="/main_temp" exact component={Main_temp}/>
                    <Route path="/op_refund_req" exact component={Op_refund_req}/>
                    <Route path="/op_refund_app" exact component={Op_refund_app}/>
                    <Route path="/op_trip_cancel_req" exact component={Op_trip_cancel_req}/>
                    <Route path="/op_trip_cancel_app" exact component={Op_trip_cancel_app}/>
                    <Route path="/op_trip_extend_req" exact component={Op_trip_extend_req}/>
                    <Route path="/op_trip_extend_app" exact component={Op_trip_extend_app}/>
                    <Route path="/op_reconcile" exact component={Op_reconcile}/>

                    <Route path="/follow_up_loading_unloading" exact component={Follow_up_loading_unloading}/>
                    <Route path="/follow_up_transit" exact component={Follow_up_transit}/>
                    <Route path="/follow_up_wfo" exact component={Follow_up_wfo}/>
                    <Route path="/follow_up_stoppage" exact component={Follow_up_stoppage}/>
                    <Route path="/follow_up" exact component={Follow_up}/>
                    <Route path="/follow_up_2" exact component={Follow_up_2}/>
                    <Route path="/follow_up_hisotry" exact component={Follow_up_hisotry}/>
                    <Route path="/follow_up_eta" exact component={Follow_up_eta}/>
                    <Route path="/follow_up_eta" exact component={Follow_up_eta}/>
                    <Route path="/follow_up_customer" exact component={Follow_up_customer}/>
                    <Route path="/follow_up_report" exact component={Follow_up_report}/>
                    <Route path="/follow_up_manual" exact component={Follow_up_manual}/>
                    <Route path="/follow_up_movement" exact component={Follow_up_movement}/>
                    <Route path="/follow_up_distance_calc" exact component={Follow_up_distance_calc}/>
                    <Route path="/follow_up_wizard" exact component={Follow_up_wizard}/>
                    <Route path="/os_company" exact component={Os_company}/>
                    <Route path="/os_vehicle" exact component={Os_vehicle}/>
                    <Route path="/os_driver" exact component={Os_driver}/>
                    <Route path="/os_trailer" exact component={Os_trailer}/>
                    <Route path="/assign_sub_contractor_req" exact component={Assign_sub_contractor_req}/>
                    <Route path="/assign_sub_contractor_app" exact component={Assign_sub_contractor_app}/>
                    <Route path="/sub_contractors" exact component={Sub_contractors}/>






                    <Route path="/d_served" exact component={D_served}/>
                    <Route path="/d_not_served" exact component={D_not_served}/>
                    <Route path="/d_sale" exact component={D_sale}/>
                    <Route path="/d_sale_detail" exact comassign_ownponent={D_sale_detail}/>
                    <Route path="/sync_item" exact component={Sync_item}/>
                    <Route path="/sync_si_unit" exact component={Sync_si_unit}/>
                    <Route path="/waiter" exact component={Waiter}/>
                    <Route path="/recipe_mgt" exact component={Recipe_mgt}/>
                    <Route path="/d_sale_per_user" exact component={D_sale_per_user}/>

                    <Route path="/coc_mgt" exact component={Coc_mgt}/>



                    <Route path="/delete_own" exact component={Delete_own}/>
                    <Route path="/other_cost" exact component={Other_cost}/>
                    <Route path="/status_type" exact component={Status_type}/>


                    <Route path="/vehicle_status" exact component={Vehicle_status}/>
                    <Route path="/opex_cost_req" exact component={Opex_cost_req}/>
                    <Route path="/opex_cost_app" exact component={Opex_cost_app}/>
                    <Route path="/opex_fuel_req" exact component={Opex_fuel_req}/>
                    <Route path="/opex_fuel_app" exact component={Opex_fuel_app}/>
                    <Route path="/opex_fuel_giver" exact component={Opex_fuel_giver}/>
                    <Route path="/opex_cost_refund" exact component={Opex_cost_refund}/>
                    <Route path="/opex_fuel_refund" exact component={Opex_fuel_refund}/>
                    <Route path="/fright_document" exact component={Fright_document}/>
                    <Route path="/finance_document" exact component={Finance_document}/>

                    <Route path="/opex_summuary" exact component={Opex_summuary}/>
                    <Route path="/opex_detail" exact component={Opex_detail}/>
                    <Route path="/opex_cost_payment_all" exact component={Opex_cost_payment_all}/>

                    <Route path="/opex_cost_payment_validator" exact component={Opex_cost_payment_validator}/>
                    <Route path="/report_generator" exact component={Report_generator}/>
                    <Route path="/void_fo" exact component={Void_fo}/>

                    <Route path="/order_status" exact component={Order_status}/>
                    <Route path="/order_detail" exact component={Order_detail}/>

                    <Route path="/tg_status_sum" exact component={Tg_status_sum}/>
                    <Route path="/tg_status_det" exact component={Tg_status_det}/>
                    <Route path="/tg_status_single" exact component={Tg_status_single}/>
                    <Route path="/tg_stop_at_client" exact component={Tg_stop_at_client}/>
                    <Route path="/pre_assign_req" exact component={Pre_assign_req}/>
                    <Route path="/pre_assign_app" exact component={Pre_assign_app}/>
                    <Route path="/assign_vehicle" exact component={Assign_vehicle}/>
                    <Route path="/opex_cost" exact component={Opex_cost}/>
                    <Route path="/opex_fuel" exact component={Opex_fuel}/>
                    <Route path="/pre_assign_exe" exact component={Pre_assign_exe}/>
                    <Route path="/refund_to_company_req" exact component={Refund_to_company_req}/>
                    <Route path="/refund_to_company_app" exact component={Refund_to_company_app}/>
                    <Route path="/pos_refund_req" exact component={Pos_refund_req}/>
                    <Route path="/pos_refund_app" exact component={Pos_refund_app}/>
                    <Route path="/neg_refund_req" exact component={Neg_refund_req}/>
                    <Route path="/neg_refund_app" exact component={Neg_refund_app}/>
                    <Route path="/opex_report" exact component={Opex_report}/>
                    <Route path="/order_weight_req" exact component={Order_weight_req}/>
                    <Route path="/order_weight_app" exact component={Order_weight_app}/>
                    <Route path="/opex_reconcile" exact component={Opex_reconcile}/>
                    <Route path="/invoice_prepare" exact component={Invoice_prepare}/>
                    <Route path="/payment_settlement" exact component={Payment_settlement}/>
                    <Route path="/opp_manage" exact component={Opp_manage}/>
                    <Route path="/fo_manage" exact component={Fo_manage}/>
                    <Route path="/opex_balance_sheet" exact component={Opex_balance_sheet}/>
                    <Route path="/order_add_expence_req" exact component={Order_add_expence_req}/>
                    <Route path="/order_add_expence_app" exact component={Order_add_expence_app}/>
                    <Route path="/order_add_expence_set" exact component={Order_add_expence_set}/>
                    <Route path="/report_master_trip" exact component={Report_master_trip}/>
                    <Route path="/report_order_allocation" exact component={Report_order_allocation}/>
                    <Route path="/update_assign_vehicle" exact component={Update_assign_vehicle}/>
                    <Route path="/report_status_update" exact component={Report_status_update}/>
                    <Route path="/report_current_status" exact component={Report_current_status}/>
                    <Route path="/report_status" exact component={Report_status}/>
                    <Route path="/assign_history" exact component={Assign_history}/>
                    <Route path="/audit_assignment" exact component={Audit_assignment}/>
                    <Route path="/mobile_home" exact component={Mobile_home}/>
                    <Route path="/mobile_current_status" exact component={Mobile_current_status}/>
                    <Route path="/mobile_customer_report" exact component={Mobile_customer_report}/>

                </Switch>
            </BrowserRouter>
        </>
    );
}
export default App;
