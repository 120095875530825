import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Pre_assign_app extends Component {

    constructor() {
        super();
        this.state = {
            data:{},
            fuels: [],
            datas: [],
            filtered: [],
            wiz: 1,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_pre_assign_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnmanage = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_pre_assign_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id: res.data.data.id,
                    data: res.data.data,
                    fuels: res.data.fuels,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            name: '',
        })
    }
    btnapprove = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to approve the pre-allocation. You can not undone the operation?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
               this.approve("true")
            }
        })
    }
    btnreject = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to reject the pre-allocation. You can not undone the operation?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
               this.approve("false")
            }
        })
    }
    approve=(approve)=>{
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.pre_assign_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            approve:approve,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td>
                    <a href="src/Componet/Operation2/Pre_assign_app" onClick={event => this.btnmanage(event,value.id)}>Manage</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.allocated_date}</td>
                <td>{value.startloc}</td>
                <td>{value.total_money}</td>
                <td>{value.total_fuel}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-3">Truck No</th>
                            <th className="col-md-2">Driver Name</th>
                            <th className="col-md-2">Allocated Date</th>
                            <th className="col-md-2">Location</th>
                            <th className="col-md-1">Cost</th>
                            <th className="col-md-1">Fuel</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let total_fuel_l=0;
        let total_fuel_etb=0;
        let tabledata=this.state.fuels.map(v=>{
            let etb=(v.fuel_rate*v.amount).toFixed(2)
            total_fuel_l=(total_fuel_l*1)+(v.amount*1);
            total_fuel_etb=(total_fuel_etb*1)+(etb*1);
            return (<tr>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Show Request</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.plate_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.driver_name}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.trailer_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.fright_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.allocate_date}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.st_loc}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Payment (ETB)</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.cost_etb}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQ BY</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.req_by}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQ AT</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.req_at}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <table id="report" className="table table-head-fixed table-sm">
                                        <tr>
                                            <th className="col-md-4">Station</th>
                                            <th className="col-md-3">Type</th>
                                            <th className="col-md-2">Rate</th>
                                            <th className="col-md-2">Fuel(L)</th>
                                            <th className="col-md-2">Fuel(ETB)</th>
                                        </tr>
                                        {tabledata}
                                    </table>
                                </div>
                                <div className="col-12">
                                    <div className="col-12">
                                        <p><strong>Total Fuel (L):</strong> {total_fuel_l}</p>
                                        <p><strong>Total ETB (L):</strong> {total_fuel_etb}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btnapprove} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Approve
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btnreject} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Reject
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btncancel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="pre_assign_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Pre Assign APPROVE</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Pre_assign_app;
