import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Pos_refund_app extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            allocations: [],
            pre_assignments: [],
            attachments: [],
            prv_datas: [],
            prv_fuels: [],
            attachments_selected: null,

            fuel_rates: [],
            fuel_rate_selected: null,

            txtsearch: '',

            fuels: [],
            fuels_budeget: [],

            wiz: 1,
            data: {},
            iscreate: false,
            id: -1,
            name: '',

        }
    }

    handelchange_fuel_rate = (fuel_rate_selected) => {
        this.setState({
            fuel_rate_selected: fuel_rate_selected,
        })
    }
    handelchange_attachment = (attachments_selected) => {
        this.setState({
            attachments_selected: attachments_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_pos_refund_app;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_attachment = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let attachments = []
        if (value === "allocation")
            attachments = this.state.allocations
        else if (value === "pre-assignment")
            attachments = this.state.pre_assignments
        this.setState({
            [name]: value,
            attachments: attachments,
            attachments_selected: null,
        })
    }

    btnmanage = (event, value) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_single_for_pos_refund_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id: value.id,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    vehicle_id: value.id,
                    plate_no: value.plate_no,
                    allocations: res.data.allocations,
                    pre_assignments: res.data.pre_assignments,
                    prv_datas: res.data.prv_datas,
                    prv_fuels: res.data.prv_fuels,
                    fuels_budeget: res.data.fuels_budeget,
                });
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id: -1,
            iscreate: true,
            name: '',
        })
    }

    btn_request_edit = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_cost_for_pos_refund_req;
        if (this.state.r_type === "fuel")
            baseurl = myconfig.edit_fuel_for_pos_refund_req;
        let attach_id = this.state.attachments_selected == null ? null : this.state.attachments_selected.value;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            attchment: this.state.r_attachment,
            attach_id: attach_id,
            type: this.state.r_type,
            amount: this.state.new_amount,
            fuels: this.state.fuels,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_request_create = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.create_for_pos_refund_req;
        let attach_id = this.state.attachments_selected == null ? null : this.state.attachments_selected.value;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id: this.state.vehicle_id,
            attchment: this.state.r_attachment,
            attach_id: attach_id,
            type: this.state.r_type,
            amount: this.state.new_amount,
            fuels: this.state.fuels,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_get_edit = (event, id, type) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_req_single_cost_edit_for_refund_req;
        if (type === "Fuel")
            baseurl = myconfig.get_req_single_fuel_edit_for_refund_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 3,
                    id: res.data.id,
                    attachments_selected: res.data.attachments_selected,
                    r_attachment: res.data.r_attachment,
                    r_type: res.data.r_type,
                    new_amount: res.data.new_amount,
                    prv_amount: res.data.prv_amount,
                    fuels: res.data.fuels,
                    iscreate: false,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filtered.map(value => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnmanage(event, value)}>Manage</a>
                </td>
                <td>{value.plate_no}</td>
            </tr>)
        })
        return (<>

            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-9">Truck No.</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata = this.state.prv_datas.map(v => {
            return (
                <tr>
                    <td>
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let data = {}
                            data.vehicle_id = v.vehicle_id
                            data.plate_no = v.plate_no
                            data.amount = v.amount
                            data.remark = v.remark
                            data.req_by = v.req_by
                            data.req_at = v.req_at
                            data.app_by = v.app_by
                            data.app_at = v.app_at
                            data.rej_by = v.rej_by
                            data.rej_at = v.rej_at
                            data.type = v.type
                            data.attchment = v.attchment
                            data.attchment_detail = v.attchment_detail
                            data.attchment_detail = v.attchment_detail
                            data.status = v.status
                            let p = this.state.prv_fuels.filter(v2 => {
                                if (v2.refund_fuel_id === v.id)
                                    return v2
                            })
                            let p2 = this.state.fuels_budeget.filter(v2 => {
                                if (v2.refund_fuel_id === v.id)
                                    return v2
                            })
                            this.setState({
                                wiz: 3,
                                data: data,
                                fuels: p,
                                fuels_budeget: p2,
                                r_type: "fuel",
                                status: v.status,
                                type: v.type,
                                id: v.id,
                            })
                        }}>Manage</a>
                    </td>
                    <td>{v.type}</td>
                    <td>{v.amount}</td>
                    <td>{v.req_by}</td>
                    <td>{v.attchment}</td>
                    <td>{v.attchment_detail}</td>
                    <td>{v.status}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor: myconfig.color_2, color: myconfig.color_3}}>
                            <h3 className="card-title">Refund Request</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck No.: {this.state.plate_no}</label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btncancel} style={{
                                            color: myconfig.color_3,
                                            backgroundColor: myconfig.color_2
                                        }}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-12">
                                    <table className="table table-sm table-bordered">
                                        <tr>
                                            <th className="col-md-2">Action</th>
                                            <th className="col-md-1">Type</th>
                                            <th className="col-md-2">Amount</th>
                                            <th className="col-md-2">REQ By</th>
                                            <th className="col-md-2">Attachment</th>
                                            <th className="col-md-2">Detail</th>
                                            <th className="col-md-1">Status</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let btn = <>
            <div className="col-md-4">
                <div className="form-group">
                    <button className="btn btn-block btn-sm"
                            onClick={event => {
                                event.preventDefault()
                                this.setState({
                                    wiz: 2,
                                })
                            }} style={{color: myconfig.color_3, backgroundColor: myconfig.color_2}}> Cancel
                    </button>
                </div>
            </div>
            <div className="col-md-8">
            </div>
        </>
        if(this.state.status==="REQUESTED"){
            btn = <>
                <div className="col-md-4">
                    <div className="form-group">
                        <button className="btn btn-block btn-sm"
                                onClick={event => {
                                    event.preventDefault()
                                    Swal.fire({
                                        title: 'Are you sure you want to Approve this request?',
                                        showDenyButton: true,
                                        confirmButtonText: 'Yes',
                                        denyButtonText: 'No',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            let url=myconfig.app_rej_cost_for_pos_refund_app
                                            if(this.state.type==="Fuel")
                                                url=myconfig.app_rej_fuel_for_pos_refund_app
                                            this.approve_rej(url,true);
                                        }
                                    })
                                }} style={{color: myconfig.color_3, backgroundColor: myconfig.color_1}}> Approve
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <button className="btn btn-block btn-sm"
                                onClick={event => {
                                    event.preventDefault()
                                    Swal.fire({
                                        title: 'Are you sure you want to Reject this request?',
                                        showDenyButton: true,
                                        confirmButtonText: 'Yes',
                                        denyButtonText: 'No',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            let url=myconfig.app_rej_cost_for_pos_refund_app
                                            if(this.state.type==="Fuel")
                                                url=myconfig.app_rej_fuel_for_pos_refund_app
                                            this.approve_rej(url,false);
                                        }
                                    })
                                }} style={{color: myconfig.color_3, backgroundColor: myconfig.color_2}}> Reject
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <button className="btn btn-block btn-sm"
                                onClick={event => {
                                    event.preventDefault()
                                    this.setState({
                                        wiz: 2,
                                    })
                                }} style={{color: myconfig.color_3, backgroundColor: myconfig.color_1}}> Cancel
                        </button>
                    </div>
                </div>
            </>
        }

        let total_fuel_l = 0;
        let total_fuel_etb = 0;
        let tabledata = this.state.fuels.map(v => {
            let etb = (v.fuel_rate * v.amount).toFixed(2)
            total_fuel_l = (total_fuel_l * 1) + (v.amount * 1);
            total_fuel_etb = (total_fuel_etb * 1) + (etb * 1);
            return (<tr>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })
        let dis = null;

        let total_fuel_l_2 = 0;
        let total_fuel_etb_2 = 0;
        let tabledata_2 = this.state.fuels_budeget.map(v => {
            let etb = (v.fuel_rate * v.amount).toFixed(2)
            total_fuel_l_2 = (total_fuel_l_2 * 1) + (v.amount * 1);
            total_fuel_etb_2 = (total_fuel_etb_2 * 1) + (etb * 1);
            return (<tr>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })

        if (this.state.r_type === "fuel")
            dis =
                <>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <h5>Fuels</h5>
                            </div>
                            <div className="col-12">
                                <table id="report" className="table table-head-fixed table-sm">
                                    <tr>
                                        <th className="col-md-5">Station</th>
                                        <th className="col-md-2">Type</th>
                                        <th className="col-md-2">Rate</th>
                                        <th className="col-md-2">Fuel(L)</th>
                                        <th className="col-md-2">Fuel(ETB)</th>
                                    </tr>
                                    {tabledata}
                                </table>
                            </div>
                            <div className="col-12">
                                <p><strong>Total Fuel (L):</strong> {total_fuel_l}</p>
                                <p><strong>Total ETB (L):</strong> {total_fuel_etb}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <hr/>
                                <h5>Fuels Budgeted</h5>
                            </div>
                            <div className="col-12">
                                <table id="report" className="table table-head-fixed table-sm">
                                    <tr>
                                        <th className="col-md-5">Station</th>
                                        <th className="col-md-2">Type</th>
                                        <th className="col-md-2">Rate</th>
                                        <th className="col-md-2">Fuel(L)</th>
                                        <th className="col-md-2">Fuel(ETB)</th>
                                    </tr>
                                    {tabledata_2}
                                </table>
                            </div>
                            <div className="col-12">
                                <p><strong>Total Fuel (L):</strong> {total_fuel_l_2}</p>
                                <p><strong>Total ETB (L):</strong> {total_fuel_etb_2}</p>
                            </div>
                        </div>
                    </div>
                </>
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor: myconfig.color_2, color: myconfig.color_3}}>
                            <h3 className="card-title">Refund Approved</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.amount}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea disabled={true} className="form-control form-control-sm"
                                                  value={this.state.data.remark} rows={5}/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>type</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Attachement</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.attchment}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Detail</label>
                                        <textarea disabled={true} className="form-control form-control-sm"
                                                  value={this.state.data.attchment_detail} rows={5}/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQ BY</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.req_by}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQ AT</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.req_at}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>APP BY</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.app_by}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>APP AT</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.app_at}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REJ BY</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.rej_by}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REJ AT</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.rej_at}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                {dis}
                                {btn}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    approve_rej = (url,is_app) => {
        let redirecttologin = myconfig.redirecttologin;
        axios.post(url, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            is_app:is_app,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        wiz:1,
                    },()=>{
                        this.fetchdata()
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        return (
            <>
                <Nav type="pos_refund_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor: myconfig.color_1, color: myconfig.color_3}}>
                                            <h3 className="card-title">Refund To Driver APPROVED</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Pos_refund_app;
