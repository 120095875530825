import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Update_assign_vehicle extends Component {

    constructor() {
        super();
        this.state = {
            cost_fuel_data: {},
            order_data: {},
            vehicle_orders: [],
            datas: [],
            filtered: [],
            fuels: [],
            wiz: 1,

            drivers: [],
            driver_selected: null,

            trailers: [],
            trailer_selected: null,

            fuel_rates: [],
            fuel_rate_selected: null,

            orders: [],
            order_selected: null,

            locations: [],

            st_selected: null,
            ed_selected: null,

        }
    }
    componentDidMount() {
        this.fetchdata()
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    handelchange_driver = (driver_selected) => {
        this.setState({
            driver_selected: driver_selected,
        })
    }
    handelchange_trailer = (trailer_selected) => {
        this.setState({
            trailer_selected: trailer_selected,
        })
    }
    handelchange_fuel_rate = (fuel_rate_selected) => {
        this.setState({
            fuel_rate_selected: fuel_rate_selected,
        })
    }
    handelchange_st_loc = (st_selected) => {
        this.setState({
            st_selected: st_selected,
        }, () => {
            if (
                this.state.st_selected != null &&
                this.state.ed_selected != null &&
                this.state.order_selected != null
            ) {
                this.getroute();
            }
        })
    }
    handelchange_ed_loc = (ed_selected) => {
        this.setState({
            ed_selected: ed_selected,
        }, () => {
            if (
                this.state.st_selected != null &&
                this.state.ed_selected != null &&
                this.state.order_selected != null
            ) {
                this.getroute();
            }
        })
    }
    handelchange_order = (order_selected) => {
        this.setState({
            order_selected: order_selected,
            order_data: order_selected,
        }, () => {
            if (
                this.state.st_selected != null &&
                this.state.ed_selected != null &&
                this.state.order_selected != null
            ) {
                this.getroute();
            }
        })
    }
    add_fuel = (event) => {
        event.preventDefault();
        if (this.state.fuel_rate_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Please select fuel rate",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (
            this.state.type != "birr" &&
            this.state.type != "coupon" &&
            this.state.type != "pad"
        ) {
            Swal.fire({
                title: 'Error!',
                text: "Please select type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.amount == "") {
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (isNaN(this.state.amount)) {
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.amount < 0) {
            Swal.fire({
                title: 'Error!',
                text: "Amount must be greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let fuels = this.state.fuels;
            let id = fuels.length + 1;
            fuels.push({
                id: id,
                station: this.state.fuel_rate_selected.label,
                fuel_rate_id: this.state.fuel_rate_selected.value,
                fuel_rate: (this.state.fuel_rate_selected.fuel_rate * 1).toFixed(2),
                type: this.state.type,
                amount: (this.state.amount * 1).toFixed(2),
            })
            this.setState({
                fuels: fuels,
                fuel_rate_selected: null,
                type: "-1",
                amount: "",
            })
        }
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_update_assign_vehicle;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.vehicles,
                    filtered: res.data.vehicles,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                    fuel_rates: res.data.fuel_rates,
                    locations: res.data.locations,
                    id: res.data.id,
                    plate_no: res.data.plate_no,
                    driver_selected: res.data.driver_selected,
                    trailer_selected: res.data.trailer_selected,
                    st_selected: res.data.st_selected,
                    fright_no: res.data.fright_no,
                    allocated_date: res.data.allocated_date,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata_wiz_1 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_orders_update_assign_vehicle;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id: this.state.vehicle_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicle_orders: res.data.datas,
                    vehicle_id: this.state.vehicle_id,
                    wiz: 2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata_wiz_2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_order_single_update_assign_vehicle;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            assign_own_id: this.state.assign_own_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    assign_own_id: this.state.assign_own_id,
                    wiz: 3,
                    plate_no: res.data.plate_no,
                    driver_selected: res.data.driver_selected,
                    trailer_selected: res.data.trailer_selected,
                    allocated_date: res.data.allocated_date,
                    st_selected: res.data.st_selected,
                    ed_selected: res.data.ed_selected,
                    order_selected: res.data.full_order,
                    order_data: res.data.full_order,
                    actual_weight: res.data.actual_weight,
                    orders: res.data.orders,
                    fuels: res.data.fuels,
                }, () => {
                    if (
                        this.state.st_selected != null &&
                        this.state.ed_selected != null &&
                        this.state.order_selected != null
                    ) {
                        this.getroute();
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.label.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    getroute = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_route_for_update_assign_vehicle;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.vehicle_id,
            st_selected: this.state.st_selected.value,
            ed_selected: this.state.ed_selected.value,
            order_selected: this.state.order_selected.value,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    cost_fuel_data: res.data.cost_fuel_data,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filtered.map(value => {
            count = count + 1
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            vehicle_id:value.value,
                        },()=>{
                            this.fetchdata_wiz_1()
                        })
                    }}>Manage</a>
                </td>
                <td>{value.label}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-2">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-8">Truck No</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata = this.state.vehicle_orders.map(v => {
            return (
                <tr>
                    <td><a href="" onClick={event => {
                        event.preventDefault();
                        this.setState({
                            assign_own_id: v.assign_own_id,
                        }, () => {
                            this.fetchdata_wiz_2()
                        })
                    }}>Edit</a></td>
                    <td>{v.plate_no}</td>
                    <td>{v.driver_name}</td>
                    <td>{v.tra}</td>
                    <td>{v.allocated_date}</td>
                    <td>{v.order_lable}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-2 py-2">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor: myconfig.color_2, color: myconfig.color_3}}>
                            <h3 className="card-title">Orders</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 pb-3">
                                    <table className="table table-sm table-bordered">
                                        <tr>
                                            <th className="col-md-1">Action</th>
                                            <th className="col-md-2">Allocated Date</th>
                                            <th className="col-md-2">Truck No.</th>
                                            <th className="col-md-2">Driver Name</th>
                                            <th className="col-md-2">Trailer No.</th>
                                            <th className="col-md-3">Order</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz: 1,
                                                    })
                                                }} style={{
                                            color: myconfig.color_3,
                                            backgroundColor: myconfig.color_2
                                        }}> Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let total_fuel_l = 0;
        let total_fuel_etb = 0;
        let tabledata = this.state.fuels.map(v => {
            let etb = (v.fuel_rate * v.amount).toFixed(2)
            total_fuel_l = ((total_fuel_l * 1) + (v.amount * 1)).toFixed(2);
            total_fuel_etb = ((total_fuel_etb * 1) + (etb * 1)).toFixed(2);
            return (<tr>
                <td><a href="src/Componet/Operation2/Pre_assign_req" onClick={event => {
                    event.preventDefault();
                    let fuels = this.state.fuels.filter(v2 => {
                        if (v2.id != v.id)
                            return v2;
                    })
                    this.setState({
                        fuels: fuels,
                    })
                }}>X</a></td>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor: myconfig.color_2, color: myconfig.color_3}}>
                            <h3 className="card-title">Assign Vehicle</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.plate_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.allocated_date}
                                               name="allocated_date"
                                               onChange={this.inputmgt}
                                               type="date"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.st_selected}
                                            onChange={this.handelchange_st_loc}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End Location</label>
                                        <Select
                                            value={this.state.ed_selected}
                                            onChange={this.handelchange_ed_loc}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                                  onChange={this.inputmgt}
                                                  value={this.state.order_data.des_good ?? "NA"}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Types of Load</label>
                                        <input disabled={true} className="form-control form-control-sm text-capitalize"
                                               value={this.state.order_data.tol}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Left Weight (q) to load</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.avl_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Actual Weight (q)</label>
                                        <input type="text" className="form-control form-control-sm" name="actual_weight"
                                               value={this.state.actual_weight} onChange={this.inputmgt}
                                               placeholder="Weight"/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>

                                <div className="col-md-3">
                                    <p><strong>Pre-diem Empty (ETB): </strong>{this.state.cost_fuel_data.pre_diem_empty}
                                    </p>
                                    <p><strong>Handling Fee Empty
                                        (ETB): </strong>{this.state.cost_fuel_data.handling_empty}</p>
                                    <p><strong>Other Fee Empty (ETB): </strong>{this.state.cost_fuel_data.other_empty}
                                    </p>
                                    <p><strong>Pre-diem Loaded
                                        (ETB): </strong>{this.state.cost_fuel_data.pre_diem_loaded}</p>
                                    <p><strong>Handling Fee Loaded
                                        (ETB): </strong>{this.state.cost_fuel_data.handling_loaded}</p>
                                    <p><strong>Other Fee Loaded (ETB): </strong>{this.state.cost_fuel_data.other_loaded}
                                    </p>
                                    <p><strong>Total Fee (ETB): </strong>{this.state.cost_fuel_data.cur_cost}</p>
                                </div>
                                <div className="col-md-3">
                                    <p><strong>Fuel Empty (L): </strong>{this.state.cost_fuel_data.bud_fuel_empty}</p>
                                    <p><strong>Loaded Fuel (L): </strong>{this.state.cost_fuel_data.bud_fuel_load}</p>
                                    <p><strong>Total Fuel (L): </strong>{this.state.cost_fuel_data.bud_fuel}</p>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Fuels Budgeted</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Fuel Station</label>
                                                <Select
                                                    value={this.state.fuel_rate_selected}
                                                    onChange={this.handelchange_fuel_rate}
                                                    options={this.state.fuel_rates}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <select className="form-control form-control-sm"
                                                        value={this.state.type}
                                                        name="type"
                                                        onChange={this.inputmgt}>
                                                    <option value="-1">-- Select Type --</option>
                                                    <option value="birr">Birr</option>
                                                    <option value="coupon">Coupon</option>
                                                    <option value="pad">Pad</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Amount (L)</label>
                                                <form onSubmit={this.add_fuel}>
                                                    <input className="form-control form-control-sm"
                                                           value={this.state.amount}
                                                           name="amount"
                                                           placeholder="Amount (L)"
                                                           onChange={this.inputmgt}
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <table id="report" className="table table-head-fixed table-sm">
                                                <tr>
                                                    <th className="col-md-1"></th>
                                                    <th className="col-md-4">Station</th>
                                                    <th className="col-md-2">Type</th>
                                                    <th className="col-md-2">Rate</th>
                                                    <th className="col-md-2">Fuel(L)</th>
                                                    <th className="col-md-2">Fuel(ETB)</th>
                                                </tr>
                                                {tabledata}
                                            </table>
                                        </div>
                                        <div className="col-12">
                                            <p><strong>Total Fuel (L):</strong> {total_fuel_l}</p>
                                            <p><strong>Total ETB (L):</strong> {total_fuel_etb}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btnupdate} style={{
                                            color: myconfig.color_3,
                                            backgroundColor: myconfig.color_1
                                        }}> Update Assign
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:2,
                                                    })
                                                }} style={{
                                            color: myconfig.color_3,
                                            backgroundColor: myconfig.color_2
                                        }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    btnupdate = (event) => {
        event.preventDefault();
        if(this.state.driver_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select driver",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.st_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select start location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.order_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.ed_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select end location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_assign_vehicle;
            let trailer_selected=this.state.trailer_selected==null?null:this.state.trailer_selected.value;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                assign_own_id: this.state.assign_own_id,
                id: this.state.vehicle_id,
                driver_selected:this.state.driver_selected.value,
                trailer_selected:trailer_selected,
                fright_no:this.state.fright_no,
                allocated_date:this.state.allocated_date,
                st_selected:this.state.st_selected.value,
                order_selected:this.state.order_selected.value,
                ed_selected:this.state.ed_selected.value,
                actual_weight:this.state.actual_weight,
                fuels:this.state.fuels,
                pre_assign_id:this.state.pre_assign_id,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.fetchdata_wiz_1()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="update_assign_vehicle"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor: myconfig.color_1, color: myconfig.color_3}}>
                                            <h3 className="card-title">Update Vehicle Allocation</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Update_assign_vehicle;
