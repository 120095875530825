import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Opex_reconcile extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],

            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',

            fuels: [],
            vehicles: [],
            vehicle_selected: null,
            is_exe:false,

            fuel_rates: [],
            fuel_rate_selected: null,
            not_given_costs:[],
            not_given_fuels:[],
            cur_cost:'',
            cur_fuel:'',
            fuel_amount:'0',
        }
    }
    handelchange_fuel_rate= (fuel_rate_selected) => {
        this.setState({
            fuel_rate_selected: fuel_rate_selected,
        })
    }
    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected:vehicle_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_opex_reconcile;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.vehicles,
                    fuel_rates: res.data.fuel_rates,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            is_exe:false,
            vehicle_selected:null,
        })
    }
    btnreconciel = (event) => {
        event.preventDefault();
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.opex_reconcile;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id: this.state.vehicle_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                }
                else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
                else {
                    if(res.data.is_fuel){
                        this.setState({
                            fuel_amount:res.data.fuel_amount,
                            fuels:[],
                            wiz:2,
                        })
                    }
                    else{
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            });
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnreconciel_fuel = (event) => {
        event.preventDefault();
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.opex_reconcile_with_fuel;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id: this.state.vehicle_selected.value,
                fuels:this.state.fuels,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                }
                else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                }
                else {
                    if(res.data.is_fuel){
                        this.setState({
                            fuel_amount:res.data.fuel_amount,
                            fuels:[],
                            wiz:2,
                        })
                    }
                    else{
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            });
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    wiz_1 = () => {
        return (<>
            <div className="col-12">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                                <h3 className="card-title">Vehicle Data</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Vehicle</label>
                                            <Select
                                                value={this.state.vehicle_selected}
                                                onChange={this.handelchange_vehicle}
                                                options={this.state.vehicles}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-sm"
                                                    onClick={this.btnreconciel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Reconcile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                    </div>
                    <div className="col-12">
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let total_fuel_l=0;
        let total_fuel_etb=0;
        let tabledata=this.state.fuels.map(v=>{
            let etb=(v.fuel_rate*v.amount).toFixed(2)
            total_fuel_l=((total_fuel_l*1)+(v.amount*1)).toFixed(2);
            total_fuel_etb=((total_fuel_etb*1)+(etb*1)).toFixed(2);
            return (<tr>
                <td><a href="src/Componet/Operation2/Pre_assign_req" onClick={event => {
                    event.preventDefault();
                    let fuels=this.state.fuels.filter(v2=>{
                        if(v2.id!=v.id)
                            return v2;
                    })
                    this.setState({
                        fuels:fuels,
                    })
                }}>X</a></td>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })
        return (<>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                        <h3 className="card-title">Vehicle Fuel Reconcile</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <h5>Fuels</h5>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Expected Fuel</label>
                                    <input className="form-control form-control-sm"
                                           disabled={true}
                                           value={this.state.fuel_amount}
                                           name="fuel_amount"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fuel Station</label>
                                    <Select
                                        value={this.state.fuel_rate_selected}
                                        onChange={this.handelchange_fuel_rate}
                                        options={this.state.fuel_rates}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Type</label>
                                    <select className="form-control form-control-sm"
                                            value={this.state.type}
                                            name="type"
                                            onChange={this.inputmgt}>
                                        <option value="-1">-- Select Type --</option>
                                        <option value="birr">Birr</option>
                                        <option value="coupon">Coupon</option>
                                        <option value="pad">Pad</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Amount (L)</label>
                                    <form onSubmit={this.add_fuel}>
                                        <input className="form-control form-control-sm"
                                               value={this.state.amount}
                                               name="amount"
                                               placeholder="Amount (L)"
                                               onChange={this.inputmgt}
                                        />
                                    </form>
                                </div>
                            </div>
                            <div className="col-12">
                                <table id="report" className="table table-head-fixed table-sm">
                                    <tr>
                                        <th className="col-md-1"></th>
                                        <th className="col-md-4">Station</th>
                                        <th className="col-md-2">Type</th>
                                        <th className="col-md-2">Rate</th>
                                        <th className="col-md-2">Fuel(L)</th>
                                        <th className="col-md-2">Fuel(ETB)</th>
                                    </tr>
                                    {tabledata}
                                </table>
                            </div>
                            <div className="col-12">
                                <p><strong>Total Fuel (L):</strong> {total_fuel_l}</p>
                                <p><strong>Total ETB (L):</strong> {total_fuel_etb}</p>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <button className="btn btn-block btn-sm"
                                            onClick={this.btnreconciel_fuel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Reconcile
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <button className="btn btn-block btn-sm"
                                            onClick={this.btncancel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    add_fuel = (event) => {
        event.preventDefault();
        if(this.state.fuel_rate_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select fuel rate",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (
            this.state.type!="birr"&&
            this.state.type!="coupon"&&
            this.state.type!="pad"
        ){
            Swal.fire({
                title: 'Error!',
                text: "Please select type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount==""){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(isNaN(this.state.amount)){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount<0){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let fuels=this.state.fuels;
            let id=fuels.length+1;
            fuels.push({
                id:id,
                station:this.state.fuel_rate_selected.label,
                fuel_rate_id:this.state.fuel_rate_selected.value,
                fuel_rate:(this.state.fuel_rate_selected.fuel_rate*1).toFixed(2),
                type:this.state.type,
                amount:(this.state.amount*1).toFixed(2),
            })
            this.setState({
                fuels: fuels,
                fuel_rate_selected: null,
                type: "-1",
                amount: "",
            })
        }
    }
    render() {
        let dis = null;
        if (this.state.wiz===1)
            dis = this.wiz_1()
        else if(this.state.wiz===2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="opex_reconcile"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Reconcile Payment</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {dis}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_reconcile;
