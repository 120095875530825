import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Assign_history extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',
            data: {},

            vehicles: [],
            vehicle_selected: null,
            is_exe:false,
            btn_text:"Get Report",
            vehicle_id:null,
        }
    }

    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected: vehicle_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_assign_history;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.vehicles,
                }, () => {
                    let vehicle_id = new URLSearchParams(this.props.location.search).get("vehicle_id");
                    let data = this.state.vehicles.filter(v => {
                        if (v.value === vehicle_id)
                            return v;
                    })
                    if (data.length > 0) {
                        this.setState({
                            vehicle_selected: {value: data[0].value, label: data[0].label}
                        }, () => {
                            this.get_report()
                        })
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    get_report = () => {
        if(this.state.is_exe===false) {
            if (this.state.vehicle_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select vehicle",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    is_exe:true,
                    btn_text:"Executing...",
                    datas:[],
                },()=>{
                    let redirecttologin = myconfig.redirecttologin;
                    let baseurl = myconfig.assign_history;
                    axios.post(baseurl, {
                        sid: window.localStorage.getItem("logmgtsys_sid"),
                        vehicle_id: this.state.vehicle_selected.value,
                    }, {cancelToken: source.token}).then(res => {
                        console.log(res.data)
                        if (res.data.auth == false) {
                            window.localStorage.setItem("logmgtsys_sid", null);
                            window.localStorage.setItem("logmgtsys_username", null);
                            window.localStorage.setItem("logmgtsys_name", null);
                            var win = window.open(redirecttologin, "_self");
                        } else if (res.data.success == false) {
                            Swal.fire({
                                title: 'Error!',
                                text: res.data.msg,
                                icon: 'error',
                                confirmButtonText: 'Okay'
                            })
                            this.setState({
                                is_exe: false,
                                btn_text: "Get Report",
                                wiz:1,
                            })
                        } else {
                            this.setState({
                                datas: res.data.datas,
                                is_exe: false,
                                btn_text: "Get Report",
                                wiz:1,
                            })
                        }
                    }).catch((e) => {
                        console.log(e.message);
                    });
                })
            }
        }
        else
        {
            Swal.fire({
                title: 'Info!',
                text: "Wait until the other execution is done",
                icon: 'info',
                confirmButtonText: 'Okay'
            })
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    wiz_1 = () => {
        let count=0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event =>{
                        event.preventDefault();
                        let data=this.state.datas.filter(v=>{
                            if(v.assign_own_id===value.assign_own_id)
                                return v;
                        })
                        if(data.length>0)
                        {
                            this.setState({
                                wiz:2,
                                data:data[0]
                            })
                        }
                    }}>Detail</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
                <td>{value.order_label}</td>
                <td>{value.allocated_date}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="col-md-12">
                <table id="report" className="table table-bordered">
                    <thead>
                    <tr>
                        <th className="col-md-1">#</th>
                        <th className="col-md-1">Action</th>
                        <th className="col-md-2">Truck No.</th>
                        <th className="col-md-1">Driver Name</th>
                        <th className="col-md-1">Trailer No.</th>
                        <th className="col-md-3">Order</th>
                        <th className="col-md-1">Allocated Date</th>
                        <th className="col-md-2">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tdata}
                    </tbody>
                </table>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Detail Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck No.</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer No</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.order_label}/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.allocated_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Loaded Weight</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.volume}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.status}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_start_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Arrival @ Load</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_load_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Departure From Load</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_dep_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Arrival @ Offload</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_unload_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Departure From Offload</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_unload_dep_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_stop_time}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:1
                                                    })
                                                }} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="assign_history"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Trucks</label>
                                                        <Select
                                                            value={this.state.vehicle_selected}
                                                            onChange={this.handelchange_vehicle}
                                                            options={this.state.vehicles}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.get_report()
                                                                }} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> {this.state.btn_text}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                                            <h3 className="card-title">Assign History</h3>
                                        </div>
                                        <div className="card-body">
                                            {dis}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Assign_history;
