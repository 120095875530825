import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Order_add_expence_req extends Component {

    constructor() {
        super();
        this.state = {
            order_add_expences: [],
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',

            data: {},
            clients: [],
            client_selected: null,

            orders: [],
            order_selected: null,
        }
    }
    handelchange_client = (client_selected) => {
        this.setState({
            client_selected:client_selected,
        })
    }
    handelchange_order = (order_selected) => {
        this.setState({
            order_selected:order_selected,
            data:order_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_wiz_1_for_order_add_expence_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    clients: res.data.clients,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1_next = (event) => {
        event.preventDefault()
        if(this.state.client_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Select client first",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_obj_wiz_2_for_order_add_expence_req;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                client_id:this.state.client_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        wiz:2,
                        orders:res.data.orders,
                        data:{},
                        order_selected:null,
                        is_create:true,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    wiz_2_next = (event) => {
        event.preventDefault()
        if(this.state.order_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Select order first first",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            this.wiz_3_exe();
        }
    }
    wiz_3_exe=()=>{
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_wiz_3_for_order_add_expence_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            order_id:this.state.order_selected.value,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz:3,
                    order_add_expences:res.data.order_add_expences,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btncreate = (event) => {
        event.preventDefault();
        if (this.state.order_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.order_add_expence_req_create;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                order_id: this.state.order_selected.value,
                reason: this.state.reason,
                amount: this.state.amount,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.wiz_3_exe()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnedit = (event) => {
        event.preventDefault();
        if (this.state.order_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.order_add_expence_req_edit;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                order_id: this.state.order_selected.value,
                order_add_expence_id: this.state.order_add_expence_id,
                reason: this.state.reason,
                amount: this.state.amount,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.wiz_3_exe()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btndelete = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove the request? You can not undone the operation?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.order_add_expence_req_remove;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    order_add_expence_id: id,
                }, {cancelToken: source.token}).then(res => {
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.wiz_3_exe()
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }

    wiz_1 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Wizard_1</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <Select
                                            value={this.state.client_selected}
                                            onChange={this.handelchange_client}
                                            options={this.state.clients}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.wiz_1_next} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Wizard_2</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Type</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_type}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate (ETB)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:3,
                                                    })
                                                }}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Previous
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.wiz_2_next}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let tdata=this.state.order_add_expences.map(v=>{
            let a_link="";
            if(v.status=="REQUESTED")
                a_link=<>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            is_create:false,
                            wiz:4,
                            reason:v.reason,
                            amount:v.amount,
                            order_add_expence_id:v.id,
                        })
                    }}>Edit</a><br/>
                    <a href="" onClick={event => this.btndelete(event,v.id)}>Remove</a><br/>
                </>
            return (
                <tr>
                    <td>{a_link}</td>
                    <td>{v.label}</td>
                    <td>{v.reason}</td>
                    <td>{v.amount}</td>
                    <td>{v.req_by}</td>
                    <td>{v.app_by}</td>
                    <td>{v.rej_by}</td>
                    <td>{v.set_by}</td>
                    <td>{v.status}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-tools">
                                <div className="input-group input-group-sm" style={{width: '150px'}}><a
                                    href="" className="btn btn-xs btn-block" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={event => {
                                    event.preventDefault();
                                    this.setState({
                                        is_create:true,
                                        wiz:4,
                                        reason:'',
                                        amount:'',
                                    })
                                }}>New Expense</a></div>
                            </div>
                        </div>
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Wizard_3</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.label}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Type</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_type}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate (ETB)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:2,
                                                    })
                                                }}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Previous
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <table className="table table-sm table-bordered">
                                        <tr>
                                            <th className="col-md-1">Action</th>
                                            <th className="col-md-2">Order</th>
                                            <th className="col-md-2">Reason</th>
                                            <th className="col-md-2">Amount</th>
                                            <th className="col-md-1">Req By</th>
                                            <th className="col-md-1">App By</th>
                                            <th className="col-md-1">Rej By</th>
                                            <th className="col-md-1">Settle By</th>
                                            <th className="col-md-1">Status</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        let btn=<button className="btn btn-block btn-sm"
                        onClick={this.btncreate}
                        style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Request
        </button>
        if(!this.state.is_create)
            btn=<button className="btn btn-block btn-sm"
                        onClick={this.btnedit}
                        style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Edit
            </button>
        return (<>
            <div className="card-body">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Wizard_4</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.label}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Type</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_type}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate (ETB)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Reason</label>
                                        <textarea rows={5} className="form-control form-control-sm"
                                                  value={this.state.reason}
                                                  name="reason"
                                                  onChange={this.inputmgt}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.amount}
                                               name="amount"
                                               placeholder="AMOUNT"
                                               onChange={this.inputmgt}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:3,
                                                    })
                                                }}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {btn}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        return (
            <>
                <Nav type="order_add_expence_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Order Expense REQ</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_add_expence_req;
