import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Opex_detail extends Component {

    constructor() {
        super();
        this.state = {
            fright_no:'',
            data:{},


            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 0,
            iscreate: false,
            id:-1,
            coc:'',
            ref_nos:"",

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    get_fright = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.opex_detail;
        this.setState({
            wiz: 0,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no:this.state.fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        datas:res.data.reports,
                        ref_nos: res.data.ref_nos,
                        wiz:1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            refund_type: '-1',
            refund_remark: '',
            refund_amount: '',
        })
    }


    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }



    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                coc: temp[0].coc,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }

    wiz_1 = () => {
        let url_1=null;
        let url_2=null;
        if(this.state.datas.length>0) {
            url_1=myconfig.redirecttofright_document+"?fright_no="+this.state.fright_no;
            url_2=myconfig.redirecttofinance_document+"?ref_no="+this.state.ref_nos;
            url_1= <a href={url_1} target="_blank">Print FO</a>
            url_2= <a href={url_2} target="_blank">Print Finance All in one</a>
        }
        let d=this.state.datas.map(v=>{
            if(v.d0===0){
                return(<tr>
                    <th colSpan={9}>{v.d1}</th>
                </tr>)
            }
            else if(v.d0===2){
                return(<tr>
                    <th>{v.d1}</th>
                    <th>{v.d2}</th>
                    <th>{v.d3}</th>
                    <th>{v.d4}</th>
                    <th>{v.d5}</th>
                    <th>{v.d6}</th>
                    <th>{v.d7}</th>
                    <th>{v.d8}</th>
                    <th>{v.d9}</th>
                </tr>)
            }
            else if(v.d0===3){
                let url_2=myconfig.redirecttofinance_document+"?ref_no="+v.d3;
                return(<tr>
                    <td>{v.d1}</td>
                    <td>{v.d2}</td>
                    <td>{v.d3}</td>
                    <td>{v.d4}</td>
                    <td>{v.d5}</td>
                    <td>{v.d6}</td>
                    <td>{v.d7}</td>
                    <td><a href={url_2} target="_blank">Print Finance Document</a></td>
                    <td>{v.d9}</td>
                </tr>)
            }
            else{
                return(<tr>
                    <td>{v.d1}</td>
                    <td>{v.d2}</td>
                    <td>{v.d3}</td>
                    <td>{v.d4}</td>
                    <td>{v.d5}</td>
                    <td>{v.d6}</td>
                    <td>{v.d7}</td>
                    <td>{v.d8}</td>
                    <td>{v.d9}</td>
                </tr>)
            }

        })
        return (<>
            <div className="col-12">
                <div className="card">
                    <div className="card-header"
                         style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                        <h3 className="card-title">DETAIL OPEX REPORT</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                           onClick={this.btnexport_report}>Download .xlsx</a></div>
                            <div className="col-12 px-3 py-1 text-left">
                                {url_1}
                            </div>
                            <div className="col-12 px-3 py-1 text-left">
                                {url_2}
                            </div>
                            <div className="table" id="section_to_print">
                                <table id="report" className="table table-responsive">
                                    {d}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="opex_detail"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Fright Data</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Fright #</label>
                                                        <input type="text" className="form-control form-control-sm" name="fright_no"
                                                               value={this.state.fright_no} onChange={this.inputmgt}
                                                               placeholder="Fright #"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-sm"
                                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.get_fright()
                                                                }}> Get Fright
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_detail;
