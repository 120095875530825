import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Audit_assignment extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            f_day:5,
            datas: [],
            wiz: 1,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    get_report = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.audit_assignment;
        this.setState({
            datas: [],
            wiz:1,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st:this.state.st,
            ed:this.state.ed,
            f_day:this.state.f_day,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        datas: res.data.datas,
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event =>{
                        event.preventDefault();
                        let data=this.state.datas.filter(v=>{
                            if(v.assign_own_id===value.assign_own_id)
                                return v;
                        })
                        if(data.length>0)
                        {
                            this.setState({
                                wiz:2,
                                data:data[0]
                            })
                        }
                    }}>Detail</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
                <td>{value.order_label}</td>
                <td>{value.allocated_date}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="col-md-12">
                <table id="report" className="table table-bordered">
                    <thead>
                    <tr>
                        <th className="col-md-1">#</th>
                        <th className="col-md-1">Action</th>
                        <th className="col-md-2">Truck No.</th>
                        <th className="col-md-1">Driver Name</th>
                        <th className="col-md-1">Trailer No.</th>
                        <th className="col-md-3">Order</th>
                        <th className="col-md-1">Allocated Date</th>
                        <th className="col-md-2">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tdata}
                    </tbody>
                </table>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Detail Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck No.</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer No</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.order_label}/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.allocated_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Loaded Weight</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.volume}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.status}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_start_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Arrival @ Load</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_load_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Departure From Load</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_dep_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Arrival @ Offload</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_unload_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Departure From Offload</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_unload_dep_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_stop_time}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:1
                                                    })
                                                }} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="audit_assignment"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Audit Assignment</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-5 px-2 py-1">
                                                    <div className="card">
                                                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                                                            <h3 className="card-title">Report Criteria</h3>
                                                            <div className="card-tools">
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Start Date</label>
                                                                        <input type="date" className="form-control form-control-sm" name="st"
                                                                               value={this.state.st} onChange={this.inputmgt}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>End Date</label>
                                                                        <input type="date" className="form-control form-control-sm" name="ed"
                                                                               value={this.state.ed} onChange={this.inputmgt}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Filter Day</label>
                                                                        <input type="text" className="form-control form-control-sm" name="f_day"
                                                                               value={this.state.f_day} onChange={this.inputmgt}
                                                                               placeholder="Filter Day"/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <button className="btn btn-block btn-sm"
                                                                                onClick={this.get_report} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Get Report
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7"></div>
                                                {dis}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Audit_assignment;
