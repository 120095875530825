import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";

let source = axios.CancelToken.source();

class Mobile_current_status extends Component {

    constructor() {
        super();
        this.state = {
            client_token:'',
            datas:[],
            filter_datas:[],
            sums:[],
            dis:1,
        }
    }

    componentDidMount() {
        let client_token = new URLSearchParams(this.props.location.search).get("client_token");
        this.setState({
            client_token: client_token,
        }, () => {
            this.fetchdata()
        })
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.mobile_current_status;
        let client_token = new URLSearchParams(this.props.location.search).get("client_token");

        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            client_token:client_token,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                this.setState({
                    dis:2,
                })
            } else {
                this.setState({
                    datas:res.data.datas,
                    filter_datas:res.data.datas,
                    sums:res.data.sums,
                    dis:3,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.plate_no.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filter_datas: temp,
        })
    }
    render() {
        let url=myconfig.redirecttomobile_home+"?client_token="+this.state.client_token
        let dis=null;
        if(this.state.dis===1)
            dis=<p>Loading...</p>
        else if(this.state.dis===2)
            dis=<p>Unauthorized usage</p>
        else if(this.state.dis===3) {
            let sums = this.state.sums.map(v => {
                return (
                    <p>
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let datas=this.state.datas
                            datas=datas.filter(v2=>{
                                if(v2.status===v.status)
                                    return v2
                            })
                            this.setState({
                                filter_datas:datas,
                            })

                        }}>
                            {v.status} ({v.count})
                        </a>
                    </p>
                )
            })
            let count = 0;
            let tdata2 = this.state.filter_datas.map(value => {
                count=count+1;
                return (<tr>
                    <td>{count}</td>
                    <td>{value.plate_no}</td>
                    <td>{value.driver_name}</td>
                    <td>{value.trailer_no}</td>
                    <td>{value.allocated_date}</td>
                    <td>{value.label_order}</td>
                    <td><p title={value.long_name}>{value.short_name}</p></td>
                    <td>{value.update_time}</td>
                    <td>{value.status}</td>
                </tr>)
            })
            dis = <>
                <input type="text" name="txt_search" onChange={this.inputsearch} placeholder="Search"/>
                {sums}
                <table>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>TRUCK NO.</th>
                        <th>DRIVER NAME</th>
                        <th>TRAILER #</th>
                        <th>ALLOCATED DATE</th>
                        <th>ORDER</th>
                        <th>LOCATION</th>
                        <th>TIME</th>
                        <th>STATUS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tdata2}
                    </tbody>
                </table>
            </>
        }
        return (
            <div>
                <a href={url}>Back</a>
                <p>Current Status</p>
                {dis}
            </div>
        )
    }
}

export default Mobile_current_status;
