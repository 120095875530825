import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Road_route extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            txtsearch: '',

            wiz: 1,
            iscreate: false,
            id:-1,
            fin_loc:'',
            distance:'',
            empty_allowance:'',
            empty_fuel_cost:'',
            empty_paring:'',
            empty_other_cost:'',
            load_allowance:'',
            load_fuel_cost:'',
            load_paring:'',
            load_other_cost:'',

            ini_locs: [],
            ini_loc_selected: null,

            fin_locs: [],
            fin_loc_selected: null,

            temp_empty_fuel:[],
            temp_load_fuel:[],
        }
    }
    handelchange_ini_loc = (ini_loc_selected) => {
        this.setState({
            ini_loc_selected:ini_loc_selected,
        })
    }
    handelchange_fin_loc = (fin_loc_selected) => {
        this.setState({
            fin_loc_selected:fin_loc_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
        this.fetchdata_2()
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata_2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_road_route_obj;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    ini_locs: res.data.datas,
                    fin_locs: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_road_routes;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.ini_loc_con.toUpperCase().includes(value.toUpperCase()) ||
                    v.fin_loc_con.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            distance:'',
            empty_allowance:'',
            empty_fuel_cost:'',
            empty_paring:'',
            empty_other_cost:'',
            load_allowance:'',
            load_fuel_cost:'',
            load_paring:'',
            load_other_cost:'',

            pre_diem_rate:'4.82',
            temp_empty_fuel:[],
            temp_load_fuel:[],
            temp_empty_millage:'',
            temp_empty_rate:'',
            temp_load_millage:'',
            temp_load_rate:'',
            a_f_empty:0,
            a_f_load:0,
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0) {

            this.setState({
                wiz: 2,
                iscreate: false,
                id: id,
                ini_loc_selected:{value:temp[0].ini_loc,label:temp[0].ini_loc_con},
                fin_loc_selected:{value:temp[0].fin_loc,label:temp[0].fin_loc_con},
                distance: temp[0].distance,
                /*    empty_allowance: temp[0].empty_allowance,
                    empty_fuel_cost: temp[0].empty_fuel_cost,
                    empty_paring: temp[0].empty_paring,
                    empty_other_cost: temp[0].empty_other_cost,
                    load_allowance: temp[0].load_allowance,
                    load_fuel_cost: temp[0].load_fuel_cost,
                    load_paring: temp[0].load_paring,
                    load_other_cost: temp[0].load_other_cost,*/
                pre_diem_rate:'4.82',
                temp_empty_fuel:[],
                temp_load_fuel:[],
                temp_empty_millage:'',
                temp_empty_rate:'',
                temp_load_millage:'',
                temp_load_rate:'',
                a_f_empty:0,
                a_f_load:0,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            distance:'',
            empty_allowance:'',
            empty_fuel_cost:'',
            empty_paring:'',
            empty_other_cost:'',
            load_allowance:'',
            load_fuel_cost:'',
            load_paring:'',
            load_other_cost:'',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.ini_loc_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select initial location.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.fin_loc_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select final location type.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.create_road_route;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                coc:this.state.coc,
                ini_loc:this.state.ini_loc_selected.value,
                fin_loc:this.state.fin_loc_selected.value,
                distance:this.state.distance,
                empty_allowance:this.state.empty_allowance,
                empty_fuel_cost:this.state.empty_fuel_cost,
                empty_paring:this.state.empty_paring,
                empty_other_cost:this.state.empty_other_cost,
                load_allowance:this.state.load_allowance,
                load_fuel_cost:this.state.load_fuel_cost,
                load_paring:this.state.load_paring,
                load_other_cost:this.state.load_other_cost,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_road_route;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            distance:this.state.distance,
            empty_allowance:this.state.empty_allowance,
            empty_fuel_cost:this.state.empty_fuel_cost,
            empty_paring:this.state.empty_paring,
            empty_other_cost:this.state.empty_other_cost,
            load_allowance:this.state.load_allowance,
            load_fuel_cost:this.state.load_fuel_cost,
            load_paring:this.state.load_paring,
            load_other_cost:this.state.load_other_cost,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value.id)}><i className="fas fa-edit px-1"></i></a>
                    <a href=""><i className="fas fa-trash px-1 text-danger"></i></a>
                </td>
                <td>{value.ini_loc_con}</td>
                <td>{value.fin_loc_con}</td>
                <td>{value.distance}</td>
                <td>{value.empty_allowance}</td>
                <td>{value.empty_fuel_cost}</td>
                <td>{value.empty_paring}</td>
                <td>{value.empty_other_cost}</td>
                <td>{value.load_allowance}</td>
                <td>{value.load_fuel_cost}</td>
                <td>{value.load_paring}</td>
                <td>{value.load_other_cost}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-block" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btnnew}>New
                        Route</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed table-responsive">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Initial Location</th>
                            <th className="col-md-1">Final Location</th>
                            <th className="col-md-1">Distance</th>
                            <th className="col-md-1">Allowance (empty)</th>
                            <th className="col-md-1">Fuel (empty)</th>
                            <th className="col-md-1">Parking (empty)</th>
                            <th className="col-md-1">Other (empty)</th>
                            <th className="col-md-1">Allowance (loaded)</th>
                            <th className="col-md-1">Fuel (loaded)</th>
                            <th className="col-md-1">Parking (loaded)</th>
                            <th className="col-md-1">Other (loaded)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    btn_next_wiz_2 = (event) => {
        event.preventDefault();
        if(this.state.ini_loc_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select initial location.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.fin_loc_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select final location type.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.distance===""||isNaN(this.state.distance)){
            Swal.fire({
                title: 'Error!',
                text: "Distance must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.distance<=0){
            Swal.fire({
                title: 'Error!',
                text: "Distance must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            this.setState({
                wiz:3,
            })
        }
    }
    wiz_2 = () => {
        let header_1 = "Add Route"
        let isdisabled=false;
        if (!this.state.iscreate) {
            header_1 = "Edit Route"
            isdisabled = true;
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Initial Location</label>
                                        <Select
                                            isDisabled={isdisabled}
                                            value={this.state.ini_loc_selected}
                                            onChange={this.handelchange_ini_loc}
                                            options={this.state.ini_locs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <Select
                                            isDisabled={isdisabled}
                                            value={this.state.fin_loc_selected}
                                            onChange={this.handelchange_fin_loc}
                                            options={this.state.fin_locs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Distance</label>
                                        <input type="text" className="form-control form-control-sm" name="distance"
                                               value={this.state.distance} onChange={this.inputmgt}
                                               placeholder="Distance"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btn_next_wiz_2}> Next
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}} onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Pre-Diem Rate</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Pre-diem Rate</label>
                                        <input type="text" className="form-control form-control-sm" name="pre_diem_rate"
                                               value={this.state.pre_diem_rate} onChange={this.inputmgt}
                                               placeholder="Pre-diem Rate"/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}} className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:2,
                                                    })
                                                }}> Previous
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} className="btn btn-block btn-sm"
                                                onClick={this.btn_next_wiz_3}> Next
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}} className="btn btn-block btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    btn_next_wiz_3 = (event) => {
        event.preventDefault();
        if(this.state.pre_diem_rate===""||isNaN(this.state.pre_diem_rate)){
            Swal.fire({
                title: 'Error!',
                text: "Pre-diem rate must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.pre_diem_rate<=0){
            Swal.fire({
                title: 'Error!',
                text: "Pre-diem must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            this.setState({
                wiz:4,
            })
        }
    }
    wiz_4 = () => {
        let temp_empty_millage=0;
        let temp_empty_rate=0;
        let e_t_data=this.state.temp_empty_fuel.map(v=>{
            temp_empty_millage=temp_empty_millage+(v.millage*1)
            temp_empty_rate=(temp_empty_rate+(temp_empty_millage/(v.rate*1)));
            return(<tr>
                <td><a href="" onClick={event => {
                    event.preventDefault()
                    let temp_empty_fuel=this.state.temp_empty_fuel.filter(v2=>{
                        if(v2.id!==v.id)
                            return v2
                    })
                    this.setState({
                        temp_empty_fuel:temp_empty_fuel,
                    })
                }}>X</a></td>
                <td>{v.millage}</td>
                <td>{v.rate}</td>
            </tr>)
        })

        let temp_load_millage=0;
        let temp_load_rate=0;
        let l_t_data=this.state.temp_load_fuel.map(v=>{
            temp_load_millage=temp_load_millage+(v.millage*1)
            temp_load_rate=(temp_load_rate+(temp_load_millage/(v.rate*1)));
            return(<tr>
                <td><a href="" onClick={event => {
                    event.preventDefault()
                    let temp_load_fuel=this.state.temp_load_fuel.filter(v2=>{
                        if(v2.id!==v.id)
                            return v2
                    })
                    this.setState({
                        temp_load_fuel:temp_load_fuel,
                    })
                }}>X</a></td>
                <td>{v.millage}</td>
                <td>{v.rate}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Fuel Calculation</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Empty Fuel</h4>
                                </div>
                                <div className="col-md-6">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th className="col-md-2"></th>
                                            <th className="col-md-6">Millage</th>
                                            <th className="col-md-6">Fuel Rate</th>
                                        </tr>
                                        {e_t_data}
                                    </table>
                                    <p><strong>Expected Millage: </strong>{this.state.distance}</p>
                                    <p><strong>Total Millage: </strong>{temp_empty_millage}</p>
                                    <p><strong>Fuel (L): </strong>{(temp_empty_rate*1).toFixed(3)}</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Empty Millage</label>
                                                <input type="text" className="form-control form-control-sm" name="temp_empty_millage"
                                                       value={this.state.temp_empty_millage} onChange={this.inputmgt}
                                                       placeholder="Empty Millage"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Empty Fuel Rate</label>
                                                <input type="text" className="form-control form-control-sm" name="temp_empty_rate"
                                                       value={this.state.temp_empty_rate} onChange={this.inputmgt}
                                                       placeholder="Empty Fuel Rate"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button className="btn btn-block" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btn_add_temp_empty}>ADD</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 pt-3">
                                    <h4>Loaded Fuel</h4>
                                </div>
                                <div className="col-md-6">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th className="col-md-2"></th>
                                            <th className="col-md-6">Millage</th>
                                            <th className="col-md-6">Fuel Rate</th>
                                        </tr>
                                        {l_t_data}
                                    </table>
                                    <p><strong>Expected Millage: </strong>{this.state.distance}</p>
                                    <p><strong>Total Millage: </strong>{temp_load_millage}</p>
                                    <p><strong>Fuel (L): </strong>{(temp_load_rate*1).toFixed(3)}</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Loaded Millage</label>
                                                <input type="text" className="form-control form-control-sm" name="temp_load_millage"
                                                       value={this.state.temp_load_millage} onChange={this.inputmgt}
                                                       placeholder="Loaded Millage"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Loaded Fuel Rate</label>
                                                <input type="text" className="form-control form-control-sm" name="temp_load_rate"
                                                       value={this.state.temp_load_rate} onChange={this.inputmgt}
                                                       placeholder="Loaded Fuel Rate"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button className="btn btn-block" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}  onClick={this.btn_add_temp_load}>ADD</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Additional Empty Fuel (L)</label>
                                        <input type="text" className="form-control form-control-sm" name="a_f_empty"
                                               value={this.state.a_f_empty} onChange={this.inputmgt}
                                               placeholder="Additional Empty Fuel (L)"/>
                                    </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Additional Loaded Fuel (L)</label>
                                        <input type="text" className="form-control form-control-sm" name="a_f_load"
                                               value={this.state.a_f_load} onChange={this.inputmgt}
                                               placeholder="Additional Loaded Fuel (L)"/>
                                    </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:2,
                                                    })
                                                }}> Previous
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                onClick={this.btn_next_wiz_4}> Next
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    btn_next_wiz_4 = (event) => {
        event.preventDefault();
        if(this.state.a_f_empty===""||isNaN(this.state.a_f_empty)){
            Swal.fire({
                title: 'Error!',
                text: "Additional Empty Fuel must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.a_f_empty<0){
            Swal.fire({
                title: 'Error!',
                text: "Additional empty fuel greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.a_f_load===""||isNaN(this.state.a_f_load)){
            Swal.fire({
                title: 'Error!',
                text: "Additional Load Fuel must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.a_f_load<0){
            Swal.fire({
                title: 'Error!',
                text: "Additional loaded fuel greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            /*    empty_allowance: temp[0].empty_allowance,
               empty_fuel_cost: temp[0].empty_fuel_cost,
               empty_paring: temp[0].empty_paring,
               empty_other_cost: temp[0].empty_other_cost,
               load_allowance: temp[0].load_allowance,
               load_fuel_cost: temp[0].load_fuel_cost,
               load_paring: temp[0].load_paring,
               load_other_cost: temp[0].load_other_cost,*/
            let empty_allowance=(this.state.pre_diem_rate*1)*(this.state.distance*1)*0.25;
            empty_allowance=empty_allowance.toFixed(3);

            let load_allowance=(this.state.pre_diem_rate*1)*(this.state.distance*1)*0.75;
            load_allowance=load_allowance.toFixed(3);

            let empty_fuel_cost=0;
            this.state.temp_empty_fuel.map(v=>{
                empty_fuel_cost=empty_fuel_cost+((v.millage*1)/(v.rate*1))
            })
            empty_fuel_cost=empty_fuel_cost+(this.state.a_f_empty*1);
            empty_fuel_cost=empty_fuel_cost.toFixed(3);

            let load_fuel_cost=0;
            this.state.temp_load_fuel.map(v=>{
                load_fuel_cost=load_fuel_cost+((v.millage*1)/(v.rate*1))
            })
            load_fuel_cost=load_fuel_cost+(this.state.a_f_load*1);
            load_fuel_cost=load_fuel_cost.toFixed(3);

            let load_paring=3950;
            if((this.state.distance*1)<600)
                load_paring=3250;

            this.setState({
                empty_allowance: empty_allowance,
                empty_fuel_cost: empty_fuel_cost,
                empty_paring: 0,
                empty_other_cost: 0,
                load_allowance: load_allowance,
                load_fuel_cost: load_fuel_cost,
                load_paring: load_paring,
                load_other_cost: 0,
                wiz:5,
            })
        }
    }
    btn_add_temp_empty = (event) => {
        event.preventDefault();
        if (this.state.temp_empty_millage === "" || isNaN(this.state.temp_empty_millage)) {
            Swal.fire({
                title: 'Error!',
                text: "Empty Millage must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.temp_empty_millage <= 0) {
            Swal.fire({
                title: 'Error!',
                text: "Empty Millage must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.temp_empty_rate === "" || isNaN(this.state.temp_empty_rate)) {
            Swal.fire({
                title: 'Error!',
                text: "Empty Rate must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.temp_empty_rate <= 0) {
            Swal.fire({
                title: 'Error!',
                text: "Empty Rate must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {

            let temp_empty_fuel=this.state.temp_empty_fuel;
            temp_empty_fuel.push({
                id:temp_empty_fuel.length+1,
                millage:this.state.temp_empty_millage,
                rate:this.state.temp_empty_rate,
            })
            this.setState({
                temp_empty_fuel:temp_empty_fuel,
                temp_empty_millage:"",
                temp_empty_rate:"",
            })
        }
    }
    btn_add_temp_load = (event) => {
        event.preventDefault();
        if (this.state.temp_load_millage === "" || isNaN(this.state.temp_load_millage)) {
            Swal.fire({
                title: 'Error!',
                text: "Loaded Millage must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.temp_load_millage <= 0) {
            Swal.fire({
                title: 'Error!',
                text: "Loaded Millage must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.temp_load_rate === "" || isNaN(this.state.temp_load_rate)) {
            Swal.fire({
                title: 'Error!',
                text: "Loaded Rate must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.temp_load_rate <= 0) {
            Swal.fire({
                title: 'Error!',
                text: "Loaded Rate must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let temp_load_fuel=this.state.temp_load_fuel;
            temp_load_fuel.push({
                id:temp_load_fuel.length+1,
                millage:this.state.temp_load_millage,
                rate:this.state.temp_load_rate,
            })
            this.setState({
                temp_load_fuel:temp_load_fuel,
                temp_load_millage:"",
                temp_load_rate:"",
            })
        }
    }
    wiz_5 = () => {
        let header_1 = "Add Route"
        let isdisabled=false;
        let btn_1 = <button className="btn btn-block btn-sm" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btncreate}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Route"
            isdisabled = true;
            btn_1 = <button className="btn btn-block btn-sm" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btnupdate}> Edit </button>
        }
        let sum_allowance=0;
        let sum_fuel=0;
        let sum_parking=0;
        let sum_other=0;
        if(
            this.state.empty_allowance!==""&&
            !isNaN(this.state.empty_allowance) &&
            this.state.load_allowance!==""&&
            !isNaN(this.state.load_allowance)
        ) {
            sum_allowance=sum_allowance+(this.state.empty_allowance*1)+(this.state.load_allowance*1);
        }

        if(
            this.state.empty_fuel_cost!==""&&
            !isNaN(this.state.empty_fuel_cost) &&
            this.state.load_fuel_cost!==""&&
            !isNaN(this.state.load_fuel_cost)
        ) {
            sum_fuel=sum_fuel+(this.state.empty_fuel_cost*1)+(this.state.load_fuel_cost*1);
        }

        if(
            this.state.empty_paring!==""&&
            !isNaN(this.state.empty_paring) &&
            this.state.load_paring!==""&&
            !isNaN(this.state.load_paring)
        ) {
            sum_parking=sum_parking+(this.state.empty_paring*1)+(this.state.load_paring*1);
        }

        if(
            this.state.empty_other_cost!==""&&
            !isNaN(this.state.empty_other_cost) &&
            this.state.load_other_cost!==""&&
            !isNaN(this.state.load_other_cost)
        ) {
            sum_parking=sum_parking+(this.state.empty_other_cost*1)+(this.state.load_other_cost*1);
        }
        sum_allowance=sum_allowance.toFixed(3);
        sum_fuel=sum_fuel.toFixed(3);
        sum_parking=sum_parking.toFixed(3);
        sum_other=sum_other.toFixed(3);
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Initial Location</label>
                                        <Select
                                            isDisabled={isdisabled}
                                            value={this.state.ini_loc_selected}
                                            onChange={this.handelchange_ini_loc}
                                            options={this.state.ini_locs}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <Select
                                            isDisabled={isdisabled}
                                            value={this.state.fin_loc_selected}
                                            onChange={this.handelchange_fin_loc}
                                            options={this.state.fin_locs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Distance</label>
                                        <input type="text" className="form-control form-control-sm" name="distance"
                                               value={this.state.distance} onChange={this.inputmgt}
                                               placeholder="Distance"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Empty Allowance</label>
                                        <input type="text" className="form-control form-control-sm" name="empty_allowance"
                                               value={this.state.empty_allowance} onChange={this.inputmgt}
                                               placeholder="Empty Allowance"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Empty Fuel (L)</label>
                                        <input type="text" className="form-control form-control-sm" name="empty_fuel_cost"
                                               value={this.state.empty_fuel_cost} onChange={this.inputmgt}
                                               placeholder="Empty Fuel (L)"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Empty Parking Cost</label>
                                        <input type="text" className="form-control form-control-sm" name="empty_paring"
                                               value={this.state.empty_paring} onChange={this.inputmgt}
                                               placeholder="Empty Parking Cost"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Empty Other Cost</label>
                                        <input type="text" className="form-control form-control-sm" name="empty_other_cost"
                                               value={this.state.empty_other_cost} onChange={this.inputmgt}
                                               placeholder="Empty Other Cost"/>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Loaded Allowance</label>
                                        <input type="text" className="form-control form-control-sm" name="load_allowance"
                                               value={this.state.load_allowance} onChange={this.inputmgt}
                                               placeholder="Loaded Allowance"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Loaded Fuel (L)</label>
                                        <input type="text" className="form-control form-control-sm" name="load_fuel_cost"
                                               value={this.state.load_fuel_cost} onChange={this.inputmgt}
                                               placeholder="Loaded Fuel (L)"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Loaded Parking Cost</label>
                                        <input type="text" className="form-control form-control-sm" name="load_paring"
                                               value={this.state.load_paring} onChange={this.inputmgt}
                                               placeholder="Loaded Parking Cost"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Loaded Other Cost</label>
                                        <input type="text" className="form-control form-control-sm" name="load_other_cost"
                                               value={this.state.load_other_cost} onChange={this.inputmgt}
                                               placeholder="Loaded Other Cost"/>
                                    </div>
                                </div>
                                <div className="col-md-3 px-3">
                                    <p><strong>Summary Allowance (ETB)</strong></p>
                                </div>
                                <div className="col-md-3 px-3">
                                    <p><strong>Summary Fuel (L)</strong></p>
                                </div>
                                <div className="col-md-3 px-3">
                                    <p><strong>Summary Parking (ETB)</strong></p>
                                </div>
                                <div className="col-md-3 px-3">
                                    <p><strong>Summary Other (ETB) </strong></p>
                                </div>

                                <div className="col-md-3 px-3">
                                    <p>{sum_allowance}</p>
                                </div>
                                <div className="col-md-3 px-3">
                                    <p>{sum_fuel}</p>
                                </div>
                                <div className="col-md-3 px-3">
                                    <p>{sum_parking}</p>
                                </div>
                                <div className="col-md-3 px-3">
                                    <p>{sum_other}</p>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:4,
                                                    })
                                                }}> Previous
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        else if (this.state.wiz === 5)
            dis = this.wiz_5()
        return (
            <>
                <Nav type="road_route"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Road Route</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Road_route;
