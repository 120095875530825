import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Opex_report extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            type:'',


            contain:"-1",
            report_by:"-1",
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            vehicles: [],
            vehicle_selected: null,

            report_exe:true,
            rows_1:[],
            rows_2:[],
        }
    }
    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected:vehicle_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_opex_report;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.vehicles,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.type.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    get_report = (event) => {
        event.preventDefault()
        if(this.state.report_exe){
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.opex_report;
            let vehicle_id=this.state.vehicle_selected==null?-1:this.state.vehicle_selected.value
            this.setState({
                report_exe:false,
            },()=>{
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    st:this.state.st,
                    ed:this.state.ed,
                    vehicle_id:vehicle_id,
                    contain:this.state.contain,
                    report_by:this.state.report_by,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            report_exe:true,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: "Report Fetched successfully",
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v=>{
                            this.setState({
                                rows_1:res.data.rows_1,
                                rows_2:res.data.rows_2,
                                report_exe:true,
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            })
        }
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            iscreate: true,
            company_name: '',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    wiz_1 = () => {
        let report_by=null;
        if(this.state.report_by==="date"){
            report_by=<>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Start Date</label>
                        <input type="date" className="form-control form-control-sm" name="st"
                               value={this.state.st} onChange={this.inputmgt}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>End Date</label>
                        <input type="date" className="form-control form-control-sm" name="ed"
                               value={this.state.ed} onChange={this.inputmgt}/>
                    </div>
                </div>
                <div className="col-md-4">
                </div>
            </>
        }
        if(this.state.report_by==="truck"){
            report_by=<>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Truck</label>
                        <Select
                            value={this.state.vehicle_selected}
                            onChange={this.handelchange_vehicle}
                            options={this.state.vehicles}
                        />
                    </div>
                </div>
                <div className="col-md-8"></div>
            </>
        }
        let report=null;
        if(this.state.report_exe){
            let tdata=this.state.rows_1.map(v=>{
                let temps=this.state.rows_2.filter(v2=>{
                    if(v2.id===v.id)
                        return v2;
                })
                let rows=temps.map(v3=>{
                    if(v3.type==1)
                        return(<th>{v3.data}</th>)
                    else
                    {
                        return(<td>{v3.data}</td>)
                    }
                })
                return (<tr>
                    {rows}
                </tr>)
            })
            report=  <div className="col-md-12">
                <div className="card">
                    <div className="card-header"
                         style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}>
                        <h3 className="card-title">Report Result</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                           onClick={this.btnexport_report}>Download .xlsx</a></div>
                            <div className="table" id="section_to_print">
                                <table id="report" className="table table-sm table-bordered table-responsive">
                                    {tdata}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                                <h3 className="card-title">Report Criteria</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Contain</label>
                                            <select className="form-control form-control-sm" name="contain"
                                                    value={this.state.contain} onChange={this.inputmgt}>
                                                <option value="-1">-- Select Contain --</option>
                                                <option value="all">All</option>
                                                <option value="money">Money</option>
                                                <option value="fuel">Fuel</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Report BY</label>
                                            <select className="form-control form-control-sm" name="report_by"
                                                    value={this.state.report_by} onChange={this.inputmgt}>
                                                <option value="-1">-- Select Report BY --</option>
                                                <option value="truck">Truck</option>
                                                <option value="date">Date</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    </div>
                                    {report_by}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-sm"
                                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}  onClick={this.get_report}> Get Report
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-sm"
                                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}  onClick={this.btncancel}> Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {report}
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="opex_report"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Opex Report</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_report;
