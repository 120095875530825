import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class User_template extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            objusers: [],
            user_templates: [],
            user_template_lists: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            user_template:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_user_template;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objusers:res.data.objusers,
                    datas:res.data.datas,
                    user_templates:res.data.user_templates,
                    user_template_lists:res.data.user_template_lists,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_select_box = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        var len=this.state.user_template_lists.length;
        let objusers=this.state.objusers.map(v=>{
            let isfound=false;
            for(let i=0; i<len;i++){
                if( this.state.user_template_lists[i].objuser_id==v.id && this.state.user_template_lists[i].user_template_id==value){
                    isfound=true
                    break;
                }
            }
            v.is_check=isfound;
            return v;
        })

        this.setState({
            [name]: value,
            objusers: objusers,
        })
    }
    inputmgt_chk = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        let temp=this.state.objusers.map(v=>{
            if(v.id==name)
                v.is_check=event.target.checked
            return v;
        })
        this.setState({
            [name]: value,
            objusers: temp,
        },()=>{
            console.log(this.state.objusers)
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        let objusers=this.state.objusers.map(v=>{
            v.is_check=false;
            return v;
        })
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            user_template: '',
            objusers: objusers,
            existed_template: "-1",
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_user_template_edit;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objusers:res.data.objusers,
                    user_template:res.data.user_template,
                    iscreate:false,
                    id:id,
                    wiz:2,
                    existed_template:"-1",
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            user_template: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.user_template;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            user_template:this.state.user_template,
            objusers:this.state.objusers,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.user_template_edit;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            user_template:this.state.user_template,
            objusers:this.state.objusers,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value.id)}><i className="fas fa-edit px-1"></i></a>
                </td>
                <td>{value.template_name}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}>New
                        Template</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-9">User Template</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let header_1 = "Add User Template"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} > Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit User Template"
            btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} > Edit </button>
        }
        let tdata=this.state.objusers.map(v=>{
            return( <tr>
                <td>{v.object_name}</td>
                <td><input checked={v.is_check} type="checkbox" className="form-control form-control-sm" name={v.id}
                           onChange={this.inputmgt_chk}/></td>
            </tr>)
        })
        let ops=this.state.user_templates.map(v=>{
            return (<option value={v.id}>{v.template_name}</option>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>User Template</label>
                                        <input type="text" className="form-control form-control-sm" name="user_template"
                                               value={this.state.user_template} onChange={this.inputmgt}
                                               placeholder="User Template"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Existed Template</label>
                                        <select type="text" className="form-control form-control-sm" name="existed_template"
                                                value={this.state.existed_template} onChange={this.inputmgt_select_box}>
                                            <option value="-1">-- Select Existed Template --</option>
                                            {ops}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btnupdate} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}  onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-8 pb-3">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th className="col-md-6">Object Name</th>
                                            <th className="col-md-6">Status</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="user_template"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">User Template</h3>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default User_template;
