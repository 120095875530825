import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Status_delivery_detail extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            urls: [],

            order: {},
            wiz: 1,
        }
    }

    componentDidMount() {
        let no = new URLSearchParams(this.props.location.search).get("no");
        console.log(no)
        if (no !== undefined && no !== "" && no!==null) {
            this.setState({
                no: no,
            }, () => this.fetchdata())
        }
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.status_delivery_detail;
        this.setState({
            datas: [],
            urls: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            no: this.state.no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        datas: res.data.datas,
                        urls: res.data.urls,
                        order: res.data.order,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_1 = () => {
        let imgs=this.state.urls.map(v=>{
            let sty="rotate("+v.deg+"deg)"
            return (<>
                    <div className="col-md-12 text-center my-2 border">
                        <img style={{transform:sty}} src={v.url}/>
                    </div>
                    <div className="col-md-12 text-center my-2 border">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let urls=this.state.urls.map(v2=>{
                                if(v2.id===v.id){
                                    v2.deg=v2.deg+90
                                    if(v2.deg>270)
                                        v2.deg=0
                                }
                                return v2
                            });
                            this.setState({
                                urls:urls
                            })
                        }}>Image Rotate</a>
                    </div>
                </>
            )
        })
        let tdata1=null
        let tdata2=null
        if(this.state.datas.length>0){
            let t_2 = this.state.datas.map(value => {
                return (<tr>
                    <td>-</td>
                    <td>{value.company}</td>
                    <td>{value.plate_no}</td>
                    <td>{value.driver_name}</td>
                    <td>{value.volume}</td>
                    <td>{value.fright_no}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>)
            })

            tdata1=  <>
                <tr>
                    <th className="col-md-1">Order #</th>
                    <th className="col-md-2">Client</th>
                    <th className="col-md-2">Origin</th>
                    <th className="col-md-2">Destination</th>
                    <th className="col-md-1">Distance</th>
                    <th className="col-md-1">Total Weight (q)</th>
                    <th className="col-md-1">Deliver By Own (q)</th>
                    <th className="col-md-1">Delivery By Sub-contractor (q)</th>
                    <th className="col-md-1">Remaining Balance (q)</th>
                </tr>
                <tr>
                    <td>{this.state.order.order_no}</td>
                    <td>{this.state.order.client}</td>
                    <td>{this.state.order.org}</td>
                    <td>{this.state.order.des}</td>
                    <td>{this.state.order.distance}</td>
                    <td>{this.state.order.total_weight}</td>
                    <td>{this.state.order.assign_owns}</td>
                    <td>{this.state.order.assign_sub_contractor}</td>
                    <td>{this.state.order.avl_weight}</td>
                </tr>
            </>
            tdata2=  <>
                <tr>
                    <th className="col-md-1"></th>
                    <th className="col-md-2">Company Name</th>
                    <th className="col-md-2">Driver</th>
                    <th className="col-md-2">Plate No</th>
                    <th className="col-md-1">Volume</th>
                    <th className="col-md-1">Fright</th>
                    <th className="col-md-1">-</th>
                    <th className="col-md-1">-</th>
                    <th className="col-md-1">-</th>
                </tr>
                {t_2}
            </>
        }

        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                    <table id="report" className="table table-head-fixed">
                        {tdata1}
                        {tdata2}
                    </table>
                    </div>
                    <div className="col-md-12">
                        {imgs}
                    </div>

                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="status_delivery_detail"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Transport Balance Detail</h3>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Order #</label>
                                                                <input type="text" className="form-control form-control-sm" name="no"
                                                                       value={this.state.no} onChange={this.inputmgt}
                                                                       placeholder="Order #"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <button className="btn btn-block btn-outline-primary btn-sm"
                                                                        onClick={event => {
                                                                            event.preventDefault();
                                                                            this.fetchdata()
                                                                        }}> Get Delivery
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Status_delivery_detail;
