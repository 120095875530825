import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import style2 from "../../style2.css"


let source = axios.CancelToken.source();

class Opex_balance_sheet extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            actuals: [],
            budgets: [],

            data: {},

            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',

            fuels: [],
            vehicles: [],
            vehicle_selected: null,
            is_exe:false,

            fuel_rates: [],
            fuel_rate_selected: null,
            not_given_costs:[],
            not_given_fuels:[],
            cur_cost:'',
            cur_fuel:'',
        }
    }
    handelchange_fuel_rate= (fuel_rate_selected) => {
        this.setState({
            fuel_rate_selected: fuel_rate_selected,
        })
    }
    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected:vehicle_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_opex_balance_sheet;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.vehicles,
                    fuel_rates: res.data.fuel_rates,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            is_exe:false,
            vehicle_selected:null,
        })
    }
    btnget_data = (event) => {
        event.preventDefault();
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (
            this.state.type !== "money"&&
            this.state.type !== "fuel"
        ) {
            Swal.fire({
                title: 'Error!',
                text: "Select type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }else {
            this.setState({
                is_exe: false,
            })
            let redirecttologin = myconfig.redirecttologin;
            let url;
            if (this.state.type==="money")
                url=myconfig.opex_balance_sheet_costs
            else
                url=myconfig.opex_balance_sheet_fuels;
            axios.post(url, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id:this.state.vehicle_selected.value,
                type:this.state.type,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            is_exe: true,
                            data: res.data.data,
                            actuals: res.data.actuals,
                            budgets: res.data.budgets,
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnreconciel = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.opex_reconcile;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.vehicle_selected.value,
            fuels:this.state.fuels,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                                <h3 className="card-title">Vehicle Data</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Vehicle</label>
                                            <Select
                                                value={this.state.vehicle_selected}
                                                onChange={this.handelchange_vehicle}
                                                options={this.state.vehicles}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Type</label>
                                            <select className="form-control form-control-sm" name="type"
                                                    value={this.state.type} onChange={this.inputmgt}>
                                                <option value="-1">-- Select Type -- </option>
                                                <option value="money">Money</option>
                                                <option value="fuel">Fuel</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-sm"
                                                    onClick={this.btnget_data} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Get Data
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                    </div>
                    <div className="col-12">
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let data_budgeted=this.state.budgets.map(v=>{
            return(
                <>
                    <div className="col-6">
                        {v.date}<br/>
                        ({v.reason})
                    </div>
                    <div className="col-6">
                        {v.amount}
                    </div>
                </>
            )
        })
        let data_actual=this.state.actuals.map(v=>{
            return(
                <>
                    <div className="col-6">
                        {v.date}<br/>
                        ({v.reason})
                    </div>
                    <div className="col-6">
                        {v.amount}
                    </div>
                </>
            )
        })
        return (<>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                        <h3 className="card-title">Balance Sheet</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row" id="section_to_print_3">
                            <div className="col-12 no-print">
                                <a href="" onClick={event => {
                                    event.preventDefault();
                                    window.print()
                                }}>Print</a>
                            </div>
                            <div className="col-12">
                                <p><strong>Truck No:- </strong>{this.state.data.veh}</p>
                                <p><strong>Driver:- </strong>{this.state.data.dri}</p>
                                <p><strong>Trailer No:- </strong>{this.state.data.tra}</p>
                                <p><strong>Balance Sheet Type:- </strong>{this.state.data.type}</p>
                            </div>
                            <div className="col-5">
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Budgeted</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Actual</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                            </div>


                            <div className="col-5">
                                <div className="row">
                                    {data_budgeted}
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="row">
                                    {data_actual}
                                </div>
                            </div>
                            <div className="col-2">
                            </div>


                            <div className="col-5">
                                <div className="row">
                                    <div className="col-6">
                                    </div>
                                    <div className="col-6">
                                        <p className="text-left"><strong>{this.state.data.total_budget}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="row">
                                    <div className="col-6">
                                    </div>
                                    <div className="col-6">
                                        <p className="text-left"><strong>{this.state.data.total_acutal}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <p className="text-left"><strong>{this.state.data.dif}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    add_fuel = (event) => {
        event.preventDefault();
        if(this.state.fuel_rate_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select fuel rate",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (
            this.state.type!="birr"&&
            this.state.type!="coupon"&&
            this.state.type!="pad"
        ){
            Swal.fire({
                title: 'Error!',
                text: "Please select type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount==""){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(isNaN(this.state.amount)){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount<0){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let fuels=this.state.fuels;
            let id=fuels.length+1;
            fuels.push({
                id:id,
                station:this.state.fuel_rate_selected.label,
                fuel_rate_id:this.state.fuel_rate_selected.value,
                fuel_rate:(this.state.fuel_rate_selected.fuel_rate*1).toFixed(2),
                type:this.state.type,
                amount:(this.state.amount*1).toFixed(2),
            })
            this.setState({
                fuels: fuels,
                fuel_rate_selected: null,
                type: "-1",
                amount: "",
            })
        }
    }
    render() {
        let dis = null;
        if (this.state.is_exe)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="opex_balance_sheet"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Truck Balance Sheet</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            {this.wiz_1()}
                                            {dis}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_balance_sheet;
