import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Op_refund_app extends Component {

    constructor() {
        super();
        this.state = {

            is_exe:false,
            datas: [],
            filtered: [],
            assing_own_op_refunds: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_op_refund_app;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
            data:{},
            assing_own_op_refunds:[],
        })

        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    get_fright = (id) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_fright_for_op_refund_app;
        this.setState({
            data:{},
            assing_own_op_refunds:[],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id:id,
                    data:res.data.data,
                    assing_own_op_refunds:res.data.data.assing_own_op_refunds,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_app = (event,id) => {
        event.preventDefault();
        if(!this.state.is_exe) {
            Swal.fire({
                title: 'Are you sure you want to approve the refund? You can not undone the operation',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        is_exe:true,
                    })
                    let redirecttologin = myconfig.redirecttologin;
                    let baseurl = myconfig.op_refund_app;
                    axios.post(baseurl, {
                        sid: window.localStorage.getItem("logmgtsys_sid"),
                        id: id,
                    }, {cancelToken: source.token}).then(res => {
                        console.log(res.data)
                        if (res.data.auth == false) {
                            window.localStorage.setItem("logmgtsys_sid", null);
                            window.localStorage.setItem("logmgtsys_username", null);
                            window.localStorage.setItem("logmgtsys_name", null);
                            var win = window.open(redirecttologin, "_self");
                        } else if (res.data.success == false) {
                            Swal.fire({
                                title: 'Error!',
                                text: res.data.msg,
                                icon: 'error',
                                confirmButtonText: 'Okay'
                            })
                            this.setState({
                                is_exe:false,
                            })
                        } else {
                            Swal.fire({
                                title: 'Success!',
                                text: res.data.msg,
                                icon: 'success',
                                confirmButtonText: 'Okay'
                            }).then(v => {
                                this.setState({
                                    is_exe:false,
                                })
                                this.get_fright(this.state.id)
                            })
                        }
                    }).catch((e) => {
                        console.log(e.message);
                    });
                }
            })
        }
        else{
            Swal.fire({
                title: 'Information!',
                text: "Wait until the first operation is done",
                icon: 'info',
                confirmButtonText: 'Okay'
            })
        }
    }
    btn_rej = (event,id) => {
        event.preventDefault();
        if(!this.state.is_exe) {
            Swal.fire({
                title: 'Are you sure you want to reject the refund? You can not undone the operation',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        is_exe:true,
                    })
                    let redirecttologin = myconfig.redirecttologin;
                    let baseurl = myconfig.op_refund_rej;
                    axios.post(baseurl, {
                        sid: window.localStorage.getItem("logmgtsys_sid"),
                        id: id,
                    }, {cancelToken: source.token}).then(res => {
                        console.log(res.data)
                        if (res.data.auth == false) {
                            window.localStorage.setItem("logmgtsys_sid", null);
                            window.localStorage.setItem("logmgtsys_username", null);
                            window.localStorage.setItem("logmgtsys_name", null);
                            var win = window.open(redirecttologin, "_self");
                        } else if (res.data.success == false) {
                            Swal.fire({
                                title: 'Error!',
                                text: res.data.msg,
                                icon: 'error',
                                confirmButtonText: 'Okay'
                            })
                            this.setState({
                                is_exe:false,
                            })
                        } else {
                            Swal.fire({
                                title: 'Success!',
                                text: res.data.msg,
                                icon: 'success',
                                confirmButtonText: 'Okay'
                            }).then(v => {
                                this.setState({
                                    is_exe:false,
                                })
                                this.get_fright(this.state.id)
                            })
                        }
                    }).catch((e) => {
                        console.log(e.message);
                    });
                }
            })
        }
        else{
            Swal.fire({
                title: 'Information!',
                text: "Wait until the first operation is done",
                icon: 'info',
                confirmButtonText: 'Okay'
            })
        }
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                coc: temp[0].coc,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.create_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td>
                    <a href="" onClick={event =>{
                        event.preventDefault();
                        this.get_fright(value.assign_own_id)
                    }}>Manage</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.fright_no}</td>
                <td>{value.refund_amount}</td>
                <td>{value.type_con}</td>
                <td>{value.refund_remark}</td>
                <td>{value.req_by}</td>
                <td>{value.req_at}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-2">Truck</th>
                            <th className="col-md-2">Driver</th>
                            <th className="col-md-1">Fright</th>
                            <th className="col-md-1">Amount</th>
                            <th className="col-md-1">Type</th>
                            <th className="col-md-1">Remark</th>
                            <th className="col-md-2">REQ BY</th>
                            <th className="col-md-1">REQ AT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata = this.state.assing_own_op_refunds.map(value => {
            let btn_1=null;
            let btn_2=null;
            let status="";
            if(value.status==="REFUND_REQUESTED"){
                btn_1= <a href="" onClick={event => this.btn_app(event,value.id)}>Approve</a>
                btn_2=  <a href="" onClick={event => this.btn_rej(event,value.id)}>Reject</a>
            }
            else if(value.status==="REFUND_APPROVED")
                status="bg-success";
            else if(value.status==="REFUND_REJECTED")
                status="bg-danger";

            return (<tr className={status}>
                <td>{btn_1}</td>
                <td>{btn_2}</td>
                <td>{value.refund_amount}</td>
                <td>{value.type_con}</td>
                <td>{value.station}</td>
                <td>{value.refund_remark}</td>
                <td>{value.req_by}</td>
                <td>{value.app_by}</td>
                <td>{value.rej_by}</td>
                <td>{value.status}</td>
            </tr>)
        })
        let lst=null
        if(this.state.data.fuels!=null)
            lst=this.state.data.fuels.map(value=>{
                return <li>{value.station} ({value.fuel})</li>
            })
        return (<>
            <div className="col-12">
                <div className="card">
                    <div className="card-header"
                         style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                        <h3 className="card-title">Operation Refund APP</h3>
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Truck #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.plate_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Driver Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.driver_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Trailer No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.trailer_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fright #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.fright_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Client</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Order #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.order_no}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cargo</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.cargo_name}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Distance</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.distance}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Volume</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.volume}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel (L)</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.total_fuel}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Start @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.start_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>ORG</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.org}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>DES</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.des}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>End @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.end_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel</label>
                                    <ul>
                                        {lst}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <table id="report" className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-1">Approve</th>
                                        <th className="col-md-1">Reject</th>
                                        <th className="col-md-1">Amount</th>
                                        <th className="col-md-1">Type</th>
                                        <th className="col-md-1">Station</th>
                                        <th className="col-md-2">Remark</th>
                                        <th className="col-md-1">REQ BY</th>
                                        <th className="col-md-1">APP BY</th>
                                        <th className="col-md-1">REJ BY</th>
                                        <th className="col-md-2">STATUS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn float-right"
                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 1,
                            },()=>this.fetchdata())
                        }}><i
                            className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>

        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="op_refund_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Refund APP</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Op_refund_app;
