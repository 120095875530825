import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import nt from "../../img/companylogo.jpg";
import Select from "react-select";
import style2 from "../../style2.css"

let source = axios.CancelToken.source();

class Invoice_prepare extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            wiz: 1,

            customers: [],
            customer_details: [],
            filtered_customer_details: [],
            customer_selected: null,
            data:{},
        }
    }

    handelchange_customer = (customer_selected) => {
        let filtered_customer_details=this.state.customer_details.map(v=>{
            if(v.client_id==customer_selected.value)
                return v
        })
        this.setState({
            customer_selected:customer_selected,
            data:customer_selected,
            filtered_customer_details:filtered_customer_details,
        })
    }
    componentDidMount() {
    }
    get_data = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_invoice_data_for_invoice_prepare;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st:this.state.st,
            ed:this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    customers: res.data.clients,
                    customer_details: res.data.datas,
                    customer_selected:null,
                    filtered_customer_details: [],
                    data:{},
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    wiz_1 = () => {
        return (<>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                        <h3 className="card-title">Invoice Date</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Start Date</label>
                                    <input type="date" className="form-control form-control-sm" name="st"
                                           value={this.state.st} onChange={this.inputmgt}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>End Date</label>
                                    <input type="date" className="form-control form-control-sm" name="ed"
                                           value={this.state.ed} onChange={this.inputmgt}/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <button className="btn btn-block btn-sm"
                                            onClick={this.get_data} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let dis=null;
        if(this.state.customer_selected!=null){
            let tdata=this.state.filtered_customer_details.map(v=>{
                if(v!=undefined)
                    return(
                        <tr>
                            <td>{v.org}</td>
                            <td>{v.des}</td>
                            <td>{v.cargo}</td>
                            <td>{v.rate_formatted}</td>
                            <td>{v.fright_no}</td>
                            <td>{v.plate_no}</td>
                            <td>{v.volume}</td>
                            <td>{v.unload_volume}</td>
                            <td>{v.payment_formatted}</td>
                        </tr>
                    )
            })
            dis=<>
                <div className="col-12 no-print">
                    <a href="" onClick={event => {
                        event.preventDefault();
                        window.print()
                    }}>Print</a>
                </div>
                <div className="col-12">
                    <div className="row" id="section_to_print_3">
                        <div className="col-4">
                            <img className="img w-75" src={nt}></img>
                        </div>
                        <div className="col-4">
                        </div>
                        <div className="col-4 text-center">
                            <h1>INVOICE</h1>
                        </div>
                        <div className="col-6 py-2">
                            <strong>Invoice Date</strong><br/>
                            {this.state.data.invoice_date}
                        </div>
                        <div className="col-6 py-2 text-center">
                        </div>
                        <div className="col-6 py-2 text-center">
                            <strong>{this.state.data.own_company_name}</strong><br/>
                            {this.state.data.own_contact}
                            <br/>
                            {this.state.data.own_phone}
                        </div>
                        <div className="col-6 py-2 text-center">
                            <strong>{this.state.data.label}</strong><br/>
                            {this.state.data.contact_person}
                            <br/>
                            {this.state.data.phone}
                        </div>
                        <div className="col-12">
                            <table className="table table-sm table-bordered">
                                <tr>
                                    <th className="col-2">Origin</th>
                                    <th className="col-2">Destination</th>
                                    <th className="col-1">Cargo</th>
                                    <th className="col-1">Transport Rate</th>
                                    <th className="col-1">Freight #</th>
                                    <th className="col-2">Truck #</th>
                                    <th className="col-1">Loaded Weight (q)</th>
                                    <th className="col-1">Unloaded Weight (q)</th>
                                    <th className="col-1">Total Payment (ETB)</th>
                                </tr>
                                {tdata}
                            </table>
                        </div>
                        <div className="col-12 text-right">
                            <p><strong>Total Payment: </strong> {this.state.data.total_payment_formatted}</p>
                        </div>
                    </div>
                </div>

            </>
        }
        return (<>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                        <h3 className="card-title">Invoice Report</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 pb-4">
                                <div className="card">
                                    <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                        <h3 className="card-title">Select Customer</h3>
                                        <div className="card-tools">
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Client</label>
                                                    <Select
                                                        value={this.state.customer_selected}
                                                        onChange={this.handelchange_customer}
                                                        options={this.state.customers}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pb-4">
                            </div>
                            {dis}
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn float-right"
                                style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}
                                onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 1})
                        }}><i
                            className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="invoice_prepare"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Invoice</h3>
                                            <div className="card-tools">
                                            </div>

                                        </div>
                                        <div className="card-body">
                                            {dis}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Invoice_prepare;
