export const myconfig =
    {
            "audit_assignment": "https://api.mayag.ztsplc.com/api/audit/audit_assignment",


            "get_obj_for_opex_reconcile": "https://api.mayag.ztsplc.com/api/reconcile/get_obj_for_opex_reconcile",
            "opex_reconcile": "https://api.mayag.ztsplc.com/api/reconcile/opex_reconcile",
            "opex_reconcile_with_fuel": "https://api.mayag.ztsplc.com/api/reconcile/opex_reconcile_with_fuel",


            "get_obj_for_report_master_trip": "https://api.mayag.ztsplc.com/api/report/get_obj_for_report_master_trip",
            "report_master_trip": "https://api.mayag.ztsplc.com/api/report/report_master_trip",
            "get_client_for_report_order_allocation": "https://api.mayag.ztsplc.com/api/report/get_client_for_report_order_allocation",
            "get_order_for_report_order_allocation": "https://api.mayag.ztsplc.com/api/report/get_order_for_report_order_allocation",
            "report_order_allocation": "https://api.mayag.ztsplc.com/api/report/report_order_allocation",
            "get_orders_for_report_status_update": "https://api.mayag.ztsplc.com/api/report/get_orders_for_report_status_update",
            "get_assigns_for_report_status_update": "https://api.mayag.ztsplc.com/api/report/get_assigns_for_report_status_update",
            "get_location_for_report_status_update": "https://api.mayag.ztsplc.com/api/report/get_location_for_report_status_update",
            "report_current_status": "https://api.mayag.ztsplc.com/api/report/report_current_status",
            "get_customer_for_report_status": "https://api.mayag.ztsplc.com/api/report/get_customer_for_report_status",
            "get_order_for_report_status": "https://api.mayag.ztsplc.com/api/report/get_order_for_report_status",
            "report_status": "https://api.mayag.ztsplc.com/api/report/report_status",


            "get_obj_wiz_1_for_order_add_expence_req": "https://api.mayag.ztsplc.com/api/orderexpence/get_obj_wiz_1_for_order_add_expence_req",
            "get_obj_wiz_2_for_order_add_expence_req": "https://api.mayag.ztsplc.com/api/orderexpence/get_obj_wiz_2_for_order_add_expence_req",
            "get_obj_wiz_3_for_order_add_expence_req": "https://api.mayag.ztsplc.com/api/orderexpence/get_obj_wiz_3_for_order_add_expence_req",
            "order_add_expence_req_create": "https://api.mayag.ztsplc.com/api/orderexpence/order_add_expence_req_create",
            "order_add_expence_req_edit": "https://api.mayag.ztsplc.com/api/orderexpence/order_add_expence_req_edit",
            "order_add_expence_req_remove": "https://api.mayag.ztsplc.com/api/orderexpence/order_add_expence_req_remove",
            "get_obj_wiz_1_for_order_add_expence_app": "https://api.mayag.ztsplc.com/api/orderexpence/get_obj_wiz_1_for_order_add_expence_app",
            "get_obj_wiz_2_for_order_add_expence_app": "https://api.mayag.ztsplc.com/api/orderexpence/get_obj_wiz_2_for_order_add_expence_app",
            "app_rej_for_order_add_expence_app": "https://api.mayag.ztsplc.com/api/orderexpence/app_rej_for_order_add_expence_app",
            "get_obj_wiz_1_for_order_add_expence_set": "https://api.mayag.ztsplc.com/api/orderexpence/get_obj_wiz_1_for_order_add_expence_set",
            "get_obj_wiz_2_for_order_add_expence_set": "https://api.mayag.ztsplc.com/api/orderexpence/get_obj_wiz_2_for_order_add_expence_set",
            "order_add_expence_set": "https://api.mayag.ztsplc.com/api/orderexpence/order_add_expence_set",

            "get_obj_opp": "https://api.mayag.ztsplc.com/api/comand/get_obj_opp",
            "opp_manage": "https://api.mayag.ztsplc.com/api/comand/opp_manage",
            "follow_up_report": "https://api.mayag.ztsplc.com/api/comand/follow_up_report",


            "get_vehicle_for_document_mgt": "https://api.mayag.ztsplc.com/api/document/get_vehicle_for_document_mgt",
            "get_data_single_for_document_mgt": "https://api.mayag.ztsplc.com/api/document/get_data_single_for_document_mgt",
            "document_mgt_2": "https://api.mayag.ztsplc.com/api/document/document_mgt",


            "get_obj_for_order_weight_req": "https://api.mayag.ztsplc.com/api/orderweight/get_obj_for_order_weight_req",
            "order_weight_req": "https://api.mayag.ztsplc.com/api/orderweight/order_weight_req",
            "get_obj_single_for_order_weight_req": "https://api.mayag.ztsplc.com/api/orderweight/get_obj_single_for_order_weight_req",
            "get_obj_for_order_weight_app": "https://api.mayag.ztsplc.com/api/orderweight/get_obj_for_order_weight_app",
            "get_obj_single_for_order_weight_app": "https://api.mayag.ztsplc.com/api/orderweight/get_obj_single_for_order_weight_app",
            "app_rej_order_weight_app": "https://api.mayag.ztsplc.com/api/orderweight/app_rej_order_weight_app",

            "get_vehicle_for_pos_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_for_pos_refund_req",
            "get_vehicle_single_for_pos_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_single_for_pos_refund_req",
            "create_for_pos_refund_req": "https://api.mayag.ztsplc.com/api/refund/create_for_pos_refund_req",
            "get_req_single_cost_edit_for_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_req_single_cost_edit_for_refund_req",
            "get_req_single_fuel_edit_for_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_req_single_fuel_edit_for_refund_req",
            "edit_cost_for_pos_refund_req": "https://api.mayag.ztsplc.com/api/refund/edit_cost_for_pos_refund_req",
            "edit_fuel_for_pos_refund_req": "https://api.mayag.ztsplc.com/api/refund/edit_fuel_for_pos_refund_req",
            "get_vehicle_for_pos_refund_app": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_for_pos_refund_app",
            "get_vehicle_single_for_pos_refund_app": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_single_for_pos_refund_app",
            "app_rej_cost_for_pos_refund_app": "https://api.mayag.ztsplc.com/api/refund/app_rej_cost_for_pos_refund_app",
            "app_rej_fuel_for_pos_refund_app": "https://api.mayag.ztsplc.com/api/refund/app_rej_fuel_for_pos_refund_app",
            "get_vehicle_for_neg_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_for_neg_refund_req",
            "get_vehicle_single_for_neg_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_single_for_neg_refund_req",
            "create_for_neg_refund_req": "https://api.mayag.ztsplc.com/api/refund/create_for_neg_refund_req",
            "get_req_single_cost_edit_for_neg_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_req_single_cost_edit_for_neg_refund_req",
            "get_req_single_fuel_edit_for_neg_refund_req": "https://api.mayag.ztsplc.com/api/refund/get_req_single_fuel_edit_for_neg_refund_req",
            "edit_cost_for_neg_refund_req": "https://api.mayag.ztsplc.com/api/refund/edit_cost_for_neg_refund_req",
            "edit_fuel_for_neg_refund_req": "https://api.mayag.ztsplc.com/api/refund/edit_fuel_for_neg_refund_req",
            "app_rej_cost_for_neg_refund_app": "https://api.mayag.ztsplc.com/api/refund/app_rej_cost_for_neg_refund_app",
            "app_rej_fuel_for_neg_refund_app": "https://api.mayag.ztsplc.com/api/refund/app_rej_fuel_for_neg_refund_app",

            "get_vehicle_for_refund_to_company_req": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_for_refund_to_company_req",
            "get_costs_for_refund_to_company_req": "https://api.mayag.ztsplc.com/api/refund/get_costs_for_refund_to_company_req",
            "get_fuels_for_refund_to_company_req": "https://api.mayag.ztsplc.com/api/refund/get_fuels_for_refund_to_company_req",
            "get_single_cost_for_refund_to_company_req": "https://api.mayag.ztsplc.com/api/refund/get_single_cost_for_refund_to_company_req",
            "get_single_fuel_for_refund_to_company_req": "https://api.mayag.ztsplc.com/api/refund/get_single_fuel_for_refund_to_company_req",
            "cost_for_refund_to_company_req": "https://api.mayag.ztsplc.com/api/refund/cost_for_refund_to_company_req",
            "fuel_for_refund_to_company_req": "https://api.mayag.ztsplc.com/api/refund/fuel_for_refund_to_company_req",
            "get_obj_for_refund_to_company_app": "https://api.mayag.ztsplc.com/api/refund/get_obj_for_refund_to_company_app",
            "get_single_cost_for_refund_to_company_app": "https://api.mayag.ztsplc.com/api/refund/get_single_cost_for_refund_to_company_app",
            "get_single_fuel_for_refund_to_company_app": "https://api.mayag.ztsplc.com/api/refund/get_single_fuel_for_refund_to_company_app",
            "refund_cost_to_company_app": "https://api.mayag.ztsplc.com/api/refund/refund_cost_to_company_app",
            "refund_fuel_to_company_app": "https://api.mayag.ztsplc.com/api/refund/refund_fuel_to_company_app",
            "get_vehicle_for_neg_refund_app": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_for_neg_refund_app",
            "get_vehicle_single_for_neg_refund_app": "https://api.mayag.ztsplc.com/api/refund/get_vehicle_single_for_neg_refund_app",


            "get_obj_for_update_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/get_obj_for_update_assign_vehicle",
            "get_orders_update_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/get_orders_update_assign_vehicle",
            "get_order_single_update_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/get_order_single_update_assign_vehicle",
            "get_route_for_update_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/get_route_for_update_assign_vehicle",
            "update_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/update_assign_vehicle",
            "get_obj_for_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/get_obj_for_assign_vehicle",
            "get_prv_history_for_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/get_prv_history_for_assign_vehicle",
            "get_route_for_assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/get_route_for_assign_vehicle",
            "assign_vehicle": "https://api.mayag.ztsplc.com/api/operation2/assign_vehicle",
            "get_invoice_data_for_invoice_prepare": "https://api.mayag.ztsplc.com/api/operation2/get_invoice_data_for_invoice_prepare",
            "get_payment_not_settle_for_payment_settlement": "https://api.mayag.ztsplc.com/api/operation2/get_payment_not_settle_for_payment_settlement",
            "payment_settlement": "https://api.mayag.ztsplc.com/api/operation2/payment_settlement",
            "get_obj_for_fo_manage": "https://api.mayag.ztsplc.com/api/operation2/get_obj_for_fo_manage",
            "fo_manage": "https://api.mayag.ztsplc.com/api/operation2/fo_manage",
            "get_obj_for_assign_history": "https://api.mayag.ztsplc.com/api/operation2/get_obj_for_assign_history",
            "assign_history": "https://api.mayag.ztsplc.com/api/operation2/assign_history",

            "get_obj_for_pre_assign_req": "https://api.mayag.ztsplc.com/api/preassign/get_obj_for_pre_assign_req",
            "pre_assign_req": "https://api.mayag.ztsplc.com/api/preassign/pre_assign_req",
            "pre_assign_req_edit": "https://api.mayag.ztsplc.com/api/preassign/pre_assign_req_edit",
            "get_single_pre_assign_req": "https://api.mayag.ztsplc.com/api/preassign/get_single_pre_assign_req",
            "get_single_edit_pre_assign_req": "https://api.mayag.ztsplc.com/api/preassign/get_single_edit_pre_assign_req",
            "get_obj_for_pre_assign_app": "https://api.mayag.ztsplc.com/api/preassign/get_obj_for_pre_assign_app",
            "get_single_pre_assign_app": "https://api.mayag.ztsplc.com/api/preassign/get_single_pre_assign_app",
            "pre_assign_app": "https://api.mayag.ztsplc.com/api/preassign/pre_assign_app",
            "get_obj_for_pre_assign_exe": "https://api.mayag.ztsplc.com/api/preassign/get_obj_for_pre_assign_exe",

            "get_message_for_tg_status_sum": "https://api.mayag.ztsplc.com/api/telegram/get_message_for_tg_status_sum",
            "send_message_for_tg_status_sum": "https://api.mayag.ztsplc.com/api/telegram/send_message_for_tg_status_sum",
            "get_message_for_tg_status_det": "https://api.mayag.ztsplc.com/api/telegram/get_message_for_tg_status_det",
            "get_avl_for_tg_status_det": "https://api.mayag.ztsplc.com/api/telegram/get_avl_for_tg_status_det",
            "send_message_for_tg_status_det": "https://api.mayag.ztsplc.com/api/telegram/send_message_for_tg_status_det",
            "get_message_for_tg_status_single": "https://api.mayag.ztsplc.com/api/telegram/get_message_for_tg_status_single",
            "send_message_for_tg_status_single": "https://api.mayag.ztsplc.com/api/telegram/send_message_for_tg_status_single",
            "get_message_for_tg_stop_at_client": "https://api.mayag.ztsplc.com/api/telegram/get_message_for_tg_stop_at_client",
            "send_message_for_tg_stop_at_client": "https://api.mayag.ztsplc.com/api/telegram/send_message_for_tg_stop_at_client",


            "d_operation": "https://api.mayag.ztsplc.com/api/dashboard/d_operation",
            "d_marketing": "https://api.mayag.ztsplc.com/api/dashboard/d_marketing",
            "d_operation_2": "https://api.mayag.ztsplc.com/api/dashboard/d_operation_2",

            "get_skpi_umos": "https://api.mayag.ztsplc.com/api/kpiplan/get_skpi_umos",
            "create_kpi_umo": "https://api.mayag.ztsplc.com/api/kpiplan/create_kpi_umo",
            "edit_kpi_umo": "https://api.mayag.ztsplc.com/api/kpiplan/edit_kpi_umo",
            "get_kpi_descriptions": "https://api.mayag.ztsplc.com/api/kpiplan/get_kpi_descriptions",
            "create_kpi_description": "https://api.mayag.ztsplc.com/api/kpiplan/create_kpi_description",
            "edit_kpi_description": "https://api.mayag.ztsplc.com/api/kpiplan/edit_kpi_description",
            "get_kpi_datas": "https://api.mayag.ztsplc.com/api/kpiplan/get_kpi_datas",
            "create_kpi_data": "https://api.mayag.ztsplc.com/api/kpiplan/create_kpi_data",
            "edit_kpi_data": "https://api.mayag.ztsplc.com/api/kpiplan/edit_kpi_data",
            "get_kpi_data": "https://api.mayag.ztsplc.com/api/kpiplan/get_kpi_data",
            "execute_main_temp": "https://api.mayag.ztsplc.com/api/kpiplan/execute_main_temp",

            "status_purchase": "https://api.mayag.ztsplc.com/api/status/status_purchase",
            "status_cement": "https://api.mayag.ztsplc.com/api/status/status_cement",
            "status_delivery": "https://api.mayag.ztsplc.com/api/status/status_delivery",
            "status_purchase_detail": "https://api.mayag.ztsplc.com/api/status/status_purchase_detail",
            "status_delivery_detail": "https://api.mayag.ztsplc.com/api/status/status_delivery_detail",
            "status_cement_detail": "https://api.mayag.ztsplc.com/api/status/status_cement_detail",
            "status_cement_allocation": "https://api.mayag.ztsplc.com/api/status/status_cement_allocation",

            "pr_prepare": "https://api.mayag.ztsplc.com/api/purchase/pr_prepare",
            "get_data_for_pr_prepare": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_pr_prepare",
            "pr_prepare_edit": "https://api.mayag.ztsplc.com/api/purchase/pr_prepare_edit",

            "get_data_for_pr_check": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_pr_check",
            "po_prepare": "https://api.mayag.ztsplc.com/api/purchase/po_prepare",
            "po_prepare_reset": "https://api.mayag.ztsplc.com/api/purchase/po_prepare_reset",

            "get_data_for_po_chk": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_po_chk",
            "po_chk": "https://api.mayag.ztsplc.com/api/purchase/po_chk",
            "po_chk_reset": "https://api.mayag.ztsplc.com/api/purchase/po_chk_reset",

            "get_data_for_purchase": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_purchase",
            "purchase": "https://api.mayag.ztsplc.com/api/purchase/purchase",
            "get_data_for_purchase_status": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_purchase_status",


            "get_data_for_po_prepare": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_po_prepare",
            "pr_check": "https://api.mayag.ztsplc.com/api/purchase/pr_check",
            "pr_check_reset": "https://api.mayag.ztsplc.com/api/purchase/pr_check_reset",


            "get_data_for_po_app": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_po_app",
            "po_app": "https://api.mayag.ztsplc.com/api/purchase/po_app",
            "po_app_reject": "https://api.mayag.ztsplc.com/api/purchase/po_app_reject",


            "get_data_for_pr_app": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_pr_app",
            "pr_app": "https://api.mayag.ztsplc.com/api/purchase/pr_app",
            "pr_app_reset": "https://api.mayag.ztsplc.com/api/purchase/pr_app_reset",

            "pr_app_del": "https://api.mayag.ztsplc.com/api/purchase/pr_app_del",
            "get_data_for_po": "https://api.mayag.ztsplc.com/api/purchase/get_data_for_po",
            "po": "https://api.mayag.ztsplc.com/api/purchase/po",


            "get_datas_for_order_loading_adv_req": "https://api.mayag.ztsplc.com/api/loadingad/get_datas_for_order_loading_adv_req",
            "order_loading_adv_req": "https://api.mayag.ztsplc.com/api/loadingad/order_loading_adv_req",
            "order_loading_adv_req_delete": "https://api.mayag.ztsplc.com/api/loadingad/order_loading_adv_req_delete",
            "get_datas_for_order_loading_adv_app": "https://api.mayag.ztsplc.com/api/loadingad/get_datas_for_order_loading_adv_app",
            "order_loading_adv_app": "https://api.mayag.ztsplc.com/api/loadingad/order_loading_adv_app",
            "order_loading_adv_app_del": "https://api.mayag.ztsplc.com/api/loadingad/order_loading_adv_app_del",
            "get_datas_for_order_loading_adv": "https://api.mayag.ztsplc.com/api/loadingad/get_datas_for_order_loading_adv",
            "get_datas_for_cement_delivery": "https://api.mayag.ztsplc.com/api/loadingad/get_datas_for_cement_delivery",
            "cement_delivery": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery",
            "cement_delivery_report": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_report",
            "get_cement_delivery_for_cement_delivery_cancel_req": "https://api.mayag.ztsplc.com/api/loadingad/get_cement_delivery_for_cement_delivery_cancel_req",
            "cement_delivery_cancel_req": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_cancel_req",
            "get_cement_delivery_cancel_for_cement_delivery_cancel_req": "https://api.mayag.ztsplc.com/api/loadingad/get_cement_delivery_cancel_for_cement_delivery_cancel_req",
            "cement_delivery_cancel_req_edit": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_cancel_req_edit",
            "cement_delivery_cancel_req_imgs": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_cancel_req_imgs",
            "cement_delivery_cancel_req_delete": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_cancel_req_delete",
            "get_obj_for_cement_delivery_cancel_app": "https://api.mayag.ztsplc.com/api/loadingad/get_obj_for_cement_delivery_cancel_app",
            "cement_delivery_cancel_app_imgs": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_cancel_app_imgs",
            "cement_delivery_cancel_app": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_cancel_app",
            "get_datas_for_cement_delivery_edit": "https://api.mayag.ztsplc.com/api/loadingad/get_datas_for_cement_delivery_edit",
            "cement_delivery_edit": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_edit",
            "cement_delivery_edit_create": "https://api.mayag.ztsplc.com/api/loadingad/cement_delivery_edit_create",

            "get_aggrement_obj_for_cement_open": "https://api.mayag.ztsplc.com/api/cement/get_aggrement_obj_for_cement_open",
            "get_product_for_cement_open": "https://api.mayag.ztsplc.com/api/cement/get_product_for_cement_open",
            "get_open_cement_for_cement_open": "https://api.mayag.ztsplc.com/api/cement/get_open_cement_for_cement_open",
            "get_single_open_order_for_cement_update": "https://api.mayag.ztsplc.com/api/cement/get_single_open_order_for_cement_update",
            "draw_approval_for_cement_update": "https://api.mayag.ztsplc.com/api/cement/draw_approval_for_cement_update",
            "cement_open": "https://api.mayag.ztsplc.com/api/cement/cement_open",
            "cement_open_edit": "https://api.mayag.ztsplc.com/api/cement/cement_open_edit",
            "cement_open_add_attachment": "https://api.mayag.ztsplc.com/api/cement/cement_open_add_attachment",
            "get_image_for_remove_for_cement_open": "https://api.mayag.ztsplc.com/api/cement/get_image_for_remove_for_cement_open",
            "cement_open_remove_attachment": "https://api.mayag.ztsplc.com/api/cement/cement_open_remove_attachment",
            "get_single_open_order_for_cement_open": "https://api.mayag.ztsplc.com/api/cement/get_single_open_order_for_cement_open",
            "get_single_open_order_for_cement_so": "https://api.mayag.ztsplc.com/api/cement/get_single_open_order_for_cement_so",
            "cement_so": "https://api.mayag.ztsplc.com/api/cement/cement_so",
            "get_single_open_order_for_eidt_for_cement_open": "https://api.mayag.ztsplc.com/api/cement/get_single_open_order_for_eidt_for_cement_open",
            "cement_open_delete": "https://api.mayag.ztsplc.com/api/cement/cement_open_delete",
            "get_open_cement_for_cement_app": "https://api.mayag.ztsplc.com/api/cement/get_open_cement_for_cement_app",
            "get_single_open_order_for_cement_app": "https://api.mayag.ztsplc.com/api/cement/get_single_open_order_for_cement_app",
            "cement_app": "https://api.mayag.ztsplc.com/api/cement/cement_app",
            "get_order_for_cement_update": "https://api.mayag.ztsplc.com/api/cement/get_order_for_cement_update",
            "cement_update": "https://api.mayag.ztsplc.com/api/cement/cement_update",
            "get_orders_for_cement_update_app": "https://api.mayag.ztsplc.com/api/cement/get_orders_for_cement_update_app",
            "get_single_orders_for_cement_update_app": "https://api.mayag.ztsplc.com/api/cement/get_single_orders_for_cement_update_app",
            "cement_update_app_rej": "https://api.mayag.ztsplc.com/api/cement/cement_update_app_rej",
            "cement_update_app_app": "https://api.mayag.ztsplc.com/api/cement/cement_update_app_app",
            "get_data_for_cement_current_status": "https://api.mayag.ztsplc.com/api/cement/get_data_for_cement_current_status",

            "create_os_company": "https://api.mayag.ztsplc.com/api/outsource/create_os_company",
            "get_os_companys": "https://api.mayag.ztsplc.com/api/outsource/get_os_companys",
            "edit_os_company": "https://api.mayag.ztsplc.com/api/outsource/edit_os_company",
            "create_os_vehicle": "https://api.mayag.ztsplc.com/api/outsource/create_os_vehicle",
            "get_os_vehicles": "https://api.mayag.ztsplc.com/api/outsource/get_os_vehicles",
            "edit_os_vehicle": "https://api.mayag.ztsplc.com/api/outsource/edit_os_vehicle",
            "create_os_driver": "https://api.mayag.ztsplc.com/api/outsource/create_os_driver",
            "get_os_drivers": "https://api.mayag.ztsplc.com/api/outsource/get_os_drivers",
            "edit_os_driver": "https://api.mayag.ztsplc.com/api/outsource/edit_os_driver",
            "create_os_trailer": "https://api.mayag.ztsplc.com/api/outsource/create_os_trailer",
            "get_os_trailer": "https://api.mayag.ztsplc.com/api/outsource/get_os_trailer",
            "edit_os_trailer": "https://api.mayag.ztsplc.com/api/outsource/edit_os_trailer",
            "get_vehicle_for_assign_sub_contractor_req": "https://api.mayag.ztsplc.com/api/outsource/get_vehicle_for_assign_sub_contractor_req",
            "get_asset_for_assign_sub_contractor_req": "https://api.mayag.ztsplc.com/api/outsource/get_asset_for_assign_sub_contractor_req",
            "assign_sub_contractor_req": "https://api.mayag.ztsplc.com/api/outsource/assign_sub_contractor_req",
            "edit_for_assign_sub_contractor_req": "https://api.mayag.ztsplc.com/api/outsource/edit_for_assign_sub_contractor_req",
            "get_vehicle_for_assign_sub_contractor_app": "https://api.mayag.ztsplc.com/api/outsource/get_vehicle_for_assign_sub_contractor_app",
            "assign_sub_contractor_app_app": "https://api.mayag.ztsplc.com/api/outsource/assign_sub_contractor_app_app",
            "assign_sub_contractor_app_rej": "https://api.mayag.ztsplc.com/api/outsource/assign_sub_contractor_app_rej",
            "fright_document_sub_contractor": "https://api.mayag.ztsplc.com/api/outsource/fright_document_sub_contractor",
            "sub_contractors": "https://api.mayag.ztsplc.com/api/outsource/sub_contractors",
            "create_supplier_company": "https://api.mayag.ztsplc.com/api/outsource/create_supplier_company",
            "get_supplier_company": "https://api.mayag.ztsplc.com/api/outsource/get_supplier_company",
            "edit_supplier_company": "https://api.mayag.ztsplc.com/api/outsource/edit_supplier_company",
            "get_vehicle_for_assign_supplier_company_req": "https://api.mayag.ztsplc.com/api/outsource/get_vehicle_for_assign_supplier_company_req",
            "assign_supplier_company_req": "https://api.mayag.ztsplc.com/api/outsource/assign_supplier_company_req",
            "edit_for_assign_supplier_company_req": "https://api.mayag.ztsplc.com/api/outsource/edit_for_assign_supplier_company_req",
            "get_vehicle_for_assign_supplier_company_app": "https://api.mayag.ztsplc.com/api/outsource/get_vehicle_for_assign_supplier_company_app",
            "assign_supplier_company_app_app": "https://api.mayag.ztsplc.com/api/outsource/assign_supplier_company_app_app",
            "assign_supplier_company_app_rej": "https://api.mayag.ztsplc.com/api/outsource/assign_supplier_company_app_rej",
            "fright_document_suppliers": "https://api.mayag.ztsplc.com/api/outsource/fright_document_suppliers",
            "suppliers": "https://api.mayag.ztsplc.com/api/outsource/suppliers",


            "open_order": "https://api.mayag.ztsplc.com/api/orderv2/open_order",
            "open_order_edit": "https://api.mayag.ztsplc.com/api/orderv2/open_order_edit",
            "order_open_add_attachment": "https://api.mayag.ztsplc.com/api/orderv2/order_open_add_attachment",
            "get_image_for_remove_for_order_open": "https://api.mayag.ztsplc.com/api/orderv2/get_image_for_remove_for_order_open",
            "order_open_remove_attachment": "https://api.mayag.ztsplc.com/api/orderv2/order_open_remove_attachment",
            "get_open_order_for_order_accept": "https://api.mayag.ztsplc.com/api/orderv2/get_open_order_for_order_accept",
            "get_single_open_order_for_order_accept": "https://api.mayag.ztsplc.com/api/orderv2/get_single_open_order_for_order_accept",
            "get_image_for_order": "https://api.mayag.ztsplc.com/api/orderv2/get_image_for_order",
            "order_accept": "https://api.mayag.ztsplc.com/api/orderv2/order_accept",
            "get_open_order_for_order_accept_fin": "https://api.mayag.ztsplc.com/api/orderv2/get_open_order_for_order_accept_fin",
            "get_single_open_order_for_order_accept_fin": "https://api.mayag.ztsplc.com/api/orderv2/get_single_open_order_for_order_accept_fin",
            "order_accept_fin_with_invoice": "https://api.mayag.ztsplc.com/api/orderv2/order_accept_fin_with_invoice",
            "order_accept_fin_with_out_invoice": "https://api.mayag.ztsplc.com/api/orderv2/order_accept_fin_with_out_invoice",
            "get_open_order_for_open_order": "https://api.mayag.ztsplc.com/api/orderv2/get_open_order_for_open_order",
            "get_single_open_order_for_open_order": "https://api.mayag.ztsplc.com/api/orderv2/get_single_open_order_for_open_order",
            "get_single_open_order_for_open_update": "https://api.mayag.ztsplc.com/api/orderv2/get_single_open_order_for_open_update",
            "draw_approval_order_update": "https://api.mayag.ztsplc.com/api/orderv2/draw_approval_order_update",
            "open_order_delete": "https://api.mayag.ztsplc.com/api/orderv2/open_order_delete",
            "get_single_open_order_for_edit_for_open_order": "https://api.mayag.ztsplc.com/api/orderv2/get_single_open_order_for_edit_for_open_order",
            "get_order_for_order_status": "https://api.mayag.ztsplc.com/api/orderv2/get_order_for_order_status",
            "get_order_for_cement_status": "https://api.mayag.ztsplc.com/api/orderv2/get_order_for_cement_status",
            "order_detail": "https://api.mayag.ztsplc.com/api/orderv2/order_detail",
            "get_order_for_order_update": "https://api.mayag.ztsplc.com/api/orderv2/get_order_for_order_update",
            "order_update": "https://api.mayag.ztsplc.com/api/orderv2/order_update",
            "get_orders_for_order_op_update_app": "https://api.mayag.ztsplc.com/api/orderv2/get_orders_for_order_op_update_app",
            "get_single_orders_for_order_op_update_app": "https://api.mayag.ztsplc.com/api/orderv2/get_single_orders_for_order_op_update_app",
            "order_op_update_app_app": "https://api.mayag.ztsplc.com/api/orderv2/order_op_update_app_app",
            "order_op_update_app_rej": "https://api.mayag.ztsplc.com/api/orderv2/order_op_update_app_rej",
            "get_orders_for_order_fin_update_app": "https://api.mayag.ztsplc.com/api/orderv2/get_orders_for_order_fin_update_app",
            "get_single_orders_for_order_fin_update_app": "https://api.mayag.ztsplc.com/api/orderv2/get_single_orders_for_order_fin_update_app",
            "order_fin_update_app_app": "https://api.mayag.ztsplc.com/api/orderv2/order_fin_update_app_app",
            "order_fin_update_app_rej": "https://api.mayag.ztsplc.com/api/orderv2/order_fin_update_app_rej",
            "get_data_for_order_current_status": "https://api.mayag.ztsplc.com/api/orderv2/get_data_for_order_current_status",
            "get_aggrement_obj_for_open_order": "https://api.mayag.ztsplc.com/api/orderv2/get_aggrement_obj_for_open_order",


            "report_generator": "https://api.mayag.ztsplc.com/api/reportgenerator/report_generator",
            "report_op_master_trip": "https://api.mayag.ztsplc.com/api/reportgenerator/report_op_master_trip",
            "report_truck_allocation": "https://api.mayag.ztsplc.com/api/reportgenerator/report_truck_allocation",
            "report_fin_detail_payment": "https://api.mayag.ztsplc.com/api/reportgenerator/report_fin_detail_payment",
            "report_fin_detail_bank": "https://api.mayag.ztsplc.com/api/reportgenerator/report_fin_detail_bank",
            "report_op_master_tracking": "https://api.mayag.ztsplc.com/api/reportgenerator/report_op_master_tracking",
            "report_op_tat": "https://api.mayag.ztsplc.com/api/reportgenerator/report_op_tat",
            "report_fin_gp_by_letter": "https://api.mayag.ztsplc.com/api/reportgenerator/report_fin_gp_by_letter",
            "report_fin_gp_by_fo_date": "https://api.mayag.ztsplc.com/api/reportgenerator/report_fin_gp_by_fo_date",
            "report_master_sale": "https://api.mayag.ztsplc.com/api/reportgenerator/report_master_sale",
            "report_master_transport": "https://api.mayag.ztsplc.com/api/reportgenerator/report_master_transport",
            "report_cement_allocation": "https://api.mayag.ztsplc.com/api/reportgenerator/report_cement_allocation",
            "report_master_cement_allocation": "https://api.mayag.ztsplc.com/api/reportgenerator/report_master_cement_allocation",

            "get_fright_for_op_refund_req": "https://api.mayag.ztsplc.com/api/operefund/get_fright_for_op_refund_req",
            "op_refund_req": "https://api.mayag.ztsplc.com/api/operefund/op_refund_req",
            "get_vehicle_for_op_refund_app": "https://api.mayag.ztsplc.com/api/operefund/get_vehicle_for_op_refund_app",
            "get_fright_for_op_refund_app": "https://api.mayag.ztsplc.com/api/operefund/get_fright_for_op_refund_app",
            "op_refund_app": "https://api.mayag.ztsplc.com/api/operefund/op_refund_app",
            "op_refund_rej": "https://api.mayag.ztsplc.com/api/operefund/op_refund_rej",
            "get_fright_for_op_trip_cancel_req": "https://api.mayag.ztsplc.com/api/operefund/get_fright_for_op_trip_cancel_req",
            "op_trip_cancel_req": "https://api.mayag.ztsplc.com/api/operefund/op_trip_cancel_req",
            "get_vehicle_for_op_trip_cancel_app": "https://api.mayag.ztsplc.com/api/operefund/get_vehicle_for_op_trip_cancel_app",
            "get_fright_for_op_trip_cancel_app": "https://api.mayag.ztsplc.com/api/operefund/get_fright_for_op_trip_cancel_app",
            "op_trip_cancel_rej": "https://api.mayag.ztsplc.com/api/operefund/op_trip_cancel_rej",
            "op_trip_cancel_app": "https://api.mayag.ztsplc.com/api/operefund/op_trip_cancel_app",
            "get_fright_for_op_trip_extend_req": "https://api.mayag.ztsplc.com/api/operefund/get_fright_for_op_trip_extend_req",
            "op_trip_extend_req": "https://api.mayag.ztsplc.com/api/operefund/op_trip_extend_req",
            "get_vehicle_for_op_trip_extend_app": "https://api.mayag.ztsplc.com/api/operefund/get_vehicle_for_op_trip_extend_app",
            "get_fright_for_op_trip_extend_app": "https://api.mayag.ztsplc.com/api/operefund/get_fright_for_op_trip_extend_app",
            "op_trip_extend_rej": "https://api.mayag.ztsplc.com/api/operefund/op_trip_extend_rej",
            "op_trip_extend_app": "https://api.mayag.ztsplc.com/api/operefund/op_trip_extend_app",
            "get_fright_for_op_reconcile": "https://api.mayag.ztsplc.com/api/operefund/get_fright_for_op_reconcile",
            "op_reconcile": "https://api.mayag.ztsplc.com/api/operefund/op_reconcile",

            "get_obj_for_opex_report": "https://api.mayag.ztsplc.com/api/opex/get_obj_for_opex_report",
            "get_single_vehicle_for_opex_reconcile": "https://api.mayag.ztsplc.com/api/opex/get_single_vehicle_for_opex_reconcile",
            "opex_report": "https://api.mayag.ztsplc.com/api/opex/opex_report",
            "get_obj_for_opex_balance_sheet": "https://api.mayag.ztsplc.com/api/opex/get_obj_for_opex_balance_sheet",
            "opex_balance_sheet_costs": "https://api.mayag.ztsplc.com/api/opex/opex_balance_sheet_costs",
            "opex_balance_sheet_fuels": "https://api.mayag.ztsplc.com/api/opex/opex_balance_sheet_fuels",
            "get_obj_for_opex_cost": "https://api.mayag.ztsplc.com/api/opex/get_obj_for_opex_cost",
            "next_1_for_opex_cost": "https://api.mayag.ztsplc.com/api/opex/next_1_for_opex_cost",
            "next_2_for_opex_cost": "https://api.mayag.ztsplc.com/api/opex/next_2_for_opex_cost",
            "get_obj_for_opex_fuel": "https://api.mayag.ztsplc.com/api/opex/get_obj_for_opex_fuel",
            "next_1_for_opex_fuel": "https://api.mayag.ztsplc.com/api/opex/next_1_for_opex_fuel",
            "next_2_for_opex_fuel": "https://api.mayag.ztsplc.com/api/opex/next_2_for_opex_fuel",
            "get_vehicle_for_opex_cost_req": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_cost_req",
            "get_sum_opex_for_opex_cost_req": "https://api.mayag.ztsplc.com/api/opex/get_sum_opex_for_opex_cost_req",
            "opex_cost_req": "https://api.mayag.ztsplc.com/api/opex/opex_cost_req",
            "get_vehicle_for_opex_cost_app": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_cost_app",
            "get_sum_opex_for_opex_cost_app": "https://api.mayag.ztsplc.com/api/opex/get_sum_opex_for_opex_cost_app",
            "get_driver_single_for_opex_cost_app": "https://api.mayag.ztsplc.com/api/opex/get_driver_single_for_opex_cost_app",
            "opex_cost_app": "https://api.mayag.ztsplc.com/api/opex/opex_cost_app",
            "get_vehicle_for_opex_cost_payment": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_cost_payment",
            "get_vehicle_for_opex_cost_payment_all": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_cost_payment_all",
            "get_vehicle_for_opex_cost_payment_all_2": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_cost_payment_all_2",
            "get_vehicle_for_opex_cost_payment_all_3": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_cost_payment_all_3",
            "opex_cost_payment": "https://api.mayag.ztsplc.com/api/opex/opex_cost_payment",
            "get_vehicle_for_opex_fuel_req": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_fuel_req",
            "get_sum_opex_for_opex_fuel_req": "https://api.mayag.ztsplc.com/api/opex/get_sum_opex_for_opex_fuel_req",
            "opex_fuel_req": "https://api.mayag.ztsplc.com/api/opex/opex_fuel_req",
            "get_vehicle_for_opex_fuel_app": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_fuel_app",
            "get_sum_opex_for_opex_fuel_app": "https://api.mayag.ztsplc.com/api/opex/get_sum_opex_for_opex_fuel_app",
            "get_vehicle_single_for_opex_fuel_app": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_single_for_opex_fuel_app",
            "opex_fuel_app": "https://api.mayag.ztsplc.com/api/opex/opex_fuel_app",
            "get_vehicle_for_opex_fuel_giver": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_fuel_giver",
            "opex_fuel_giver": "https://api.mayag.ztsplc.com/api/opex/opex_fuel_giver",
            "get_fright_for_opex_cost_refund": "https://api.mayag.ztsplc.com/api/opex/get_fright_for_opex_cost_refund",
            "get_vehicle_for_opex_cost_refund": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_cost_refund",
            "opex_cost_refund": "https://api.mayag.ztsplc.com/api/opex/opex_cost_refund",
            "get_vehicle_single_for_opex_cost_refund": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_single_for_opex_cost_refund",
            "edit_opex_cost_refund": "https://api.mayag.ztsplc.com/api/opex/edit_opex_cost_refund",
            "get_vehicle_for_opex_fuel_refund": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_fuel_refund",
            "get_vehicle_single_for_opex_fuel_refund": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_single_for_opex_fuel_refund",
            "opex_fuel_refund": "https://api.mayag.ztsplc.com/api/opex/opex_fuel_refund",
            "edit_opex_fuel_refund": "https://api.mayag.ztsplc.com/api/opex/edit_opex_fuel_refund",
            "get_vehicle_for_opex_summuary": "https://api.mayag.ztsplc.com/api/opex/get_vehicle_for_opex_summuary",
            "opex_summuary": "https://api.mayag.ztsplc.com/api/opex/opex_summuary",
            "opex_detail": "https://api.mayag.ztsplc.com/api/opex/opex_detail",
            "opex_cost_payment_validator": "https://api.mayag.ztsplc.com/api/opex/opex_cost_payment_validator",
            "get_opex_cost_bank_tt": "https://api.mayag.ztsplc.com/api/opex/get_opex_cost_bank_tt",
            "opex_cost_bank_tt": "https://api.mayag.ztsplc.com/api/opex/opex_cost_bank_tt",


            "createuser": "https://api.mayag.ztsplc.com/user/createuser",
            "updateuser": "https://api.mayag.ztsplc.com/user/updateuser",
            "changepwduser": "https://api.mayag.ztsplc.com/user/changepwduser",
            "getusers": "https://api.mayag.ztsplc.com/user/getusers",
            "getuser": "https://api.mayag.ztsplc.com/user/getuser",
            "getuseraccess": "https://api.mayag.ztsplc.com/user/getuseraccess",
            "updateuseraccess": "https://api.mayag.ztsplc.com/user/updateuseraccess",
            "login": "https://api.mayag.ztsplc.com/user/login",
            "checksession": "https://api.mayag.ztsplc.com/user/checksession",
            "logout": "https://api.mayag.ztsplc.com/user/logout",
            "getuasession": "https://api.mayag.ztsplc.com/user/getuasession",
            "changepwd_by_user": "https://api.mayag.ztsplc.com/user/changepwd_by_user",
            "get_obj_for_user_template": "https://api.mayag.ztsplc.com/user/get_obj_for_user_template",
            "user_template": "https://api.mayag.ztsplc.com/user/user_template",
            "get_obj_for_user_template_edit": "https://api.mayag.ztsplc.com/user/get_obj_for_user_template_edit",
            "user_template_edit": "https://api.mayag.ztsplc.com/user/user_template_edit",

            "createobjuser": "https://api.mayag.ztsplc.com/objuser/createobjuser",
            "updateobjuser": "https://api.mayag.ztsplc.com/objuser/updateobjuser",
            "getobjusers": "https://api.mayag.ztsplc.com/objuser/getobjusers",
            "getobjuser": "https://api.mayag.ztsplc.com/objuser/getobjuser",

            "create_client_type": "https://api.mayag.ztsplc.com/api/client/create_client_type",
            "get_client_types": "https://api.mayag.ztsplc.com/api/client/get_client_types",
            "edit_client_type": "https://api.mayag.ztsplc.com/api/client/edit_client_type",
            "create_client": "https://api.mayag.ztsplc.com/api/client/create_client",
            "get_client_type_for_client_mgt": "https://api.mayag.ztsplc.com/api/client/get_client_type_for_client_mgt",
            "get_clients": "https://api.mayag.ztsplc.com/api/client/get_clients",
            "edit_client": "https://api.mayag.ztsplc.com/api/client/edit_client",


            "current_status": "https://api.mayag.ztsplc.com/api/mobile/current_status",
            "mobile_current_status": "https://api.mayag.ztsplc.com/api/mobile/mobile_current_status",
            "mobile_customer_report": "https://api.mayag.ztsplc.com/api/mobile/mobile_customer_report",


            "create_coc": "https://api.mayag.ztsplc.com/api/misc/create_coc",
            "get_cocs": "https://api.mayag.ztsplc.com/api/misc/get_cocs",
            "edit_coc": "https://api.mayag.ztsplc.com/api/misc/edit_coc",
            "create_cargo": "https://api.mayag.ztsplc.com/api/misc/create_cargo",
            "get_cargos": "https://api.mayag.ztsplc.com/api/misc/get_cargos",
            "edit_cargo": "https://api.mayag.ztsplc.com/api/misc/edit_cargo",

            "get_obj_for_order": "https://api.mayag.ztsplc.com/api/order/get_obj_for_order",
            "create_order": "https://api.mayag.ztsplc.com/api/order/create_order",
            "get_orders": "https://api.mayag.ztsplc.com/api/order/get_orders",
            "get_order": "https://api.mayag.ztsplc.com/api/order/get_order",
            "edit_order": "https://api.mayag.ztsplc.com/api/order/edit_order",
            "check_route": "https://api.mayag.ztsplc.com/api/order/check_route",
            "get_driver_for_document_mgt": "https://api.mayag.ztsplc.com/api/order/get_driver_for_document_mgt",
            "get_document_mgt": "https://api.mayag.ztsplc.com/api/order/get_document_mgt",
            "document_mgt": "https://api.mayag.ztsplc.com/api/order/document_mgt",


            "create_vehicle": "https://api.mayag.ztsplc.com/api/asset/create_vehicle",
            "get_vehicles": "https://api.mayag.ztsplc.com/api/asset/get_vehicles",
            "edit_vehicle": "https://api.mayag.ztsplc.com/api/asset/edit_vehicle",
            "create_driver": "https://api.mayag.ztsplc.com/api/asset/create_driver",
            "get_drivers": "https://api.mayag.ztsplc.com/api/asset/get_drivers",
            "edit_driver": "https://api.mayag.ztsplc.com/api/asset/edit_driver",
            "create_trailer": "https://api.mayag.ztsplc.com/api/asset/create_trailer",
            "get_trailers": "https://api.mayag.ztsplc.com/api/asset/get_trailers",
            "edit_trailer": "https://api.mayag.ztsplc.com/api/asset/edit_trailer",
            "create_location": "https://api.mayag.ztsplc.com/api/asset/create_location",
            "get_locations": "https://api.mayag.ztsplc.com/api/asset/get_locations",
            "edit_location": "https://api.mayag.ztsplc.com/api/asset/edit_location",
            "get_road_route_obj": "https://api.mayag.ztsplc.com/api/asset/get_road_route_obj",
            "get_road_routes": "https://api.mayag.ztsplc.com/api/asset/get_road_routes",
            "create_road_route": "https://api.mayag.ztsplc.com/api/asset/create_road_route",
            "edit_road_route": "https://api.mayag.ztsplc.com/api/asset/edit_road_route",
            "create_other_cost": "https://api.mayag.ztsplc.com/api/asset/create_other_cost",
            "get_other_costs": "https://api.mayag.ztsplc.com/api/asset/get_other_costs",
            "edit_other_cost": "https://api.mayag.ztsplc.com/api/asset/edit_other_cost",
            "create_status_type": "https://api.mayag.ztsplc.com/api/asset/create_status_type",
            "get_status_types": "https://api.mayag.ztsplc.com/api/asset/get_status_types",
            "edit_status_type": "https://api.mayag.ztsplc.com/api/asset/edit_status_type",
            "create_fuel_rate": "https://api.mayag.ztsplc.com/api/asset/create_fuel_rate",
            "get_fuel_rates": "https://api.mayag.ztsplc.com/api/asset/get_fuel_rates",
            "edit_fuel_rate": "https://api.mayag.ztsplc.com/api/asset/edit_fuel_rate",
            "get_obj_for_road_route_open": "https://api.mayag.ztsplc.com/api/asset/get_obj_for_road_route_open",
            "road_route_open": "https://api.mayag.ztsplc.com/api/asset/road_route_open",



            "get_assets_for_assign_own": "https://api.mayag.ztsplc.com/api/assign/get_assets_for_assign_own",
            "client_attach": "https://api.mayag.ztsplc.com/api/assign/client_attach",
            "vehicle_assign_own": "https://api.mayag.ztsplc.com/api/assign/vehicle_assign_own",
            "get_final_route": "https://api.mayag.ztsplc.com/api/assign/get_final_route",
            "get_create_obj": "https://api.mayag.ztsplc.com/api/assign/get_create_obj",

            "update_get_assets_for_assign_own": "https://api.mayag.ztsplc.com/api/assign/update_get_assets_for_assign_own",
            "update_get_asset": "https://api.mayag.ztsplc.com/api/assign/update_get_asset",
            "update_update_asset": "https://api.mayag.ztsplc.com/api/assign/update_update_asset",
            "update_get_volume": "https://api.mayag.ztsplc.com/api/assign/update_get_volume",
            "update_get_assign_single_obj": "https://api.mayag.ztsplc.com/api/assign/update_get_assign_single_obj",
            "update_update_volume": "https://api.mayag.ztsplc.com/api/assign/update_update_volume",
            "update_get_container": "https://api.mayag.ztsplc.com/api/assign/update_get_container",
            "update_update_container": "https://api.mayag.ztsplc.com/api/assign/update_update_container",
            "update_get_start_loc": "https://api.mayag.ztsplc.com/api/assign/update_get_start_loc",
            "update_update_start_loc": "https://api.mayag.ztsplc.com/api/assign/update_update_start_loc",
            "update_get_fin_loc": "https://api.mayag.ztsplc.com/api/assign/update_get_fin_loc",
            "update_update_fin_loc": "https://api.mayag.ztsplc.com/api/assign/update_update_fin_loc",
            "update_get_client": "https://api.mayag.ztsplc.com/api/assign/update_get_client",
            "update_client_attach": "https://api.mayag.ztsplc.com/api/assign/update_client_attach",
            "update_update_client": "https://api.mayag.ztsplc.com/api/assign/update_update_client",
            "get_vehicle_for_payment_req": "https://api.mayag.ztsplc.com/api/assign/get_vehicle_for_payment_req",
            "get_assign_own_for_payment_req": "https://api.mayag.ztsplc.com/api/assign/get_assign_own_for_payment_req",
            "get_assign_own_cost_for_payment_req": "https://api.mayag.ztsplc.com/api/assign/get_assign_own_cost_for_payment_req",
            "payment_req": "https://api.mayag.ztsplc.com/api/assign/payment_req",
            "remove_payment_req": "https://api.mayag.ztsplc.com/api/assign/remove_payment_req",
            "pouplate_fileds_for_payment_req": "https://api.mayag.ztsplc.com/api/assign/pouplate_fileds_for_payment_req",
            "get_vehicle_for_payment_app": "https://api.mayag.ztsplc.com/api/assign/get_vehicle_for_payment_app",
            "get_assign_own_for_payment_app": "https://api.mayag.ztsplc.com/api/assign/get_assign_own_for_payment_app",
            "get_assign_own_cost_for_payment_app": "https://api.mayag.ztsplc.com/api/assign/get_assign_own_cost_for_payment_app",
            "payment_app": "https://api.mayag.ztsplc.com/api/assign/payment_app",
            "get_vehicle_for_payment_pay": "https://api.mayag.ztsplc.com/api/assign/get_vehicle_for_payment_pay",
            "get_assign_own_for_payment_pay": "https://api.mayag.ztsplc.com/api/assign/get_assign_own_for_payment_pay",
            "payment_pay": "https://api.mayag.ztsplc.com/api/assign/payment_pay",
            "payment_app_for_cash": "https://api.mayag.ztsplc.com/api/assign/payment_app_for_cash",
            "cash_payment_req": "https://api.mayag.ztsplc.com/api/assign/cash_payment_req",
            "remove_cash_payment_req": "https://api.mayag.ztsplc.com/api/assign/remove_cash_payment_req",
            "get_vehicle_for_delete_own": "https://api.mayag.ztsplc.com/api/assign/get_vehicle_for_delete_own",
            "get_hisotry_single_for_delete_own": "https://api.mayag.ztsplc.com/api/assign/get_hisotry_single_for_delete_own",
            "delete_own": "https://api.mayag.ztsplc.com/api/assign/delete_own",
            "get_vehicle_for_update_allocate": "https://api.mayag.ztsplc.com/api/assign/get_vehicle_for_update_allocate",
            "get_assign_own_for_update_allocate": "https://api.mayag.ztsplc.com/api/assign/get_assign_own_for_update_allocate",
            "get_single_assign_own_for_update_allocate": "https://api.mayag.ztsplc.com/api/assign/get_single_assign_own_for_update_allocate",
            "client_attach_for_update_allocate": "https://api.mayag.ztsplc.com/api/assign/client_attach_for_update_allocate",
            "vehicle_assign_own_update": "https://api.mayag.ztsplc.com/api/assign/vehicle_assign_own_update",
            "get_fright_for_fright_document": "https://api.mayag.ztsplc.com/api/assign/get_fright_for_fright_document",
            "get_fright_for_finance_document": "https://api.mayag.ztsplc.com/api/assign/get_fright_for_finance_document",


            "get_fright_for_follow_up_manual": "https://api.mayag.ztsplc.com/api/controal/get_fright_for_follow_up_manual",
            "get_vehicle_follow_up": "https://api.mayag.ztsplc.com/api/controal/get_vehicle_follow_up",
            "get_mov_for_follow_up": "https://api.mayag.ztsplc.com/api/controal/get_mov_for_follow_up",
            "assign_date": "https://api.mayag.ztsplc.com/api/controal/assign_date",
            "get_single_date_follow_up": "https://api.mayag.ztsplc.com/api/controal/get_single_date_follow_up",
            "get_follow_up_hisotry": "https://api.mayag.ztsplc.com/api/controal/get_follow_up_hisotry",
            "get_vehicle_follow_up_2": "https://api.mayag.ztsplc.com/api/controal/get_vehicle_follow_up_2",
            "get_mov_for_follow_up_2": "https://api.mayag.ztsplc.com/api/controal/get_mov_for_follow_up_2",
            "get_mov_for_follow_up_2_1": "https://api.mayag.ztsplc.com/api/controal/get_mov_for_follow_up_2_1",
            "create_vehicle_status": "https://api.mayag.ztsplc.com/api/controal/create_vehicle_status",
            "get_vehicle_status": "https://api.mayag.ztsplc.com/api/controal/get_vehicle_status",
            "remove_vehicle_status": "https://api.mayag.ztsplc.com/api/controal/remove_vehicle_status",
            "get_status_type_for_vehicle_status": "https://api.mayag.ztsplc.com/api/controal/get_status_type_for_vehicle_status",
            "get_follow_up_hisotrys": "https://api.mayag.ztsplc.com/api/controal/get_follow_up_hisotrys",
            "get_follow_up_hisotry_single": "https://api.mayag.ztsplc.com/api/controal/get_follow_up_hisotry_single",
            "get_loc_for_follow_up_eta": "https://api.mayag.ztsplc.com/api/controal/get_loc_for_follow_up_eta",
            "get_vehicles_follow_up_eta": "https://api.mayag.ztsplc.com/api/controal/get_vehicles_follow_up_eta",
            "get_km_follow_up_eta": "https://api.mayag.ztsplc.com/api/controal/get_km_follow_up_eta",
            "get_follow_up_customer": "https://api.mayag.ztsplc.com/api/controal/get_follow_up_customer",
            "get_follow_up_report": "https://api.mayag.ztsplc.com/api/controal/get_follow_up_report",
            "get_vehicle_for_follow_up_stoppage": "https://api.mayag.ztsplc.com/api/controal/get_vehicle_for_follow_up_stoppage",
            "get_single_mov_for_follow_up_stoppage": "https://api.mayag.ztsplc.com/api/controal/get_single_mov_for_follow_up_stoppage",
            "fileter_follow_up_stoppage": "https://api.mayag.ztsplc.com/api/controal/fileter_follow_up_stoppage",
            "get_vehicle_for_follow_up_loading_unloading": "https://api.mayag.ztsplc.com/api/controal2/get_vehicle_for_follow_up_loading_unloading",
            "get_mov_for_follow_up_loading_unloading": "https://api.mayag.ztsplc.com/api/controal2/get_mov_for_follow_up_loading_unloading",
            "get_vehicle_for_follow_up_transit": "https://api.mayag.ztsplc.com/api/controal2/get_vehicle_for_follow_up_transit",
            "get_mov_for_follow_up_transit": "https://api.mayag.ztsplc.com/api/controal2/get_mov_for_follow_up_transit",
            "get_vehicle_for_follow_up_wfo": "https://api.mayag.ztsplc.com/api/controal2/get_vehicle_for_follow_up_wfo",
            "get_mov_for_follow_up_wfo": "https://api.mayag.ztsplc.com/api/controal2/get_mov_for_follow_up_wfo",
            "get_vehicles_for_follow_up_manual": "https://api.mayag.ztsplc.com/api/controal2/get_vehicles_for_follow_up_manual",
            "get_history_for_follow_up_manual": "https://api.mayag.ztsplc.com/api/controal2/get_history_for_follow_up_manual",
            "get_mov_1_follow_up_manual": "https://api.mayag.ztsplc.com/api/controal2/get_mov_1_follow_up_manual",
            "get_mov_2_follow_up_manual": "https://api.mayag.ztsplc.com/api/controal2/get_mov_2_follow_up_manual",
            "get_vehicles_for_follow_up_stoppage": "https://api.mayag.ztsplc.com/api/controal2/get_vehicles_for_follow_up_stoppage",
            "add_remark_follow_up_stoppage": "https://api.mayag.ztsplc.com/api/controal2/add_remark_follow_up_stoppage",
            "get_reports_for_follow_up_stoppage": "https://api.mayag.ztsplc.com/api/controal2/get_reports_for_follow_up_stoppage",
            "follow_up_distance_calc": "https://api.mayag.ztsplc.com/api/controal2/follow_up_distance_calc",
            "get_vehicle_ids_follow_up_wizard": "https://api.mayag.ztsplc.com/api/controal2/get_vehicle_ids_follow_up_wizard",
            "get_vehicle_follow_up_wizard": "https://api.mayag.ztsplc.com/api/controal2/get_vehicle_follow_up_wizard",
            "execute_follow_up_wizard": "https://api.mayag.ztsplc.com/api/controal2/execute_follow_up_wizard",
            "get_result_follow_up_wizard": "https://api.nt.ztsplc.com/api/controal2/get_result_follow_up_wizard",
            "pub_dashboard_1": "https://api.mayag.ztsplc.com/api/publicdashboard/pub_dashboard_1",
            "pub_dashboard_2": "https://api.mayag.ztsplc.com/api/publicdashboard/pub_dashboard_2",
            "pub_dashboard_3_loc": "https://api.mayag.ztsplc.com/api/publicdashboard/pub_dashboard_3_loc",
            "pub_dashboard_3": "https://api.mayag.ztsplc.com/api/publicdashboard/pub_dashboard_3",
            "r_load_factor": "https://api.mayag.ztsplc.com/api/report/r_load_factor",
            "r_maintance": "https://api.mayag.ztsplc.com/api/report/r_maintance",
            "r_ava": "https://api.mayag.ztsplc.com/api/report/r_ava",
            "r_tat": "https://api.mayag.ztsplc.com/api/report/r_tat",
            "r_detail_tat": "https://api.mayag.ztsplc.com/api/report/r_detail_tat",
            "get_vehicle_for_r_fright_order": "https://api.mayag.ztsplc.com/api/report/get_vehicle_for_r_fright_order",
            "r_fright_order_by_fright_no": "https://api.mayag.ztsplc.com/api/report/r_fright_order_by_fright_no",
            "r_fright_order_by_plate_no": "https://api.mayag.ztsplc.com/api/report/r_fright_order_by_plate_no",
            "get_vehicle_for_r_movement": "https://api.mayag.ztsplc.com/api/report/get_vehicle_for_r_movement",
            "r_movement": "https://api.mayag.ztsplc.com/api/report/r_movement",
            "get_vehicle_obj_for_r_movement": "https://api.mayag.ztsplc.com/api/report/get_vehicle_obj_for_r_movement",
            "r_tac": "https://api.mayag.ztsplc.com/api/report/r_tac",
            "r_tac_current": "https://api.mayag.ztsplc.com/api/report/r_tac_current",
            "r_time_to_load": "https://api.mayag.ztsplc.com/api/report/r_time_to_load",
            "r_time_to_load_open": "https://api.mayag.ztsplc.com/api/report/r_time_to_load_open",

            "finance_fo_by_date": "https://api.mayag.ztsplc.com/api/finance/finance_fo_by_date",
            "finance_fo_by_plate_no": "https://api.mayag.ztsplc.com/api/finance/finance_fo_by_plate_no",
            "get_vehicle_for_finance_fo_by_plate_no": "https://api.mayag.ztsplc.com/api/finance/get_vehicle_for_finance_fo_by_plate_no",
            "finance_canceled_fo": "https://api.mayag.ztsplc.com/api/finance/finance_canceled_fo",
            "finance_doc_rec": "https://api.mayag.ztsplc.com/api/finance/finance_doc_rec",
            "finance_not_doc_rec": "https://api.mayag.ztsplc.com/api/finance/finance_not_doc_rec",

            "get_fright_for_void_fo": "https://api.mayag.ztsplc.com/api/assign2/get_fright_for_void_fo",
            "void_fo": "https://api.mayag.ztsplc.com/api/assign2/void_fo",
            "get_obj_for_fright_extender": "https://api.mayag.ztsplc.com/api/assign2/get_obj_for_fright_extender",
            "vehicle_assign_own_with_additional_fo": "https://api.mayag.ztsplc.com/api/assign2/vehicle_assign_own_with_additional_fo",
            "get_obj_for_cancel_allocate": "https://api.mayag.ztsplc.com/api/assign2/get_obj_for_cancel_allocate",
            "cancel_allocate": "https://api.mayag.ztsplc.com/api/assign2/cancel_allocate",
            "delete_cancel_allocate": "https://api.mayag.ztsplc.com/api/assign2/delete_cancel_allocate",
            "veh_ava": "https://api.mayag.ztsplc.com/api/assign2/veh_ava",
            "veh_ava_get_loc": "https://api.mayag.ztsplc.com/api/assign2/veh_ava_get_loc",

            "get_obj_for_g_in_req": "https://api.mayag.ztsplc.com/api/maintenance/get_obj_for_g_in_req",
            "g_in_req": "https://api.mayag.ztsplc.com/api/maintenance/g_in_req",
            "gets_g_in_req": "https://api.mayag.ztsplc.com/api/maintenance/gets_g_in_req",
            "update_g_in_req": "https://api.mayag.ztsplc.com/api/maintenance/update_g_in_req",
            "remove_g_in_req": "https://api.mayag.ztsplc.com/api/maintenance/remove_g_in_req",
            "gets_g_in_app": "https://api.mayag.ztsplc.com/api/maintenance/gets_g_in_app",
            "g_in_app": "https://api.mayag.ztsplc.com/api/maintenance/g_in_app",
            "gets_g_out_req": "https://api.mayag.ztsplc .com/api/maintenance/gets_g_out_req",
            "g_out_req": "https://api.mayag.ztsplc.com/api/maintenance/g_out_req",
            "reset_g_out_req": "https://api.mayag.ztsplc.com/api/maintenance/reset_g_out_req",
            "gets_g_out_app": "https://api.mayag.ztsplc.com/api/maintenance/gets_g_out_app",
            "g_out_app": "https://api.mayag.ztsplc.com/api/maintenance/g_out_app",
            "gets_today_g_status": "https://api.mayag.ztsplc.com/api/maintenance/gets_today_g_status",
            "gets_current_maintance_g_status": "https://api.mayag.ztsplc.com/api/maintenance/gets_current_maintance_g_status",

            "redirect": "https://mayag.ztsplc.com",
            "redirecttoassign_vehicle": "https://mayag.ztsplc.com/assign_vehicle",
            "redirecttologin": "https://mayag.ztsplc.com/login",
            "redirecttoassign_history": "https://mayag.ztsplc.com/assign_history",
            "redirecttofollowuphistory": "https://mayag.ztsplc.com/follow_up_hisotry",
            "redirecttoupdate_allocate": "https://mayag.ztsplc.com/redirecttoupdate_allocate",
            "redirecttomovement": "https://mayag.ztsplc.com/r_movement",
            "redirecttodocument_mgt": "https://mayag.ztsplc.com/document_mgt",
            "redirecttofright_document": "https://mayag.ztsplc.com/fright_document",
            "redirecttofinance_document": "https://mayag.ztsplc.com/finance_document",
            "redirecttoopex_fuel_giver": "https://mayag.ztsplc.com/opex_fuel_giver",
            "redirecttoreport_generator": "https://mayag.ztsplc.com/report_generator",
            "redirecttoorder_detail": "https://mayag.ztsplc.com/order_detail",
            "redirecttostatus_purchase_detail": "https://mayag.ztsplc.com/status_purchase_detail",
            "redirecttostatus_delivery_detail": "https://mayag.ztsplc.com/status_delivery_detail",
            "redirecttostatus_cement_detail": "https://mayag.ztsplc.com/status_cement_detail",
            "redirecttoassign_own": "https://mayag.ztsplc.com/assign_own",
            "redirecttoassign_sub_contractor_req": "https://mayag.ztsplc.com/assign_sub_contractor_req",
            "redirecttostatus_cement": "https://mayag.ztsplc.com/status_cement",
            "redirecttostatus_delivery": "https://mayag.ztsplc.com/status_delivery",
            "redirecttopublic_dashboard": "https://mayag.ztsplc.com/d_status?token=nt_admin_token_1234_4321",
            "redirecttod_operation": "https://mayag.ztsplc.com/d_operation",
            "redirecttod_operation_2": "https://mayag.ztsplc.com/d_operation_2",
            "redirecttod_marketing": "https://mayag.ztsplc.com/d_marketing",
            "redirecttofollow_up_movement": "https://mayag.ztsplc.com/follow_up_movement",
            "redirecttomobile_home": "https://mayag.ztsplc.com/mobile_home",
            "redirecttomobile_current_status": "https://mayag.ztsplc.com/mobile_current_status",
            "redirecttomobile_customer_report": "https://mayag.ztsplc.com/mobile_customer_report",

            "stoppage_1": "https://api.mayag.ztsplc.com/api/misc/stoppage_1",
            "stoppage_2": "https://api.mayag.ztsplc.com/api/misc/stoppage_2",
            "stoppage_3": "https://api.mayag.ztsplc.com/api/misc/stoppage_3",

            "script_1": "https://api.mayag.ztsplc.com/api/adminscript/script_1",
            "check_fo_status": "https://api.mayag.ztsplc.com/api/adminscript/check_fo_status",

            "urluser": "/user",
            "urlobjuser": "/objuser",
            "urluseraccess1": "/useraccess1",
            "useraccess2": "/useraccess2",

            "urlclient_type": "/client_type",
            "urlclient_mgt": "/client_mgt",
            "urlcoc_mgt": "/coc_mgt",
            "urlcargo_type": "/cargo_type",
            "urlorder": "/order",
            "urlvehicle": "/vehicle",
            "urldriver": "/driver",
            "urltrailer": "/trailer",
            "urllocation": "/location",
            "urlroad_route": "/road_route",

            "urlassign_own": "/assign_own",
            "urlupdate_own": "/update_own",
            "urlother_cost": "/other_cost",
            "urlpayment_req": "/payment_req",
            "urlpayment_app": "/payment_app",
            "urlpayment_pay": "/payment_pay",
            //"urlfollow_up": "/follow_up",
            "urlstatus_type": "/status_type",
            "urlvehicle_status": "/vehicle_status",
            "urlfollow_up_2": "/follow_up_2",
            "urlfollow_up_hisotry": "/follow_up_hisotry",
            "urlfollow_up_eta": "/follow_up_eta",
            "urlfollow_up_customer": "/follow_up_customer",
            "urldelete_own": "/delete_own",
            "urlfollow_up_report": "/follow_up_report",
            "urldocument_mgt": "/document_mgt",
            "urlfollow_up_loading_unloading": "/follow_up_loading_unloading",
            "urlfollow_up_transit": "/follow_up_transit",
            "urlfollow_up_wfo": "/follow_up_wfo",
            "urlfollow_up_stoppage": "/follow_up_stoppage",
            "urlfuel_rate": "/fuel_rate",
            "urlr_trip_master": "/r_trip_master",
            "urlr_load_factor": "/r_load_factor",
            "urlupdate_allocate": "/update_allocate",
            "urlg_in_req": "/g_in_req",
            "urlg_in_app": "/g_in_app",
            "urlg_out_req": "/g_out_req",
            "urlg_out_app": "/g_out_app",
            "urlg_status": "/g_status",
            "urlfollow_up_manual": "/follow_up_manual",
            "urlr_maintance": "/r_maintance",
            "urld_status": "/d_status",
            "urlr_ava": "/r_ava",
            "urlr_tat": "/r_tat",
            "urlr_fright_order": "/r_fright_order",
            "urlr_daily_stoppage": "/r_daily_stoppage",
            "urlr_movement": "/r_movement",
            "urlr_tac": "/r_tac",
            "urlr_tac_current": "/r_tac_current",
            "urlr_time_to_load": "/r_time_to_load",
            "urlr_time_to_load_open": "/r_time_to_load_open",
            "urlcancel_allocate": "/cancel_allocate",
            "urlfinance_fo_by_date": "/finance_fo_by_date",
            "urlfinance_fo_by_plate_no": "/finance_fo_by_plate_no",
            "urlfinance_canceled_fo": "/finance_canceled_fo",
            "urladmin_script": "/admin_script",
            "urlr_detail_tat": "/r_detail_tat",
            "urlfinance_doc_rec": "/finance_doc_rec",
            "urlfinance_not_doc_rec": "/finance_not_doc_rec",
            "urlopex_cost_req": "/opex_cost_req",
            "urlopex_cost_app": "/opex_cost_app",
            "urlopex_cost_payment": "/opex_cost_payment",
            "urlopex_fuel_req": "/opex_fuel_req",
            "urlopex_fuel_app": "/opex_fuel_app",
            "urlopex_fuel_giver": "/opex_fuel_giver",
            "urlopex_cost_refund": "/opex_cost_refund",
            "urlopex_fuel_refund": "/opex_fuel_refund",
            "urlfright_document": "/fright_document",
            "urlfinance_document": "/finance_document",
            "urlop_refund_req": "/op_refund_req",
            "urlop_refund_app": "/op_refund_app",
            "urlopex_summuary": "/opex_summuary",
            "urlopex_detail": "/opex_detail",
            "urlopex_cost_payment_all": "/opex_cost_payment_all",
            "urlop_trip_cancel_req": "/op_trip_cancel_req",
            "urlop_trip_cancel_app": "/op_trip_cancel_app",
            "urlop_trip_extend_req": "/op_trip_extend_req",
            "urlop_trip_extend_app": "/op_trip_extend_app",
            "urlopex_cost_payment_validator": "/opex_cost_payment_validator",
            "urlreport_generator": "/report_generator",
            "urlvoid_fo": "/void_fo",
            "urlfollow_up_movement": "/follow_up_movement",
            "urlorder_open": "/order_open",
            "urlopex_cost_bank_tt": "/opex_cost_bank_tt",
            "urlorder_accept": "/order_accept",
            "urlorder_accept_fin": "/order_accept_fin",
            "urlos_company": "/os_company",
            "urlos_vehicle": "/os_vehicle",
            "urlos_driver": "/os_driver",
            "urlos_trailer": "/os_trailer",
            "urlassign_sub_contractor_req": "/assign_sub_contractor_req",
            "urlassign_sub_contractor_app": "/assign_sub_contractor_app",
            "urlsub_contractors": "/sub_contractors",
            "urlsupplier_company": "/supplier_company",
            "urlassign_supplier_company_req": "/assign_supplier_company_req",
            "urlassign_supplier_company_app": "/assign_supplier_company_app",
            "urlsuppliers": "/suppliers",
            "urlorder_status": "/order_status",
            "urlorder_detail": "/order_detail",
            "urlop_reconcile": "/op_reconcile",
            "urlorder_update": "/order_update",
            "urlorder_op_update_app": "/order_op_update_app",
            "urlorder_fin_update_app": "/order_fin_update_app",
            "urlcement_open": "/cement_open",
            "urlcement_app": "/cement_app",
            "urlcement_update": "/cement_update",
            "urlcement_update_app": "/cement_update_app",
            "urlcement_status": "/cement_status",
            "urlorder_loading_adv_req": "/order_loading_adv_req",
            "urlorder_loading_adv_app": "/order_loading_adv_app",
            "urlorder_loading_adv": "/order_loading_adv",
            "urlpr_prepare": "/pr_prepare",
            "urlpr_check": "/pr_check",
            "urlpr_app": "/pr_app",
            "urlpo_app": "/po_app",
            "urlpurchase_status": "/purchase_status",
            "urlstatus_purchase": "/status_purchase",
            "urlstatus_cement": "/status_cement",
            "urlstatus_delivery": "/status_delivery",
            "urlstatus_purchase_detail": "/status_purchase_detail",
            "urlstatus_delivery_detail": "/status_delivery_detail",
            "urluser_template": "/user_template",
            "urlveh_ava": "/veh_ava",
            "urlcement_current_status": "/cement_current_status",
            "urlorder_current_status": "/order_current_status",
            "urlpo_prepare": "/po_prepare",
            "urlpo_chk": "/po_chk",
            "urlpurchase": "/purchase",
            "urlstatus_cement_detail": "/status_cement_detail",
            "urlcement_delivery": "/cement_delivery",
            "urlcement_delivery_report": "/cement_delivery_report",
            "urlcement_delivery_cancel_req": "/cement_delivery_cancel_req",
            "urlcement_delivery_cancel_app": "/cement_delivery_cancel_app",
            "urlcement_so": "/cement_so",
            "urlcement_delivery_edit": "/cement_delivery_edit",
            "urlstatus_cement_allocation": "/status_cement_allocation",
            "urlroad_route_open": "/road_route_open",
            "urlkpi_umo": "/kpi_umo",
            "urlkpi_description": "/kpi_description",
            "urlkpi_data": "/kpi_data",
            "urlmain_temp": "/main_temp",
            "urlfollow_up_distance_calc": "/follow_up_distance_calc",
            "urlfollow_up_wizard": "/follow_up_wizard",
            "urltg_status_sum": "/tg_status_sum",
            "urltg_status_det": "/tg_status_det",
            "urltg_status_single": "/tg_status_single",
            "urltg_stop_at_client": "/tg_stop_at_client",
            "urlpre_assign_req": "/pre_assign_req",
            "urlpre_assign_app": "/pre_assign_app",
            "urlassign_vehicle": "/assign_vehicle",
            "urlopex_cost": "/opex_cost",
            "urlopex_fuel": "/opex_fuel",
            "urlpre_assign_exe": "/pre_assign_exe",
            "urlrefund_to_company_req": "/refund_to_company_req",
            "urlrefund_to_company_app": "/refund_to_company_app",
            "urlpos_refund_req": "/pos_refund_req",
            "urlpos_refund_app": "/pos_refund_app",
            "urlneg_refund_req": "/neg_refund_req",
            "urlneg_refund_app": "/neg_refund_app",
            "urlopex_report": "/opex_report",
            "urlorder_weight_req": "/order_weight_req",
            "urlorder_weight_app": "/order_weight_app",
            "urlopex_reconcile": "/opex_reconcile",
            "urlinvoice_prepare": "/invoice_prepare",
            "urlpayment_settlement": "/payment_settlement",
            "urlopp_manage": "/opp_manage",
            "urlfo_manage": "/fo_manage",
            "urlopex_balance_sheet": "/opex_balance_sheet",
            "urlorder_add_expence_req": "/order_add_expence_req",
            "urlorder_add_expence_app": "/order_add_expence_app",
            "urlorder_add_expence_set": "/order_add_expence_set",
            "urlreport_master_trip": "/report_master_trip",
            "urlreport_order_allocation": "/report_order_allocation",
            "urlupdate_assign_vehicle": "/update_assign_vehicle",
            "urlreport_status_update": "/report_status_update",
            "urlreport_current_status": "/report_current_status",
            "urlreport_status": "/report_status",
            "urlassign_history": "/assign_history",
            "urlaudit_assignment": "/audit_assignment",

            "token_mobile": "Mayag_2025_TMS_System_Devlopment_101",
            "color_1": "#1865a9",
            "color_2": "#ee9029",
            "color_3": "#ffffff",
    }
export default myconfig;