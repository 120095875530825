import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Assign_sub_contractor_req extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: true,
            id:-1,

            sub_contractor_companys: [],
            sub_contractor_company_selected: null,

            sub_contractor_vehicles: [],
            sub_contractor_vehicle_selected: null,

            sub_contractor_drivers: [],
            sub_contractor_driver_selected: null,

            sub_contractor_trailers: [],
            sub_contractor_trailer_selected: null,

            orders: [],
            order_selected: null,

            order_display:false,
            order_no:'',
            org:'',
            des:'',
            distance:'',
            cargo:'',
            des_good:'',
            tol:'',
            avl_weight:'',
            order_rate:'',

            loaded_weight:'',
            fright_no:'',
            allocate_date:'',
            transport_rate:'',
            remark:'',
        }
    }
    handelchange_sub_contractor_company = (sub_contractor_company_selected) => {
        if(sub_contractor_company_selected!=null){
            this.setState({
                sub_contractor_vehicles: [],
                sub_contractor_vehicle_selected: null,
                sub_contractor_drivers: [],
                sub_contractor_driver_selected: null,
                sub_contractor_trailers: [],
                sub_contractor_trailer_selected: null,
            },()=>{
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.get_asset_for_assign_sub_contractor_req;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: sub_contractor_company_selected.value,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            sub_contractor_company_selected:sub_contractor_company_selected,
                            sub_contractor_vehicles:res.data.sub_contractor_vehicles,
                            sub_contractor_drivers:res.data.sub_contractor_drivers,
                            sub_contractor_trailers:res.data.sub_contractor_trailers,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            })
        }
    }
    handelchange_sub_contractor_vehicle = (sub_contractor_vehicle_selected) => {
        this.setState({
            sub_contractor_vehicle_selected:sub_contractor_vehicle_selected,
        })
    }
    handelchange_sub_contractor_driver = (sub_contractor_driver_selected) => {
        this.setState({
            sub_contractor_driver_selected:sub_contractor_driver_selected,
        })
    }
    handelchange_sub_contractor_trailer = (sub_contractor_trailer_selected) => {
        this.setState({
            sub_contractor_trailer_selected:sub_contractor_trailer_selected,
        })
    }
    handelchange_order = (order_selected) => {
        if (order_selected != null) {
            this.setState({
                order_display: false,
                order_no: '',
                org: '',
                des: '',
                distance: '',
                cargo: '',
                des_good: '',
                tol: '',
                avl_weight: '',
                order_rate: '',
            }, () => {
                let temp = this.state.orders.filter(v => {
                    if (v.value === order_selected.value)
                        return v;
                })
                if (temp.length > 0) {

                    this.setState({
                        order_display: true,
                        order_no: temp[0].order_no,
                        org: temp[0].org,
                        des: temp[0].des,
                        distance: temp[0].distance,
                        cargo: temp[0].cargo,
                        des_good: temp[0].des_good,
                        tol: temp[0].tol,
                        avl_weight: temp[0].avl_weight,
                        order_rate: temp[0].order_rate,
                        order_selected: order_selected,
                    })
                }
            })
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_assign_sub_contractor_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    sub_contractor_companys:res.data.sub_contractor_companys,
                    orders:res.data.orders,
                    datas:res.data.datas,
                    filtered:res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            sub_contractor_company_selected: null,
            sub_contractor_vehicle_selected: null,
            sub_contractor_driver_selected: null,
            sub_contractor_trailer_selected: null,
            order_selected: null,
            order_display:false,
            order_no:'',
            org:'',
            des:'',
            distance:'',
            cargo:'',
            des_good:'',
            tol:'',
            avl_weight:'',
            order_rate:'',
            loaded_weight:'',
            fright_no:'',
            allocate_date:'',
            transport_rate:'',
            remark:'',
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                coc: temp[0].coc,
                sub_contractor_company_selected: temp[0].sub_contractor_company_selected,
                sub_contractor_vehicle_selected: temp[0].sub_contractor_vehicle_selected,
                sub_contractor_driver_selected: temp[0].sub_contractor_driver_selected,
                sub_contractor_trailer_selected: temp[0].sub_contractor_trailer_selected,
                order_selected: temp[0].order_selected,
                order_display:true,
                order_no:temp[0].order.order_no,
                org:temp[0].order.org,
                des:temp[0].order.des,
                distance:temp[0].order.distance,
                cargo:temp[0].order.cargo,
                des_good:temp[0].order.des_good,
                tol:temp[0].order.tol,
                avl_weight:temp[0].order.avl_weight,
                order_rate:temp[0].order.order_rate,
                loaded_weight:temp[0].loaded_weight,
                fright_no:temp[0].fright_no,
                allocate_date:temp[0].allocate_date,
                transport_rate:temp[0].transport_rate,
                remark:temp[0].remark,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.sub_contractor_company_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor company.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state. sub_contractor_vehicle_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor vehicle.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state. sub_contractor_driver_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor driver.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }else if(this.state. order_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select order.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.assign_sub_contractor_req;
            let sub_contractor_trailer_id=this.state.sub_contractor_trailer_selected==null?null:this.state.sub_contractor_trailer_selected.value
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                sub_contractor_company_id:this.state.sub_contractor_company_selected.value,
                sub_contractor_vehicle_id:this.state.sub_contractor_vehicle_selected.value,
                sub_contractor_driver_id:this.state.sub_contractor_driver_selected.value,
                sub_contractor_trailer_id:sub_contractor_trailer_id,
                order_id:this.state.order_selected.value,
                loaded_weight:this.state.loaded_weight,
                fright_no:this.state.fright_no,
                allocate_date:this.state.allocate_date,
                transport_rate:this.state.transport_rate,
                remark:this.state.remark,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    btnupdate = (event) => {
        event.preventDefault();
        if(this.state.sub_contractor_company_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor company.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state. sub_contractor_vehicle_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor vehicle.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state. sub_contractor_driver_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor driver.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }else if(this.state. order_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select order.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.edit_for_assign_sub_contractor_req;
            let sub_contractor_trailer_id=this.state.sub_contractor_trailer_selected==null?null:this.state.sub_contractor_trailer_selected.value
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                sub_contractor_company_id:this.state.sub_contractor_company_selected.value,
                sub_contractor_vehicle_id:this.state.sub_contractor_vehicle_selected.value,
                sub_contractor_driver_id:this.state.sub_contractor_driver_selected.value,
                sub_contractor_trailer_id:sub_contractor_trailer_id,
                order_id:this.state.order_selected.value,
                loaded_weight:this.state.loaded_weight,
                fright_no:this.state.fright_no,
                allocate_date:this.state.allocate_date,
                transport_rate:this.state.transport_rate,
                remark:this.state.remark,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value.id)}><i className="fas fa-edit px-1"></i></a>
                </td>
                <td>{value.fright_no}</td>
                <td>{value.order_no}</td>
                <td>{value.label}</td>
                <td>{value.company_name}</td>
                <td>{value.plate_no}</td>
                <td>{value.allocate_date}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-block"
                        style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                        onClick={this.btnnew}>New
                        REQ</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Fright # </th>
                            <th className="col-md-1">Order # </th>
                            <th className="col-md-3">Order </th>
                            <th className="col-md-2">Sub-contractor</th>
                            <th className="col-md-2">Truck # </th>
                            <th className="col-md-1">Allocated Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let btn_1 = <button className="btn btn-block btn-sm"
                            style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                            onClick={this.btncreate}> REQUEST </button>
        if (!this.state.iscreate) {
            btn_1 = <button className="btn btn-block btn-sm"
                            style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                            onClick={this.btnupdate}> UPDATE </button>
        }
        let order_display=null
        if(this.state.order_display) {
            order_display = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.order_no}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Orgin</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.org}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Destination</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.des}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Millage</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.distance}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Cargo</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.cargo}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Description of Good</label>
                        <textarea readOnly={true} rows={5} className="form-control form-control-sm"
                                 value={this.state.des_good}></textarea>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Types of Load</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.tol}/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Available Weight</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.avl_weight}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Weight</label>
                        <input type="text" className="form-control form-control-sm" name="loaded_weight"
                               value={this.state.loaded_weight} onChange={this.inputmgt}
                               placeholder="Weight"/>
                    </div>
                </div>
                <div className="col-md-4">
                </div>


                <div className="col-md-4">
                    <div className="form-group">
                        <label>Order Rate</label>
                        <input readOnly={true} className="form-control form-control-sm"
                               value={this.state.order_rate}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Transport Rate</label>
                        <input type="text" className="form-control form-control-sm" name="transport_rate"
                               value={this.state.transport_rate} onChange={this.inputmgt}
                               placeholder="Transport Rate"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Remark</label>
                        <textarea rows={5} className="form-control form-control-sm"
                               name="remark"   onChange={this.inputmgt} value={this.state.remark}></textarea>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        {btn_1}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <button className="btn btn-block btn-sm"
                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                onClick={this.btncancel}> Cancel
                        </button>
                    </div>
                </div>
            </>
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Sub-Contractor Allocation REQUEST</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Company</label>
                                        <Select
                                            value={this.state.sub_contractor_company_selected}
                                            onChange={this.handelchange_sub_contractor_company}
                                            options={this.state.sub_contractor_companys}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Vehicle</label>
                                        <Select
                                            value={this.state.sub_contractor_vehicle_selected}
                                            onChange={this.handelchange_sub_contractor_vehicle}
                                            options={this.state.sub_contractor_vehicles}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Driver</label>
                                        <Select
                                            value={this.state.sub_contractor_driver_selected}
                                            onChange={this.handelchange_sub_contractor_driver}
                                            options={this.state.sub_contractor_drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Trailer</label>
                                        <Select
                                            value={this.state.sub_contractor_trailer_selected}
                                            onChange={this.handelchange_sub_contractor_trailer}
                                            options={this.state.sub_contractor_trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input type="text" className="form-control form-control-sm" name="fright_no"
                                               value={this.state.fright_no} onChange={this.inputmgt}
                                               placeholder="Fright #"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input type="date" className="form-control form-control-sm" name="allocate_date"
                                               value={this.state.allocate_date} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                {order_display}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="assign_sub_contractor_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Allocate Sub-Contractor Vehicle REQ</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Assign_sub_contractor_req;
