import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Report_order_allocation extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),

            clients:[],
            client_selected:null,

            orders:[],
            order_selected:null,

            rep_executing:false,
            rows_1:[],
            rows_2:[],
        }
    }

    handelchange_client = (client_selected) => {
        this.setState({
            orders: [],
            order_selected: null,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_order_for_report_order_allocation;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                client_id: client_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        orders: res.data.orders,
                        client_selected: client_selected,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    handelchange_order = (order_selected) => {
        this.setState({
            order_selected: order_selected,
        })
    }

    componentDidMount() {
        this.fetchdata();
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_client_for_report_order_allocation;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    clients: res.data.clients,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    getreport = (event) => {
        event.preventDefault()
        if (!this.state.rep_executing) {
            if (this.state.order_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select order",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    rep_executing: true,
                },()=>{
                    let redirecttologin = myconfig.redirecttologin;
                    let baseurl = myconfig.report_order_allocation;
                    axios.post(baseurl, {
                        sid: window.localStorage.getItem("logmgtsys_sid"),
                        order_id: this.state.order_selected.value,
                    }, {cancelToken: source.token}).then(res => {
                        console.log(res.data)
                        if (res.data.auth == false) {
                            window.localStorage.setItem("logmgtsys_sid", null);
                            window.localStorage.setItem("logmgtsys_username", null);
                            window.localStorage.setItem("logmgtsys_name", null);
                            var win = window.open(redirecttologin, "_self");
                        } else if (res.data.success == false) {
                            this.setState({
                                rep_executing: false,
                            }, () => {
                                Swal.fire({
                                    title: 'Error!',
                                    text: res.data.msg,
                                    icon: 'error',
                                    confirmButtonText: 'Okay'
                                })
                            })
                        }else {
                            this.setState({
                                rows_1:res.data.rows_1,
                                rows_2:res.data.rows_2,
                                rep_executing:false,
                             })
                        }
                    }).catch((e) => {
                        console.log(e.message);
                    });
                })
            }
        }
        else{
            Swal.fire({
                title: 'Information!',
                text: "Report is being executing. Please wait",
                icon: 'info',
                confirmButtonText: 'Okay'
            })
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    render() {
        let btn_text="Loading..."
        let report=null;
        if(!this.state.rep_executing){
            btn_text="Execute";
            let tdata=this.state.rows_1.map(v=>{
                let temps=this.state.rows_2.filter(v2=>{
                    if(v2.id===v.id)
                        return v2;
                })
                let rows=temps.map(v3=>{
                    if(v3.type==1)
                        return(<th>{v3.data}</th>)
                    else
                    {
                        return(<td>{v3.data}</td>)
                    }
                })
                return (<tr>
                    {rows}
                </tr>)
            })
            report=  <div className="col-md-12">
              <div className="card">
                  <div className="card-header"
                       style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}>
                      <h3 className="card-title">Report Result</h3>
                      <div className="card-tools">
                      </div>
                  </div>
                  <div className="card-body">
                      <div className="row">
                          <div className="col-12 px-3 py-1 text-left"><a href="#" onClick={this.btnexport_report}>Download .xlsx</a></div>
                          <div className="table" id="section_to_print">
                              <table id="report" className="table table-sm table-bordered table-responsive">
                                  {tdata}
                              </table>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        }
        return (
            <>
                <Nav type="report_order_allocation"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Report Master Trip</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Client</label>
                                                        <Select
                                                            value={this.state.client_selected}
                                                            onChange={this.handelchange_client}
                                                            options={this.state.clients}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Order</label>
                                                        <Select
                                                            value={this.state.order_selected}
                                                            onChange={this.handelchange_order}
                                                            options={this.state.orders}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <button type="button" className="btn float-right  mx-2"
                                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        this.setState({
                                                            vehicle_selected:null,
                                                            rep_executing:false,
                                                            rows_1:[],
                                                            rows_2:[],
                                                        })
                                                    }}>Cancel
                                            </button>
                                            <button type="button" className="btn float-right btn-sm  mx-2"
                                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                    onClick={this.getreport}>{btn_text}
                                            </button>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                </div>
                                {report}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Report_order_allocation;
