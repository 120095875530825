import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Order_weight_app extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            prv_datas: [],

            txtsearch: '',


            wiz: 1,
            iscreate: false,
            id: -1,
            name: '',
            data: {},

        }
    }

    componentDidMount() {
        this.fetchdata()
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_order_weight_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.label.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnrequest = (event, id) => {
        event.preventDefault();
        let temp = this.state.filtered.filter(v => {
            if (v.id === id)
                return v;
        })
        if (temp.length > 0) {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_obj_single_for_order_weight_app;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let data = {};
                    data.id = temp[0].id
                    data.order_no = temp[0].order_no
                    data.label = temp[0].label
                    data.org = temp[0].org
                    data.des = temp[0].des
                    data.cargo = temp[0].cargo
                    data.total_weight = temp[0].total_weight
                    data.avl_weight = temp[0].avl_weight
                    data.delivered_weight = temp[0].delivered_weight
                    data.distance = temp[0].distance
                    data.transport_rate = temp[0].transport_rate
                    data.client = temp[0].client
                    data.st_date = temp[0].st_date
                    data.ed_date = temp[0].ed_date
                    data.amount = res.data.amount;
                    data.remark = res.data.remark;
                    this.setState({
                        wiz: 2,
                        iscreate: false,
                        data: data,
                        prv_datas: res.data.datas,
                        id: data.id,
                        quantity: '',
                        remark: '',
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id: -1,
            iscreate: true,
            name: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.order_weight_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            quantity: this.state.quantity,
            remark: this.state.remark,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_cargo;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name: this.state.company_name,
            id: this.state.id,
            name: this.state.name,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filtered.map(value => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnrequest(event, value.id)}>Manage</a>
                </td>
                <td>{value.st_date}</td>
                <td>{value.label}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.cargo}</td>
                <td>{value.total_weight}</td>
                <td>{value.avl_weight}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Order Date</th>
                            <th className="col-md-2">Order</th>
                            <th className="col-md-2">Origin</th>
                            <th className="col-md-2">Destination</th>
                            <th className="col-md-1">Cargo</th>
                            <th className="col-md-1">Total Weight (q)</th>
                            <th className="col-md-1">AVL Weight (q)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata = this.state.prv_datas.map(v => {
            return (
                <tr>
                    <td>{v.label}</td>
                    <td>{v.order_no}</td>
                    <td>{v.amount}</td>
                    <td>{v.req_at}</td>
                    <td>{v.remark}</td>
                    <td>{v.req_by}</td>
                    <td>{v.status}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor: myconfig.color_2, color: myconfig.color_3}}>
                            <h3 className="card-title">REQUEST FORM</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label>Order </label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.label}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Total Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.total_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Delivered Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.delivered_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Available Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.avl_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.amount}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                                  value={this.state.data.remark}/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {this.approve_rej(event,true)}} style={{
                                            color: myconfig.color_3,
                                            backgroundColor: myconfig.color_1
                                        }}> APPROVE
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {this.approve_rej(event,false)}} style={{
                                            color: myconfig.color_3,
                                            backgroundColor: myconfig.color_2
                                        }}> REJECT
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btncancel} style={{
                                            color: myconfig.color_3,
                                            backgroundColor: myconfig.color_1
                                        }}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <table className="table table-bordered table-sm">
                                        <tr>
                                            <th className="col-md-2">Order</th>
                                            <th className="col-md-1">Order #</th>
                                            <th className="col-md-1">Amount</th>
                                            <th className="col-md-2">Date</th>
                                            <th className="col-md-2">Remark</th>
                                            <th className="col-md-2">REQ BY</th>
                                            <th className="col-md-2">STATUS</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>)
    }
    approve_rej = (event,is_app) => {
        event.preventDefault()
        Swal.fire({
            title: 'Are you sure you want to execute this request?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.app_rej_order_weight_app;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id:this.state.id,
                    is_app:is_app,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v=>{
                            this.setState({
                                wiz:1,
                            },()=>{
                                this.fetchdata()
                            })
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="order_weight_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor: myconfig.color_1, color: myconfig.color_3}}>
                                            <h3 className="card-title">Weight Approve</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_weight_app;
