import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Refund_to_company_app extends Component {

    constructor() {
        super();
        this.state = {
            data: {},
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_refund_to_company_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            name: '',
        })
    }
    getsingle = (event,id,type) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_cost_for_refund_to_company_app;
        if(type==="Fuel")
            baseurl = myconfig.get_single_fuel_for_refund_to_company_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    data:res.data.data,
                    id:id,
                    type:type,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnapprove = (event,is_app) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to execute the data. You can not undone the operation?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.refund_cost_to_company_app;
                if(this.state.type==="Fuel")
                    baseurl = myconfig.refund_fuel_to_company_app;

                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id:this.state.id,
                    is_app:is_app,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            }, () => {
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td>
                    <a href="" onClick={event => this.getsingle(event,value.id,value.type)}>Manage</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.req_by}</td>
                <td>{value.req_at}</td>
                <td>{value.amount}</td>
                <td>{value.type}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-2">Plate No</th>
                            <th className="col-md-2">REQ BY</th>
                            <th className="col-md-2">REQ At</th>
                            <th className="col-md-2">Amount</th>
                            <th className="col-md-2">Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let dis=null;
        if(this.state.type==="Fuel")
            dis=<>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Station</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.data.station}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Rate</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.data.rate}/>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </>
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Refund Request</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.amount}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.allocate_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>FO No</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.fo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Customer Name</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.type}/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                {dis}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Refund Amount</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="amount"
                                               value={this.state.data.amount} onChange={this.inputmgt}
                                               placeholder="Refund Amount"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea disabled={true} rows={5} className="form-control form-control-sm" name="remark"
                                                  value={this.state.data.remark} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {this.btnapprove(event,true)}} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Approve
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {this.btnapprove(event,false)}}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Reject
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="refund_to_company_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Refund To Company APPROVE / REJECT</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Refund_to_company_app;
