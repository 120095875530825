import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Report_status_update extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),

            main_datas:[],


            rep_executing:false,
            rows_1:[],
            rows_2:[],

        }
    }
    componentDidMount() {
        this.fetchdata();
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchdata = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_orders_for_report_status_update;
        await this.setState({
            msg:'Fetching orders....'
        })
        let orders=[];
        let assign_owns=[];
        await axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                orders=res.data.orders
            }
        }).catch((e) => {
            console.log(e.message);
        });

        for(let i=0; i<orders.length; i++) {
            let baseurl2 = myconfig.get_assigns_for_report_status_update;
            await axios.post(baseurl2, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                order_id:orders[i].value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    for(let o=0;o<res.data.assign_owns.length;o++){
                        assign_owns.push({
                            assign_own_id:res.data.assign_owns[o].assign_own_id,
                            order_id:res.data.assign_owns[o].order_id,
                            vehicle_id:res.data.assign_owns[o].vehicle_id,
                            veh_id:res.data.assign_owns[o].veh_id,
                            plate_no:res.data.assign_owns[o].plate_no,
                            trailer:res.data.assign_owns[o].trailer,
                            driver_name:res.data.assign_owns[o].driver_name,
                            phone_no:res.data.assign_owns[o].phone_no,
                            date_dispatch_from_eth:res.data.assign_owns[o].date_dispatch_from_eth,
                            date_dispatch_from_dj:res.data.assign_owns[o].date_dispatch_from_dj,
                            day_since_dj_dispatch:res.data.assign_owns[o].day_since_dj_dispatch,
                            status:res.data.assign_owns[o].status,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

        let msg = this.state.msg +
            "\n" +
            orders.length +
            " order fetched"
        await this.setState({
            msg: msg,
        })
        for(let j=0; j<orders.length;j++) {
            let msg = this.state.msg +
                "\n" +
                "Executing " +
                orders[j].label +
                "(" +
                orders[j].st_date +
                ")"+
                "Executing ("+
                (j+1)+
                "of "+
                 orders.length+
                ")"
            let main_datas=this.state.main_datas;
            main_datas.push({
                order_id:orders[j].value,
                label:orders[j].label,
                st_date:orders[j].st_date,
                order_header:orders[j].label+" ("+orders[j].st_date+") ",

                assign_own_id:"",
                vehicle_id:"",
                veh_id:"",
                plate_no:"",
                trailer:"",
                driver_name:"",
                phone_no:"",
                date_dispatch_from_eth:"",
                date_dispatch_from_dj:"",
                day_since_dj_dispatch:"",
                status:"",
                type:1,
            })
            await this.setState({
                msg: msg,
                main_datas: main_datas,
            })
            let single_assign_owns=assign_owns.filter(v=>{
                if(v.order_id===orders[j].value)
                    return v;
            })
            msg = this.state.msg +
                "\n" +
                "Executing a total vehicle of  " +
                single_assign_owns.length;
            await this.setState({
                msg: msg,
            })
            for(let o=0; o<single_assign_owns.length; o++){
                let msg = this.state.msg +
                    "\n" +
                    "Executing (" +
                    (o+1)+" of "+
                    (single_assign_owns.length)+" data) "+
                    single_assign_owns[o].plate_no;
                await this.setState({
                    msg: msg,
                })

                let baseurl2 = myconfig.get_location_for_report_status_update;
                await axios.post(baseurl2, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    assign_own_id:single_assign_owns[o].assign_own_id,
                    eid:this.state.eid,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        let main_datas=this.state.main_datas;
                        main_datas.push({
                            plate_no:single_assign_owns[o].plate_no,
                            trailer:single_assign_owns[o].trailer,
                            driver_name:single_assign_owns[o].driver_name,
                            phone_no:single_assign_owns[o].phone_no,
                            date_dispatch_from_eth:single_assign_owns[o].date_dispatch_from_eth,
                            date_dispatch_from_dj:single_assign_owns[o].date_dispatch_from_dj,
                            short_name:res.data.data.short_name,
                            avltime:res.data.data.avltime,
                            day_since_dj_dispatch:single_assign_owns[o].day_since_dj_dispatch,
                            status:single_assign_owns[o].status,
                            order_id:orders[j].value,
                            label:orders[j].label,
                            st_date:orders[j].st_date,
                            order_header:orders[j].label+" ("+orders[j].st_date+") ",
                            assign_own_id:single_assign_owns[o].assign_own_id,
                            vehicle_id:single_assign_owns[o].vehicle_id,
                            veh_id:single_assign_owns[o].veh_id,

                            loc:res.data.data.loc,
                            cor:res.data.data.cor,

                            type:2,
                        })
                        this.setState({
                            eid:res.data.eid,
                            main_datas:main_datas,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });

            }
        }
    }
    getreport = (event) => {
        event.preventDefault()
        if (!this.state.rep_executing) {
            if (this.state.order_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select order",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else {
                this.setState({
                    rep_executing: true,
                },()=>{
                    let redirecttologin = myconfig.redirecttologin;
                    let baseurl = myconfig.report_order_allocation;
                    axios.post(baseurl, {
                        sid: window.localStorage.getItem("logmgtsys_sid"),
                        order_id: this.state.order_selected.value,
                    }, {cancelToken: source.token}).then(res => {
                        console.log(res.data)
                        if (res.data.auth == false) {
                            window.localStorage.setItem("logmgtsys_sid", null);
                            window.localStorage.setItem("logmgtsys_username", null);
                            window.localStorage.setItem("logmgtsys_name", null);
                            var win = window.open(redirecttologin, "_self");
                        } else if (res.data.success == false) {
                            this.setState({
                                rep_executing: false,
                            }, () => {
                                Swal.fire({
                                    title: 'Error!',
                                    text: res.data.msg,
                                    icon: 'error',
                                    confirmButtonText: 'Okay'
                                })
                            })
                        }else {
                            this.setState({
                                rows_1:res.data.rows_1,
                                rows_2:res.data.rows_2,
                                rep_executing:false,
                            })
                        }
                    }).catch((e) => {
                        console.log(e.message);
                    });
                })
            }
        }
        else{
            Swal.fire({
                title: 'Information!',
                text: "Report is being executing. Please wait",
                icon: 'info',
                confirmButtonText: 'Okay'
            })
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }


    render() {
        let count=0;
        let tdata=this.state.main_datas.map(v=>{
            if(v.type===1){
                return(
                    <tr>
                        <td colSpan={11} >{v.label}</td>
                    </tr>
                )
            }
            else {
                count = count + 1
                return (
                    <tr>
                        <td>{count}</td>
                        <td>{v.plate_no}</td>
                        <td>{v.trailer}</td>
                        <td>{v.driver_name}</td>
                        <td>{v.phone_no}</td>
                        <td>{v.date_dispatch_from_eth}</td>
                        <td>{v.date_dispatch_from_dj}</td>
                        <td>{v.short_name}</td>
                        <td>{v.avltime}</td>
                        <td>{v.day_since_dj_dispatch}</td>
                        <td>{v.status}</td>
                    </tr>
                )
            }

        })
        return (
            <>
                <Nav type="report_status_update"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Report Status Update</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Status Update</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Status</label>
                                                        <textarea className="form-control form-control-sm"
                                                                  disabled={false}
                                                                  rows={8}
                                                                  name="msg"
                                                                  value={this.state.msg}
                                                                  onChange={this.inputmgt}/>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}>
                                            <h3 className="card-title">Report Result</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3 py-1 text-left"><a href="#" onClick={this.btnexport_report}>Download .xlsx</a></div>
                                                <div className="table" id="section_to_print">
                                                    <table id="report" className="table table-sm table-bordered table-responsive">
                                                        <tr>
                                                            <th className="col-md-1">#</th>
                                                            <th className="col-md-1">Truck No</th>
                                                            <th className="col-md-1">Trailer No</th>
                                                            <th className="col-md-1">Driver Name</th>
                                                            <th className="col-md-1">Phone Number</th>
                                                            <th className="col-md-1">Date Dispatch from ETH</th>
                                                            <th className="col-md-1">Date Dispatch From DJ</th>
                                                            <th className="col-md-1">Current Location</th>
                                                            <th className="col-md-1">GPS Update Time</th>
                                                            <th className="col-md-1">Day Since DJ Dispatch</th>
                                                            <th className="col-md-2">Status</th>
                                                        </tr>
                                                        {tdata}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Report_status_update;
