import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Refund_to_company_req extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            opex_costs: [],
            opex_fuels: [],
            type:'',
            data:{},
            txtsearch: '',

            wiz: 1,
            iscreate: false,
            id:-1,
            plate_no:'',
            amount:'',
            remark:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_refund_to_company_req;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.plate_no.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                name: temp[0].name,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            name: '',
        })
    }
    btnrequest = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cost_for_refund_to_company_req;
        if(this.state.type==="fuel")
            baseurl = myconfig.fuel_for_refund_to_company_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            amount:this.state.amount,
            remark:this.state.remark,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    get_cost = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_costs_for_refund_to_company_req;
        this.setState({
            type:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id:this.state.id,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    opex_costs:res.data.datas,
                    type:'cost',
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    get_fuel = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_fuels_for_refund_to_company_req;
        this.setState({
            type:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id:this.state.id,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    opex_fuels:res.data.datas,
                    type:'fuel',
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    refund_single = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_cost_for_refund_to_company_req;
        if(this.state.type==="fuel")
            baseurl = myconfig.get_single_fuel_for_refund_to_company_req;

        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    id: id,
                    wiz: 3,
                    data:res.data.data,
                    amount:res.data.data.amount,
                    remark:res.data.data.remark,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            id:value.id,
                            plate_no:value.plate_no,
                            wiz:2,
                            opex_costs:[],
                            opex_fuels:[],
                            type:'',
                        })
                    }}>Refund</a>
                </td>
                <td>{value.plate_no}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-9">Truck No.</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let display=null;
        if(this.state.type==="cost"){
            let tdata=this.state.opex_costs.map(v=>{
                return(
                    <tr>
                        <td><a href="" onClick={event => this.refund_single(event,v.id)} >Refund</a></td>
                        <td>{v.amount}</td>
                        <td>{v.allocate_date}</td>
                        <td>{v.fo}</td>
                        <td>{v.company_name}</td>
                        <td>{v.org}</td>
                        <td>{v.des}</td>
                        <td>{v.type}</td>
                    </tr>
                )
            })
            display=
                <div className="col-12">
                    <table className="table table-sm table-bordered">
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-2">Amount</th>
                            <th className="col-md-1">Allocate Date</th>
                            <th className="col-md-1">FO #</th>
                            <th className="col-md-2">Customer Name</th>
                            <th className="col-md-2">Origin</th>
                            <th className="col-md-2">Destination</th>
                            <th className="col-md-1">Type</th>
                        </tr>
                        {tdata}
                    </table>
                </div>
        }
        else  if(this.state.type==="fuel"){
            let tdata=this.state.opex_fuels.map(v=>{
                return(
                    <tr>
                        <td><a href="" onClick={event => this.refund_single(event,v.id)} >Refund</a></td>
                        <td>{v.amount}</td>
                        <td>{v.allocate_date}</td>
                        <td>{v.fo}</td>
                        <td>{v.company_name}</td>
                        <td>{v.org}</td>
                        <td>{v.des}</td>
                        <td>{v.station}</td>
                        <td>{v.rate}</td>
                        <td>{v.type}</td>
                    </tr>
                )
            })
            display=
                <div className="col-12">
                    <table className="table table-sm table-bordered">
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-2">Amount</th>
                            <th className="col-md-1">Allocate Date</th>
                            <th className="col-md-1">FO #</th>
                            <th className="col-md-2">Customer Name</th>
                            <th className="col-md-1">Origin</th>
                            <th className="col-md-1">Destination</th>
                            <th className="col-md-1">Station</th>
                            <th className="col-md-1">Rate</th>
                            <th className="col-md-1">Type</th>
                        </tr>
                        {tdata}
                    </table>
                </div>
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Refund Request</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label><strong>Truck No: </strong> {this.state.plate_no}</label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.get_cost} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Get Cost
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.get_fuel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Get Fuel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}>Cancel
                                        </button>
                                    </div>
                                </div>
                                {display}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let dis=null;
        if(this.state.type==="fuel")
            dis=<>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Station</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.data.station}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Rate</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.data.rate}/>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </>
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Refund Request</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.amount}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.allocate_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>FO No</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.fo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Customer Name</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.type}/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                {dis}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Refund Amount</label>
                                        <input type="text" className="form-control form-control-sm" name="amount"
                                               value={this.state.amount} onChange={this.inputmgt}
                                               placeholder="Refund Amount"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btnrequest} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Request
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:2,
                                                    })
                                                }} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="refund_to_company_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Refund To Company REQUEST</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Refund_to_company_req;
