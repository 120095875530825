import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Pre_assign_req extends Component {

    constructor() {
        super();
        this.state = {
            data:{},
            fuels: [],
            datas: [],
            filtered: [],

            vehicles: [],
            vehicle_selected: null,

            drivers: [],
            driver_selected: null,

            trailers: [],
            trailer_selected: null,

            fuel_rates: [],
            fuel_rate_selected: null,

            locations: [],
            location_selected: null,




            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',

        }
    }

    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected: vehicle_selected,
            driver_selected: vehicle_selected.driver_selected,
            trailer_selected: vehicle_selected.trailer_selected,
        })
    }
    handelchange_driver = (driver_selected) => {
        this.setState({
            driver_selected: driver_selected,
        })
    }
    handelchange_trailer = (trailer_selected) => {
        this.setState({
            trailer_selected: trailer_selected,
        })
    }
    handelchange_fuel_rate= (fuel_rate_selected) => {
        this.setState({
            fuel_rate_selected: fuel_rate_selected,
        })
    }
    handelchange_location= (location_selected) => {
        this.setState({
            location_selected: location_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_pre_assign_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    vehicles: res.data.vehicles,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                    fuel_rates: res.data.fuel_rates,
                    locations: res.data.locations,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            name: '',
            type: '',
            amount: '',
            payment_amount: '',
        })
    }
    btnshow = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_pre_assign_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 3,
                    data: res.data.data,
                    fuels: res.data.fuels,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_edit_pre_assign_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id:id,
                    iscreate:false,
                    vehicle_selected:res.data.vehicle_selected,
                    driver_selected:res.data.driver_selected,
                    trailer_selected:res.data.trailer_selected,
                    fright_no:res.data.fright_no,
                    allocated_date:res.data.allocated_date,
                    location_selected:res.data.location_selected,
                    cost_amount:res.data.cost_amount,
                    fuels:res.data.fuels,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            name: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.vehicle_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.driver_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select driver",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.location_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select Location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.pre_assign_req;
            let trailer_id=this.state.trailer_selected==null?null:this.state.trailer_selected.value;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id:this.state.vehicle_selected.value,
                driver_id:this.state.driver_selected.value,
                trailer_id:trailer_id,
                st_loc_id:this.state.location_selected.value,
                cost_amount:this.state.cost_amount,
                fright_no:this.state.fright_no,
                allocate_date:this.state.allocated_date,
                fuels:this.state.fuels,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnupdate = (event) => {
        event.preventDefault();
        if(this.state.vehicle_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.driver_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select driver",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.location_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select Location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.pre_assign_req_edit;
            let trailer_id=this.state.trailer_selected==null?null:this.state.trailer_selected.value;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                vehicle_id:this.state.vehicle_selected.value,
                driver_id:this.state.driver_selected.value,
                trailer_id:trailer_id,
                st_loc_id:this.state.location_selected.value,
                cost_amount:this.state.cost_amount,
                fright_no:this.state.fright_no,
                allocate_date:this.state.allocated_date,
                fuels:this.state.fuels,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    add_fuel = (event) => {
        event.preventDefault();
        if(this.state.fuel_rate_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select fuel rate",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (
            this.state.type!="birr"&&
            this.state.type!="coupon"&&
            this.state.type!="pad"
        ){
            Swal.fire({
                title: 'Error!',
                text: "Please select type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount==""){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(isNaN(this.state.amount)){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount<0){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let fuels=this.state.fuels;
            let id=fuels.length+1;
            fuels.push({
                id:id,
                station:this.state.fuel_rate_selected.label,
                fuel_rate_id:this.state.fuel_rate_selected.value,
                fuel_rate:this.state.fuel_rate_selected.fuel_rate,
                type:this.state.type,
                amount:this.state.amount,
            })
            this.setState({
                fuels: fuels,
                fuel_rate_selected: null,
                type: "-1",
                amount: "",
            })
        }
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td>
                    <a href="src/Componet/Operation2/Pre_assign_req" onClick={event => this.btnshow(event,value.id)}>Show</a> |
                    <a href="src/Componet/Operation2/Pre_assign_req" onClick={event => this.btnedit(event,value.id)}>Edit</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.allocated_date}</td>
                <td>{value.startloc}</td>
                <td>{value.total_money}</td>
                <td>{value.total_fuel}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="src/Componet/Operation2/Pre_assign_req" className="btn btn-xs btn-block" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btnnew}>New
                        Request</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-3">Truck No</th>
                            <th className="col-md-2">Driver Name</th>
                            <th className="col-md-2">Allocated Date</th>
                            <th className="col-md-2">Location</th>
                            <th className="col-md-1">Cost</th>
                            <th className="col-md-1">Fuel</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let header_1 = "Add Code"
        let btn_1 = <button className="btn btn-block  btn-sm" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btncreate}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Code"
            btn_1 = <button className="btn btn-block  btn-sm" style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}} onClick={this.btnupdate}> Edit </button>
        }
        let total_fuel_l=0;
        let total_fuel_etb=0;
        let tabledata=this.state.fuels.map(v=>{
            let etb=(v.fuel_rate*v.amount).toFixed(2)
            total_fuel_l=(total_fuel_l*1)+(v.amount*1);
            total_fuel_etb=(total_fuel_etb*1)+(etb*1);
            return (<tr>
                <td><a href="src/Componet/Operation2/Pre_assign_req" onClick={event => {
                    event.preventDefault();
                    let fuels=this.state.fuels.filter(v2=>{
                        console.log(v2.id)
                        console.log(v.id)
                        if(v2.id!=v.id)
                            return v2;
                    })
                    this.setState({
                        fuels:fuels,
                    })
                }}>X</a></td>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate</label>
                                        <Select
                                            value={this.state.vehicle_selected}
                                            onChange={this.handelchange_vehicle}
                                            options={this.state.vehicles}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.fright_no}
                                               name="fright_no"
                                               placeholder="Fright #"
                                               onChange={this.inputmgt}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.allocated_date}
                                               name="allocated_date"
                                               onChange={this.inputmgt}
                                               type="date"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.location_selected}
                                            onChange={this.handelchange_location}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Payment (ETB)</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.cost_amount}
                                               name="cost_amount"
                                               placeholder="Payment (ETB)"
                                               onChange={this.inputmgt}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Fuels</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Fuel Station</label>
                                                <Select
                                                    value={this.state.fuel_rate_selected}
                                                    onChange={this.handelchange_fuel_rate}
                                                    options={this.state.fuel_rates}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <select className="form-control form-control-sm"
                                                        value={this.state.type}
                                                        name="type"
                                                        onChange={this.inputmgt}>
                                                    <option value="-1">-- Select Type --</option>
                                                    <option value="birr">Birr</option>
                                                    <option value="coupon">Coupon</option>
                                                    <option value="pad">Pad</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Amount</label>
                                                <form onSubmit={this.add_fuel}>
                                                    <input className="form-control form-control-sm"
                                                           value={this.state.amount}
                                                           name="amount"
                                                           placeholder="Payment (ETB)"
                                                           onChange={this.inputmgt}
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <table id="report" className="table table-head-fixed table-sm">
                                                <tr>
                                                    <th className="col-md-1"></th>
                                                    <th className="col-md-4">Station</th>
                                                    <th className="col-md-2">Type</th>
                                                    <th className="col-md-2">Rate</th>
                                                    <th className="col-md-2">Fuel(L)</th>
                                                    <th className="col-md-2">Fuel(ETB)</th>
                                                </tr>
                                                {tabledata}
                                            </table>
                                        </div>
                                        <div className="col-12">
                                            <p><strong>Total Fuel (L):</strong> {total_fuel_l}</p>
                                            <p><strong>Total ETB (L):</strong> {total_fuel_etb}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btncancel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {

        let total_fuel_l=0;
        let total_fuel_etb=0;
        let tabledata=this.state.fuels.map(v=>{
            let etb=(v.fuel_rate*v.amount).toFixed(2)
            total_fuel_l=(total_fuel_l*1)+(v.amount*1);
            total_fuel_etb=(total_fuel_etb*1)+(etb*1);
            return (<tr>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Show Request</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.plate_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.driver_name}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.trailer_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.fright_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.allocate_date}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.st_loc}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Payment (ETB)</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.cost_etb}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQ BY</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.req_by}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQ AT</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.data.req_at}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <table id="report" className="table table-head-fixed table-sm">
                                        <tr>
                                            <th className="col-md-4">Station</th>
                                            <th className="col-md-3">Type</th>
                                            <th className="col-md-2">Rate</th>
                                            <th className="col-md-2">Fuel(L)</th>
                                            <th className="col-md-2">Fuel(ETB)</th>
                                        </tr>
                                        {tabledata}
                                    </table>
                                </div>
                                <div className="col-12">
                                    <div className="col-12">
                                        <p><strong>Total Fuel (L):</strong> {total_fuel_l}</p>
                                        <p><strong>Total ETB (L):</strong> {total_fuel_etb}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btncancel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="pre_assign_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Pre Assign REQUEST</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Pre_assign_req;
