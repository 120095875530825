import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Main_temp extends Component {

    constructor() {
        super();
        this.state = {
            datas:'',
            dt:'',
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.execute_main_temp;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            dt:this.state.dt,
            datas:this.state.datas,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        datas: res.data.ret_msg,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    render() {
        return (
            <>
                <Nav type="main_temp"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Maintenance Execute</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Date</label>
                                                        <input type="date" className="form-control form-control-sm" name="dt"
                                                               value={this.state.dt} onChange={this.inputmgt}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-8"></div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Data (Truck | Trailer | Current Location | Driver | ADMISSION DATE & TIME | WORK ORDER NUMBER | TYPE OF REPAIR | DATE & TIME REQUESTED | Remark)</label>
                                                        <textarea rows={10} className="form-control form-control-sm" name="datas"
                                                                  value={this.state.datas} onChange={this.inputmgt}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-sm"
                                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                                onClick={this.btncreate}> Execute
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Main_temp;
