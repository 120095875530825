import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Order_add_expence_set extends Component {

    constructor() {
        super();
        this.state = {
            orders: [],
            data: {},
            order_add_expences: [],

            wiz: 1,
            iscreate: false,
            id:-1,
            type:'',


        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_wiz_1_for_order_add_expence_set;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    orders: res.data.orders,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btnmanage = (event,value) => {
        event.preventDefault();
        this.setState({
            order_id: value.order_id,
            order_add_expence_id:value.id,
            data:value,
        }, () => {
            this.wiz_2_get_data();
        })
    }
    wiz_2_get_data(){
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_wiz_2_for_order_add_expence_set;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            order_id:this.state.order_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    order_add_expences: res.data.order_add_expences,
                    wiz:2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_3_get_data(msg){
        Swal.fire({
            title: msg,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.order_add_expence_set;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    order_add_expence_id: this.state.order_add_expence_id,
                }, {cancelToken: source.token}).then(res => {
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.wiz_2_get_data()
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    wiz_1 = () => {
        let tdata=this.state.orders.map(v=>{
            return (
                <tr>
                    <td><a href="" onClick={event => {this.btnmanage(event,v)}}>Manage </a></td>
                    <td>{v.label}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <table className="table table-sm table-bordered">
                            <tr>
                                <th className="col-md-3">Action</th>
                                <th className="col-md-9">Order</th>
                            </tr>
                            {tdata}
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata=this.state.order_add_expences.map(v=>{
            let a_link="";
            if(v.status=="APPROVED")
                a_link=<>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            is_create:false,
                            wiz:3,
                            reason:v.reason,
                            amount:v.amount,
                            order_add_expence_id:v.id,
                        })
                    }}>Manage</a><br/>
                </>
            return (
                <tr>
                    <td>{a_link}</td>
                    <td>{v.label}</td>
                    <td>{v.reason}</td>
                    <td>{v.amount}</td>
                    <td>{v.req_by}</td>
                    <td>{v.app_by}</td>
                    <td>{v.rej_by}</td>
                    <td>{v.set_by}</td>
                    <td>{v.status}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Manage Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-sm table-bordered">
                                        <tr>
                                            <th className="col-md-1">Action</th>
                                            <th className="col-md-2">Order</th>
                                            <th className="col-md-2">Reason</th>
                                            <th className="col-md-2">Amount</th>
                                            <th className="col-md-1">Req By</th>
                                            <th className="col-md-1">App By</th>
                                            <th className="col-md-1">Rej By</th>
                                            <th className="col-md-1">Settle By</th>
                                            <th className="col-md-1">Status</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Previous
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Settlement</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.label}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Type</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.company_type}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate (ETB)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Reason</label>
                                        <textarea rows={5} className="form-control form-control-sm"
                                                  value={this.state.reason}
                                                  name="reason"
                                                  onChange={this.inputmgt}
                                                  disabled={true}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.amount}
                                               name="amount"
                                               placeholder="AMOUNT"
                                               onChange={this.inputmgt}
                                               disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.wiz_3_get_data("Do you want to settle this payment request?")
                                                }}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Settle
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:2,
                                                    })
                                                }}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="order_add_expence_set"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Order Expense SETTLEMENT </h3>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_add_expence_set;
