import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Payment_settlement extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',


            wiz: 1,
            iscreate: false,
            id: -1,
            name: '',
            data: {},
            settle_at: new Date().toISOString().slice(0, 10),
            remark: '',
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_payment_not_settle_for_payment_settlement;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btn_settle = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to settle this payment? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.payment_settlement;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: this.state.id,
                    settle_at: this.state.settle_at,
                    remark: this.state.remark,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            }, () => this.fetchdata())
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {

            return (<tr>
                <td>
                    <a href="" onClick={event =>{
                        event.preventDefault();
                        this.setState({
                            wiz:2,
                            data:value,
                            id:value.id,
                        })
                    } }>Settle</a>
                </td>
                <td>{value.client_name}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.cargo}</td>
                <td>{value.rate}</td>
                <td>{value.fright_no}</td>
                <td>{value.plate_no}</td>
                <td>{value.volume}</td>
                <td>{value.unload_volume}</td>
                <td>{value.payment}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-1">Origin</th>
                            <th className="col-md-1">Destination</th>
                            <th className="col-md-1">Cargo</th>
                            <th className="col-md-1">Transport Rate</th>
                            <th className="col-md-1">Freight #</th>
                            <th className="col-md-1">Plate #</th>
                            <th className="col-md-1">Loaded Weight (q)</th>
                            <th className="col-md-1">Unloaded Weight (q)</th>
                            <th className="col-md-1">Payment (ETB)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Payment Settlement</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.client_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.org} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.des} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.cargo} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.rate} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Freight #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.fright_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>	Plate #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Loaded Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.volume} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Unloaded Weight (q)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.unload_volume} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Payment (ETB)</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.data.payment} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Settle At</label>
                                        <input type="date" className="form-control form-control-sm" name="settle_at"
                                               value={this.state.settle_at} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="remark"
                                               value={this.state.remark} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btn_settle}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}> Settle
                                        </button>
                                    </div>
                                </div>x
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="payment_settlement"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Payment Settlement</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Payment_settlement;
