import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import Select from "react-select";
import {Modal} from "react-bootstrap";


let source = axios.CancelToken.source();

class Opp_manage extends Component {

    constructor() {
        super();
        this.state = {
            commands:'',
            out_msg:'',
            is_exe:false,
            retdatas:[],
            is_chain:false,
            chain_value:-1,
            cushow:false,
            dt: new Date().toISOString().slice(0, 10),

            command_lists: [],
            actions: [],
            cats: [],
            trucks: [],
            dates: [],
            command_list_selected: null,
            modal_type:1


        }
    }

    handelchange_command_list = (command_list_selected) => {
        let commands=this.state.commands;
        this.setState({
            cushow:false,
            commands:commands+command_list_selected.value+";\n",
            command_list_selected:command_list_selected,
        },()=>{
            const element = document.getElementById('txt_commands');
            element.focus();
        })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btn_execute = (event) => {
        event.preventDefault()
        if(!this.state.is_exe){
            this.setState({
                is_exe:true,
                out_msg:'Executing....',
            },()=>{
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.opp_manage;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    eid:this.state.eid,
                    commands:this.state.commands
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        this.setState({
                            is_exe:false,
                        },()=>{
                            Swal.fire({
                                title: 'Error!',
                                text: res.data.msg,
                                icon: 'error',
                                confirmButtonText: 'Okay'
                            })
                        })
                    } else {
                        if(res.data.is_chain) {
                            this.setState({
                                eid:res.data.eid,
                                out_msg:res.data.out_msg,
                                retdatas:res.data.retdatas,
                                is_chain:res.data.is_chain,
                                chain_value:res.data.chain_value,
                                trucks:res.data.trucks,
                                dates:res.data.dates,
                            },()=>{
                                if (res.data.chain_value === 1)
                                    this.follow_up_report();
                            });
                        }
                        else{
                            this.setState({
                                is_exe:false,
                                eid:res.data.eid,
                                out_msg:res.data.out_msg,
                                retdatas:res.data.retdatas,
                                is_chain:res.data.is_chain,
                                chain_value:res.data.chain_value,
                            },()=>{
                                Swal.fire({
                                    title: 'Success!',
                                    text: res.data.msg,
                                    icon: 'success',
                                    confirmButtonText: 'Okay'
                                })
                            })
                        }


                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            })
        }

    }
    follow_up_report = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.follow_up_report;
        let len = this.state.retdatas.length;
        let assign_datas = []
        let order_datas = []
        for (let i = 0; i < len; i++) {
            await this.setState({
                out_msg: this.state.out_msg + "\n " +
                    "Executing " + (i + 1) + " data of " +
                    (len) +
                    "\n" +
                    this.state.retdatas[i].plate_no
            })
            await axios.post(
                baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    data: this.state.retdatas[i],
                    eid: this.state.eid,
                }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    assign_datas.push({
                        id: res.data.assign_data.id,
                        plate_no_full: res.data.assign_data.plate_no_full,
                        start_loc: res.data.assign_data.start_loc,
                        start_lat: res.data.assign_data.start_lat,
                        start_lon: res.data.assign_data.start_lon,
                        start_distance: res.data.assign_data.start_distance,
                        end_loc: res.data.assign_data.end_loc,
                        end_lat: res.data.assign_data.end_lat,
                        end_lon: res.data.assign_data.end_lon,
                        end_distance: res.data.assign_data.end_distance,
                        trip_start_time: res.data.assign_data.trip_start_time,
                        trip_to_load_time: res.data.assign_data.trip_to_load_time,
                        trip_to_dep_time: res.data.assign_data.trip_to_dep_time,
                        trip_to_unload_time: res.data.assign_data.trip_to_unload_time,
                        trip_to_unload_dep_time: res.data.assign_data.trip_to_unload_dep_time,
                        trip_to_stop_time: res.data.assign_data.trip_to_stop_time,
                        is_gps_working: res.data.assign_data.is_gps_working,
                        allocated_date: res.data.assign_data.allocated_date,
                        sta: res.data.assign_data.sta,
                        plate_no: res.data.assign_data.plate_no,
                    })
                    order_datas.push({
                        id: res.data.order_data.id,
                        label: res.data.order_data.label,
                        org_lat: res.data.order_data.org_lat,
                        org_lon: res.data.order_data.org_lon,
                        org_distance: res.data.order_data.org_distance,
                        des_lat: res.data.order_data.des_lat,
                        des_lon: res.data.order_data.des_lon,
                        des_distance: res.data.order_data.des_distance,
                    })
                    this.setState(
                        {
                            eid: res.data.eid,
                            out_msg: this.state.out_msg + "\n" + res.data.out_msg,
                        }
                    )
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
        Swal.fire({
            title: 'Success!',
            text: "Executed successfully",
            icon: 'success',
            confirmButtonText: 'Okay'
        }).then(v => {
            let out_msg = this.state.out_msg
            out_msg = out_msg +
                "\nASSIGN_OWN_ID\t" +
                "TRUCK_NO\t" +
                "ORDER_LABEL\t" +
                "START_LOCATION\t" +
                "START_LAT\t" +
                "START_LON\t" +
                "START_DISTANCE\t" +
                "END_LOCATION\t" +
                "END_LAT\t" +
                "END_LON\t" +
                "END_DISTANCE\t" +
                "TRIP_START_TIME\t" +
                "TRIP_TO_LOAD_TIME\t" +
                "TRIP_TO_DEP_TIME\t" +
                "TRIP_TO_UNLOAD_TIME\t" +
                "TRIP_TO_UNLOAD_DEP_TIME\t" +
                "TRIP_TO_STOP_TIME\t" +
                "GPS_STATUS\t" +
                "ALLOCATED_DATE\t" +
                "STATUS\t" +
                "PLATE_NO"
            for (let o = 0; o < assign_datas.length; o++) {
                out_msg = out_msg + "\n";
                out_msg = out_msg +
                    assign_datas[o].id + "\t" +
                    assign_datas[o].plate_no_full + "\t" +
                    assign_datas[o].label + "\t" +
                    assign_datas[o].start_loc + "\t" +
                    assign_datas[o].start_lat + "\t" +
                    assign_datas[o].start_lon + "\t" +
                    assign_datas[o].start_distance + "\t" +
                    assign_datas[o].end_loc + "\t" +
                    assign_datas[o].end_lat + "\t" +
                    assign_datas[o].end_lon + "\t" +
                    assign_datas[o].end_distance + "\t" +
                    assign_datas[o].trip_start_time + "\t" +
                    assign_datas[o].trip_to_load_time + "\t" +
                    assign_datas[o].trip_to_dep_time + "\t" +
                    assign_datas[o].trip_to_unload_time + "\t" +
                    assign_datas[o].trip_to_unload_dep_time + "\t" +
                    assign_datas[o].trip_to_stop_time + "\t" +
                    assign_datas[o].is_gps_working + "\t" +
                    assign_datas[o].allocated_date + "\t" +
                    assign_datas[o].sta + "\t" +
                    assign_datas[o].plate_no
            }
            out_msg = out_msg +
                "\nORDER_ID\t" +
                "ORDER_LABEL\t" +
                "ORIGIN_LAT\t" +
                "ORIGIN_LON\t" +
                "ORIGIN_DISTANCE\t" +
                "DESTINATION_LAT\t" +
                "DESTINATION_LON\t" +
                "DESTINATION_DISTANCE"
            for (let o = 0; o < order_datas.length; o++) {
                out_msg = out_msg + "\n";
                out_msg = out_msg +
                    order_datas[o].id + "\t" +
                    order_datas[o].label + "\t" +
                    order_datas[o].org_lat + "\t" +
                    order_datas[o].org_lon + "\t" +
                    order_datas[o].org_distance + "\t" +
                    order_datas[o].des_lat + "\t" +
                    order_datas[o].des_lon + "\t" +
                    order_datas[o].des_distance
            }
            out_msg = out_msg + "\nExecuting finished"
            this.setState({
                is_exe: false,
                out_msg: out_msg,
            })
        })
    }
    msg_report = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.opp_msg;
        let len=this.state.retdatas.length;
        for (let i = 0; i < len; i++) {
            this.setState({
                out_msg: this.state.out_msg+"\nExecuting "+(i+1)+" data of "+(len),
            })
            await axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                data: this.state.retdatas[i],
                eid: this.state.eid,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState(
                        {
                            eid:res.data.eid,
                            out_msg: this.state.out_msg+"\n"+res.data.out_msg,
                        }
                    )
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
        this.setState({
            is_exe:false,
            out_msg: this.state.out_msg+"\nExecuting finished",
        })
    }

    modalcu = () => {

        let displayed=null;
        if(this.state.modal_type===1)
            displayed= <div className="row">
                <div className="col-12 px-3">
                    <div className="form-group">
                        <label>Date</label>
                        <input  className="form-control" type="date" name="dt"
                                value={this.state.dt} onChange={this.inputmgt}/>
                    </div>
                </div>
                <div className="col-md-12 px-3">
                    <div className="form-group d-flex justify-content-around">
                        <button className="btn btn-outline-primary" onClick={this.submitcu}> Select</button>
                        <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel</button>
                    </div>
                </div>
            </div>;
        else if(this.state.modal_type===2)
        {
            displayed= <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label>Command List</label>
                        <Select
                            value={this.state.command_list_selected}
                            onChange={this.handelchange_command_list}
                            options={this.state.command_lists}
                        />
                    </div>
                </div>
            </div>
        }
        return (<Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header>Date Selector</Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    {displayed}
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    hidecu = () => {
        this.setState({
            cushow: false,
        })
    }

    submitcu = (event) => {
        event.preventDefault()
        let commands = this.state.commands + this.state.dt + ";";
        this.setState({
            commands: commands,
            cushow: false,
        },()=>{
            const element = document.getElementById('txt_commands');
            element.focus();
        })
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_opp;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    actions: res.data.actions,
                    cats: res.data.cats,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    render() {
        return (
            <>
                <Nav type="opp_manage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Console Management</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <label>Input
                                                        <a href="" onClick={event => {
                                                            event.preventDefault()
                                                            this.setState({
                                                                modal_type:1,
                                                                cushow: true,
                                                            })
                                                        }}> Insert Date
                                                        </a> |

                                                        <a href="" onClick={event => {
                                                            event.preventDefault()
                                                            this.setState({
                                                                command_list_selected:null,
                                                                command_lists:this.state.actions,
                                                                modal_type:2,
                                                                cushow: true,
                                                            })
                                                        }}>Action List
                                                        </a>|
                                                        <a href="" onClick={event => {
                                                            event.preventDefault()
                                                            this.setState({
                                                                command_list_selected:null,
                                                                command_lists:this.state.cats,
                                                                modal_type:2,
                                                                cushow: true,
                                                            })
                                                        }}>Category List
                                                        </a>|
                                                        <a href="" onClick={event => {
                                                            event.preventDefault()
                                                            this.setState({
                                                                commands:'',
                                                            })
                                                        }}>Clear
                                                        </a>
                                                    </label>
                                                    <div className="form-group">
                                                        <textarea id="txt_commands" rows={15} className="form-control"
                                                                  name="commands"
                                                                  value={this.state.commands} onChange={this.inputmgt}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Out put</label>
                                                    <div className="form-group">
                                                        <textarea rows={15} className="form-control"
                                                                  name="out_msg"
                                                                  value={this.state.out_msg} onChange={this.inputmgt}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <button className="btn btn-block"
                                                                style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}
                                                                onClick={this.btn_execute}>Execute</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Opp_manage;
