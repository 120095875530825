import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Assign_vehicle extends Component {

    constructor() {
        super();
        this.state = {
            cost_fuel_data:{},
            order_data:{},
            datas: [],
            data: {},
            filtered: [],
            fuels: [],
            fuels_2: [],
            wiz:1,

            drivers: [],
            driver_selected: null,

            trailers: [],
            trailer_selected: null,

            fuel_rates: [],
            fuel_rate_selected: null,
            fuel_rate_selected_2: null,

            orders: [],
            order_selected: null,

            locations: [],
            prv_orders: [],

            st_selected: null,
            ed_selected: null,

            is_exe:false,
            btn_text:'Assign Vehicle',
        }
    }

    handelchange_driver = (driver_selected) => {
        this.setState({
            driver_selected: driver_selected,
        })
    }
    handelchange_trailer = (trailer_selected) => {
        this.setState({
            trailer_selected: trailer_selected,
        })
    }
    handelchange_fuel_rate= (fuel_rate_selected) => {
        this.setState({
            fuel_rate_selected: fuel_rate_selected,
        })
    }
    handelchange_fuel_rate_2= (fuel_rate_selected_2) => {
        this.setState({
            fuel_rate_selected_2: fuel_rate_selected_2,
        })
    }

    handelchange_st_loc= (st_selected) => {
        this.setState({
            st_selected: st_selected,
        },()=>{
            if(
                this.state.st_selected!=null&&
                this.state.ed_selected!=null&&
                this.state.order_selected!=null
            ){
                this.getroute();
            }
        })
    }
    handelchange_ed_loc= (ed_selected) => {
        this.setState({
            ed_selected: ed_selected,
        },()=>{
            if(
                this.state.st_selected!=null&&
                this.state.ed_selected!=null&&
                this.state.order_selected!=null
            ){
                this.getroute();
            }
        })
    }
    handelchange_order= (order_selected) => {
        this.setState({
            order_selected: order_selected,
            order_data: order_selected,
        },()=>{
            if(
                this.state.st_selected!=null&&
                this.state.ed_selected!=null&&
                this.state.order_selected!=null
            ){
                this.getroute();
            }
        })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_assign_vehicle;
        let pre_assign_id = new URLSearchParams(this.props.location.search).get("pre_assign_id");
        let is_set=true;
        if(pre_assign_id===null)
        {
            pre_assign_id=-1;
            is_set=false;
        }

        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            pre_assign_id:pre_assign_id,
            is_set:is_set,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(!res.data.is_pre_allocate_ok) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        window.open(myconfig.redirecttoassign_vehicle, "_self");
                    })
                }
                else{
                    let wiz=1;
                    if(is_set)
                        wiz=2;
                    this.setState({
                        datas: res.data.vehicles,
                        filtered: res.data.vehicles,
                        drivers: res.data.drivers,
                        trailers: res.data.trailers,
                        fuel_rates: res.data.fuel_rates,
                        locations: res.data.locations,
                        orders: res.data.orders,
                        id: res.data.id,
                        plate_no: res.data.plate_no,
                        driver_selected: res.data.driver_selected,
                        trailer_selected: res.data.trailer_selected,
                        st_selected: res.data.st_selected,
                        fright_no: res.data.fright_no,
                        allocated_date: res.data.allocated_date,
                        wiz:wiz,
                        pre_assign_id:pre_assign_id,
                        is_set:is_set,
                    })
                }

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.label.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btnmanage = (event,id) => {
        event.preventDefault();
        let vehicle=this.state.datas.filter(v=>{
            if(v.value===id)
                return v;
        })
        if(vehicle.length>0) {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_prv_history_for_assign_vehicle;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        prv_orders:res.data.prv_orders,
                        wiz: 2,
                        id: vehicle[0].value,
                        plate_no: vehicle[0].label,
                        driver_selected: vehicle[0].driver_selected,
                        trailer_selected: vehicle[0].trailer_selected,
                        fuel_rate_selected: null,
                        st_selected: null,
                        ed_selected: null,
                        actual_weight:400,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });

        }
        else{
            Swal.fire({
                title: 'Error!',
                text: "No vehicle is found",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
    }
    getroute = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_route_for_assign_vehicle;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            st_selected:this.state.st_selected.value,
            ed_selected:this.state.ed_selected.value,
            order_selected:this.state.order_selected.value,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    cost_fuel_data: res.data.cost_fuel_data,
                    fuels: res.data.fuels,
                    fuels_2: res.data.fuels,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            name: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(!this.state.is_exe) {
            if (this.state.driver_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Please select driver",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else if (this.state.st_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Please select start location",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else if (this.state.order_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Please select order",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else if (this.state.ed_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Please select end location",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    is_exe: true,
                    btn_text: 'Executing...',
                }, () => {
                    let redirecttologin = myconfig.redirecttologin;
                    let baseurl = myconfig.assign_vehicle;
                    let trailer_selected = this.state.trailer_selected == null ? null : this.state.trailer_selected.value;
                    axios.post(baseurl, {
                        sid: window.localStorage.getItem("logmgtsys_sid"),
                        id: this.state.id,
                        driver_selected: this.state.driver_selected.value,
                        trailer_selected: trailer_selected,
                        fright_no: this.state.fright_no,
                        allocated_date: this.state.allocated_date,
                        st_selected: this.state.st_selected.value,
                        order_selected: this.state.order_selected.value,
                        ed_selected: this.state.ed_selected.value,
                        actual_weight: this.state.actual_weight,
                        fuels: this.state.fuels,
                        fuels_2: this.state.fuels_2,
                        pre_assign_id: this.state.pre_assign_id,
                    }, {cancelToken: source.token}).then(res => {
                        if (res.data.auth == false) {
                            window.localStorage.setItem("logmgtsys_sid", null);
                            window.localStorage.setItem("logmgtsys_username", null);
                            window.localStorage.setItem("logmgtsys_name", null);
                            var win = window.open(redirecttologin, "_self");
                        } else if (res.data.success == false) {
                            Swal.fire({
                                title: 'Error!',
                                text: res.data.msg,
                                icon: 'error',
                                confirmButtonText: 'Okay'
                            })
                            this.setState({
                                is_exe: false,
                                btn_text: 'Assign Vehicle',
                            })
                        } else {
                            Swal.fire({
                                title: 'Success!',
                                text: res.data.msg,
                                icon: 'success',
                                confirmButtonText: 'Okay'
                            }).then(v => {
                                let url = myconfig.redirecttofright_document + "?fright_no=" + res.data.fright_no;
                                window.open(url, "_blank");
                                window.open(myconfig.redirecttoassign_vehicle, "_self");
                            })
                        }
                    }).catch((e) => {
                        console.log(e.message);
                    });
                })
            }
        }
        else
        {
            Swal.fire({
                title: 'Information!',
                text: "Other operation in being executing. Please wait until it finished",
                icon: 'info',
                confirmButtonText: 'Okay'
            })
        }
    }
    add_fuel = (event) =>  {
        event.preventDefault();
        if(this.state.fuel_rate_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select fuel rate",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (
            this.state.type!="birr"&&
            this.state.type!="coupon"&&
            this.state.type!="pad"
        ){
            Swal.fire({
                title: 'Error!',
                text: "Please select type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount==""){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(isNaN(this.state.amount)){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount<0){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let fuels=this.state.fuels;
            let id=fuels.length+1;
            fuels.push({
                id:id,
                station:this.state.fuel_rate_selected.label,
                fuel_rate_id:this.state.fuel_rate_selected.value,
                fuel_rate:(this.state.fuel_rate_selected.fuel_rate*1).toFixed(2),
                type:this.state.type,
                amount:(this.state.amount*1).toFixed(2),
            })
            this.setState({
                fuels: fuels,
                fuel_rate_selected: null,
                type: "-1",
                amount: "",
            })
        }
    }
    add_fuel_2 = (event) =>  {
        event.preventDefault();
        if(this.state.fuel_rate_selected_2==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select fuel rate",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (
            this.state.type_2!="birr"&&
            this.state.type_2!="coupon"&&
            this.state.type_2!="pad"
        ){
            Swal.fire({
                title: 'Error!',
                text: "Please select type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount_2==""){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(isNaN(this.state.amount_2)){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.amount_2<0){
            Swal.fire({
                title: 'Error!',
                text: "Amount must be greater than or equal to zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let fuels_2=this.state.fuels_2;
            let id=fuels_2.length+1;
            fuels_2.push({
                id:id,
                station:this.state.fuel_rate_selected_2.label,
                fuel_rate_id:this.state.fuel_rate_selected_2.value,
                fuel_rate:(this.state.fuel_rate_selected_2.fuel_rate*1).toFixed(2),
                type:this.state.type_2,
                amount:(this.state.amount_2*1).toFixed(2),
            })
            this.setState({
                fuels_2: fuels_2,
                fuel_rate_selected_2: null,
                type_2: "-1",
                amount_2: "",
            })
        }
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnmanage(event,value.value)}>Manage</a>
                </td>
                <td>{value.label}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-2">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-8">Truck No</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let total_fuel_l=0;
        let total_fuel_etb=0;
        let tabledata=this.state.fuels.map(v=>{
            let etb=(v.fuel_rate*v.amount).toFixed(2)
            total_fuel_l=((total_fuel_l*1)+(v.amount*1)).toFixed(2);
            total_fuel_etb=((total_fuel_etb*1)+(etb*1)).toFixed(2);
            return (<tr>
                <td><a href="src/Componet/Operation2/Pre_assign_req" onClick={event => {
                    event.preventDefault();
                    let fuels=this.state.fuels.filter(v2=>{
                        console.log(v2.id)
                        console.log(v.id)
                        if(v2.id!=v.id)
                            return v2;
                    })
                    this.setState({
                        fuels:fuels,
                    })
                }}>X</a></td>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })

        let total_fuel_l_2=0;
        let total_fuel_etb_2=0;
        let tabledata_2=this.state.fuels_2.map(v=>{
            let etb=(v.fuel_rate*v.amount).toFixed(2)
            total_fuel_l_2=((total_fuel_l_2*1)+(v.amount*1)).toFixed(2);
            total_fuel_etb_2=((total_fuel_etb_2*1)+(etb*1)).toFixed(2);
            return (<tr>
                <td><a href="src/Componet/Operation2/Pre_assign_req" onClick={event => {
                    event.preventDefault();
                    let fuels_2=this.state.fuels_2.filter(v2=>{
                        if(v2.id!=v.id)
                            return v2;
                    })
                    this.setState({
                        fuels_2:fuels_2,
                    })
                }}>X</a></td>
                <td>{v.station}</td>
                <td>{v.type}</td>
                <td>{v.fuel_rate}</td>
                <td>{v.amount}</td>
                <td>{etb}</td>
            </tr>)
        })

        let tdata=this.state.prv_orders.map(v=>{
            return (<tr>
                <td>
                    <a href="" onClick={event =>{
                        event.preventDefault();
                        let data=this.state.prv_orders.filter(v2=>{
                            if(v2.assign_own_id===v.assign_own_id)
                                return v2;
                        })
                        if(data.length>0)
                        {
                            this.setState({
                                wiz:3,
                                data:data[0]
                            })
                        }
                    }}>{v.order_label}</a></td>
                <td>{v.allocated_date}</td>
                <td>{v.driver_name}</td>
                <td>{v.trailer_no}</td>
                <td>{v.status}</td>
            </tr>)
        })
        let url=myconfig.redirecttoassign_history+"?vehicle_id="+this.state.id
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Assign Vehicle</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <a href={url} target="_blank">See Full History </a>
                                    <table className="table table-bordered">
                                        <tr>
                                            <th className="col-md-4">Order</th>
                                            <th className="col-md-2">Allocated Date</th>
                                            <th className="col-md-2">Driver Name</th>
                                            <th className="col-md-2">Trailer No</th>
                                            <th className="col-md-2">Status</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate</label>
                                        <input
                                            disabled={true}
                                            className="form-control form-control-sm"
                                            value={this.state.plate_no}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.allocated_date}
                                               name="allocated_date"
                                               onChange={this.inputmgt}
                                               type="date"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.st_selected}
                                            onChange={this.handelchange_st_loc}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End Location</label>
                                        <Select
                                            value={this.state.ed_selected}
                                            onChange={this.handelchange_ed_loc}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                                  onChange={this.inputmgt} value={this.state.order_data.des_good ?? "NA"}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Types of Load</label>
                                        <input disabled={true} className="form-control form-control-sm text-capitalize"
                                               value={this.state.order_data.tol}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Left Weight (q) to load</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.avl_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Actual Weight (q)</label>
                                        <input type="text" className="form-control form-control-sm" name="actual_weight"
                                               value={this.state.actual_weight} onChange={this.inputmgt}
                                               placeholder="Weight"/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-3">
                                    <p><strong>Previous Fuel (L): </strong>{this.state.cost_fuel_data.prv_fuel}</p>
                                    <p><strong>Current Fuel (L): </strong>{this.state.cost_fuel_data.cur_fuel}</p>
                                    <p><strong>Fuel (L): </strong>{this.state.cost_fuel_data.fuel}</p>
                                </div>
                                <div className="col-md-3">
                                    <p><strong>Previous Cost (ETB): </strong>{this.state.cost_fuel_data.prv_cost}</p>
                                    <p><strong>Current Cost (ETB): </strong>{this.state.cost_fuel_data.cur_cost}</p>
                                    <p><strong>Cost (ETB): </strong>{this.state.cost_fuel_data.cost}</p>
                                </div>
                                <div className="col-md-3">
                                    <p><strong>Pre-diem Empty (ETB): </strong>{this.state.cost_fuel_data.pre_diem_empty}</p>
                                    <p><strong>Handling Fee Empty (ETB): </strong>{this.state.cost_fuel_data.handling_empty}</p>
                                    <p><strong>Other Fee Empty (ETB): </strong>{this.state.cost_fuel_data.other_empty}</p>
                                    <p><strong>Pre-diem Loaded (ETB): </strong>{this.state.cost_fuel_data.pre_diem_loaded}</p>
                                    <p><strong>Handling Fee Loaded (ETB): </strong>{this.state.cost_fuel_data.handling_loaded}</p>
                                    <p><strong>Other Fee Loaded (ETB): </strong>{this.state.cost_fuel_data.other_loaded}</p>
                                    <p><strong>Total Fee (ETB): </strong>{this.state.cost_fuel_data.total_fee}</p>
                                </div>
                                <div className="col-md-3">
                                    <p><strong>Fuel Empty (L): </strong>{this.state.cost_fuel_data.bud_fuel_empty}</p>
                                    <p><strong>Loaded Fuel (L): </strong>{this.state.cost_fuel_data.bud_fuel_load}</p>
                                    <p><strong>Total Fuel (L): </strong>{this.state.cost_fuel_data.bud_fuel}</p>
                                </div>

                                <div className="col-md-12">
                                    <div className="row">
                                        <hr/>
                                        <div className="col-12">
                                            <h5>Fuels Actual</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Fuel Station</label>
                                                <Select
                                                    value={this.state.fuel_rate_selected}
                                                    onChange={this.handelchange_fuel_rate}
                                                    options={this.state.fuel_rates}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <select className="form-control form-control-sm"
                                                        value={this.state.type}
                                                        name="type"
                                                        onChange={this.inputmgt}>
                                                    <option value="-1">-- Select Type --</option>
                                                    <option value="birr">Birr</option>
                                                    <option value="coupon">Coupon</option>
                                                    <option value="pad">Pad</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Amount (L)</label>
                                                <form onSubmit={this.add_fuel}>
                                                    <input className="form-control form-control-sm"
                                                           value={this.state.amount}
                                                           name="amount"
                                                           placeholder="Amount (L)"
                                                           onChange={this.inputmgt}
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <table id="report" className="table table-head-fixed table-sm">
                                                <tr>
                                                    <th className="col-md-1"></th>
                                                    <th className="col-md-4">Station</th>
                                                    <th className="col-md-2">Type</th>
                                                    <th className="col-md-2">Rate</th>
                                                    <th className="col-md-2">Fuel(L)</th>
                                                    <th className="col-md-2">Fuel(ETB)</th>
                                                </tr>
                                                {tabledata}
                                            </table>
                                        </div>
                                        <div className="col-12">
                                            <p><strong>Total Fuel (L):</strong> {total_fuel_l}</p>
                                            <p><strong>Total ETB (L):</strong> {total_fuel_etb}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 pt-3">
                                    <hr/>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Fuels Budgeted</h5><a href="" onClick={
                                            event => {
                                                event.preventDefault()
                                                let temp=this.state.fuels;
                                                let temp2=[];
                                                let len=temp.length
                                                for(let i=0; i<len;i++) {
                                                    temp2.push({
                                                        id: temp[i].id,
                                                        station: temp[i].station,
                                                        fuel_rate_id: temp[i].fuel_rate_id,
                                                        fuel_rate: temp[i].fuel_rate,
                                                        type: temp[i].type,
                                                        amount: temp[i].amount,
                                                    })
                                                }
                                                this.setState({
                                                    fuels_2:temp2,
                                                })
                                            }}><p>(Same as actual)</p></a>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Fuel Station</label>
                                                <Select
                                                    value={this.state.fuel_rate_selected_2}
                                                    onChange={this.handelchange_fuel_rate_2}
                                                    options={this.state.fuel_rates}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Type</label>
                                                <select className="form-control form-control-sm"
                                                        value={this.state.type_2}
                                                        name="type_2"
                                                        onChange={this.inputmgt}>
                                                    <option value="-1">-- Select Type --</option>
                                                    <option value="birr">Birr</option>
                                                    <option value="coupon">Coupon</option>
                                                    <option value="pad">Pad</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Amount (L)</label>
                                                <form onSubmit={this.add_fuel_2}>
                                                    <input className="form-control form-control-sm"
                                                           value={this.state.amount_2}
                                                           name="amount_2"
                                                           placeholder="Amount (L)"
                                                           onChange={this.inputmgt}
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <table id="report" className="table table-head-fixed table-sm">
                                                <tr>
                                                    <th className="col-md-1"></th>
                                                    <th className="col-md-4">Station</th>
                                                    <th className="col-md-2">Type</th>
                                                    <th className="col-md-2">Rate</th>
                                                    <th className="col-md-2">Fuel(L)</th>
                                                    <th className="col-md-2">Fuel(ETB)</th>
                                                </tr>
                                                {tabledata_2}
                                            </table>
                                        </div>
                                        <div className="col-12">
                                            <p><strong>Total Fuel (L):</strong> {total_fuel_l_2}</p>
                                            <p><strong>Total ETB (L):</strong> {total_fuel_etb_2}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btncreate} style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}>{this.state.btn_text}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={this.btncancel} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header" style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Detail Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck No.</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer No</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.order_label}/>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.allocated_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Loaded Weight</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.volume}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Status</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.status}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_start_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Arrival @ Load</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_load_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Departure From Load</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_dep_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Arrival @ Offload</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_unload_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Departure From Offload</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_unload_dep_time}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.trip_to_stop_time}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:2
                                                    })
                                                }} style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="assign_vehicle"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Assign Vehicle</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Assign_vehicle;
