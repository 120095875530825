import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Opex_cost_refund extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            datas: [],
            data: {},
            display_detail: false,
            wiz: 1,
            re_amount: '',
            re_remark: '',
            re_rec_by: '',
            re_rec_at: '',
            amount: '',
            payment_type: '',
            req_by: '',
            app_by: '',
            given_by: '',
        }
    }
    componentDidMount() {

    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    btnget_report = (event) => {
        event.preventDefault();
        this.getreport();
    }
    getreport = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_fright_for_opex_cost_refund;
        this.setState({
            data: {},
            display_detail: false,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no: this.state.fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    id: res.data.data.id,
                    data: res.data.data,
                    datas: res.data.datas,
                    display_detail: true,

                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.opex_cost_refund;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            re_amount:this.state.re_amount,
            re_remark:this.state.re_remark,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    tabledata=()=> {
        let retdata = this.state.reports.map(value => {
            return (<tr>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
                <td>{value.client}</td>
                <td>{value.start_at}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.end_at}</td>
                <td>{value.trip_start_at}</td>
                <td>{value.arrive_to_load}</td>
                <td>{value.s_t_start_to_org}</td>
                <td>{value.a_t_start_to_org}</td>
                <td>{value.sa_t_start_to_org}</td>
                <td>{value.loading_start_at}</td>
                <td>{value.loading_end_at}</td>
                <td>{value.s_for_loading}</td>
                <td>{value.a_for_loading}</td>
                <td>{value.sa_for_loading}</td>
                <td>{value.trip_start_to_offload}</td>
                <td>{value.arrive_to_offload}</td>
                <td>{value.s_t_org_to_des}</td>
                <td>{value.a_t_org_to_des}</td>
                <td>{value.sa_t_org_to_des}</td>
                <td>{value.offloading_start_at}</td>
                <td>{value.offloading_end_at}</td>
                <td>{value.s_for_offloading}</td>
                <td>{value.a_for_offloading}</td>
                <td>{value.sa_for_offloading}</td>
                <td>{value.trip_start_to_stop}</td>
                <td>{value.arrive_to_stop}</td>
                <td>{value.s_t_des_to_stop}</td>
                <td>{value.a_t_des_to_stop}</td>
                <td>{value.sa_t_des_to_stop}</td>
                <td>{value.s_total_tat}</td>
                <td>{value.a_total_tat}</td>
                <td>{value.sa_total_tat}</td>
            </tr>)
        });
        return (<>
            {retdata}
        </>)
    }
    wiz_1=()=> {
        let dis = null;

        if (this.state.display_detail) {

            let tdata = this.state.datas.map(v => {
                let amount=0;
                if(v.re_amount!=="")
                    amount=v.re_amount
                return (<tr>
                    <td><a href="" onClick={event => {this.btnrefund(event,v.id)}}>Refund</a></td>
                    <td>{v.amount}</td>
                    <td>{v.payment_type}</td>
                    <td>{v.req_by}</td>
                    <td>{v.app_by}</td>
                    <td>{v.given_by}</td>
                    <td>{amount}</td>
                </tr>)
            })
            let lst = null
            if (this.state.data.fuels != null)
                lst = this.state.data.fuels.map(value => {
                    return <li>{value.station} ({value.fuel})</li>
                })
            dis = <div className="col-md-12">
                <div className="card">
                    <div className="card-header"
                         style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                        <h3 className="card-title">Fright Detail</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Truck #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.plate_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Driver Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.driver_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Trailer No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.trailer_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fright #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.fright_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Client</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Order #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.order_no}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cargo</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.cargo_name}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Distance</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.distance}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Volume</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.volume}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel (L)</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.total_fuel}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Start @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.start_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>ORG</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.org}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>DES</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.des}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>End @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.end_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel</label>
                                    <ul>
                                        {lst}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                            </div>
                            <div className="col-md-12">
                                <table className="table table-head-fixed table-bordered">
                                    <thead>
                                    <tr>
                                        <th className="col-md-1">Action</th>
                                        <th className="col-md-2">Amount</th>
                                        <th className="col-md-1">Payment Type</th>
                                        <th className="col-md-2">REQ_BY</th>
                                        <th className="col-md-2">APP_BY</th>
                                        <th className="col-md-2">PAID_BY</th>
                                        <th className="col-md-2">Re_Fund Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return (
            <>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                            <h3 className="card-title">Fright Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Fright #</label>
                                            <input className="form-control" type="text"
                                                   name="fright_no" value={this.state.fright_no}
                                                   onChange={this.inputmgt} placeholder="Fright #"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer clearfix">
                            <button type="button" className="btn float-right"
                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                    onClick={this.btnget_report}>Get Fright
                            </button>
                        </div>
                    </div>
                </div>
                {dis}
            </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                            <h3 className="card-title">Refund</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="text" className="form-control form-control-sm"
                                               value={this.state.amount} readOnly={true}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <input type="text" className="form-control form-control-sm"
                                               value={this.state.payment_type} readOnly={true}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>REQ_BY</label>
                                        <input type="text" className="form-control form-control-sm"
                                               value={this.state.req_by} readOnly={true}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>APP_BY</label>
                                        <input type="text" className="form-control form-control-sm"
                                               value={this.state.app_by} readOnly={true}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>PAID_BY</label>
                                        <input type="text" className="form-control form-control-sm"
                                               value={this.state.given_by} readOnly={true}/>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Refund Amount</label>
                                        <input type="text" className="form-control form-control-sm" name="re_amount"
                                               value={this.state.re_amount} onChange={this.inputmgt}
                                               placeholder="AMOUNT"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="re_remark"
                                               value={this.state.re_remark} onChange={this.inputmgt}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Recivied By</label>
                                        <input type="text" className="form-control form-control-sm"
                                               value={this.state.re_rec_by} readOnly={true}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Received AT</label>
                                        <input type="text" className="form-control form-control-sm"
                                               value={this.state.re_rec_at} readOnly={true}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                onClick={this.btncreate}> Create Refund</button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    btnrefund = (event,id) => {
        event.preventDefault();
        let temp=this.state.datas.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                re_amount: temp[0].re_amount,
                re_remark: temp[0].re_remark,
                re_rec_by: temp[0].re_rec_by,
                re_rec_at: temp[0].re_rec_at,
                amount: temp[0].amount,
                payment_type: temp[0].payment_type,
                req_by: temp[0].req_by,
                app_by: temp[0].app_by,
                given_by: temp[0].given_by,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    render() {
        let dis=null;
        if(this.state.wiz===1)
            dis=this.wiz_1()
        else if(this.state.wiz===2)
            dis=this.wiz_2()
        return (
            <>
                <Nav type="opex_cost_refund"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Opex Cost Refund</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_cost_refund;
