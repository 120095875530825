import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Report_current_status extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],

            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.report_current_status;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
                <td>{value.allocated_date}</td>
                <td>{value.label_order}</td>
                <td><p title={value.long_name}>{value.short_name}</p></td>
                <td>{value.update_time}</td>
                <td>{value.status}</td>
                <td>{value.date_dispatch_from_dj}</td>
                <td>{value.day_dispatch_from_dj}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 px-3 py-1 text-left"><a href="#" onClick={this.btnexport_report}>Download .xlsx</a></div>
                    <div className="col-12">
                        <table id="report" className="table table-head-fixed table-responsive">
                            <thead>
                            <tr>
                                <th className="col-md-1">#</th>
                                <th className="col-md-1">TRUCK NO.</th>
                                <th className="col-md-1">DRIVER NAME</th>
                                <th className="col-md-1">TRAILER #</th>
                                <th className="col-md-1">ALLOCATED DATE</th>
                                <th className="col-md-2">ORDER</th>
                                <th className="col-md-1">LOCATION</th>
                                <th className="col-md-1">TIME</th>
                                <th className="col-md-1">STATUS</th>
                                <th className="col-md-1">Date Dispatched From Dj</th>
                                <th className="col-md-1">Day Since DJ dispatch</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="report_current_status"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Report Current Status</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Report_current_status;
