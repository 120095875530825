import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Report_status extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],

            wiz: 1,
            iscreate: false,
            id: -1,
            name: '',
            clients: [],
            client_selected: null,

            orders: [],
            order_selected: null,
            btn_text:'Get Report',
            is_exe:false,
        }
    }

    handelchange_client = (client_selected) => {
        this.setState({
            orders: [],
            order_selected: null,
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_order_for_report_status;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                client_id: client_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        orders: res.data.orders,
                        client_selected: client_selected,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    handelchange_order = (order_selected) => {
        this.setState({
            order_selected: order_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_customer_for_report_status;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    clients: res.data.customers,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    get_report = (event) => {
        event.preventDefault()
        if (this.state.order_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            if (this.state.is_exe) {
                Swal.fire({
                    title: 'Info!',
                    text: "Wait for the first request completed",
                    icon: 'info',
                    confirmButtonText: 'Okay'
                })
            }
            else {
                this.setState({
                        btn_text: "Fetching...",
                        is_exe: true,
                        datas: []
                    },
                    () => {
                        let redirecttologin = myconfig.redirecttologin;
                        let baseurl = myconfig.report_status;
                        axios.post(baseurl, {
                            sid: window.localStorage.getItem("logmgtsys_sid"),
                            order_id: this.state.order_selected.value,
                        }, {cancelToken: source.token}).then(res => {
                            console.log(res.data)
                            if (res.data.auth == false) {
                                window.localStorage.setItem("logmgtsys_sid", null);
                                window.localStorage.setItem("logmgtsys_username", null);
                                window.localStorage.setItem("logmgtsys_name", null);
                                var win = window.open(redirecttologin, "_self");
                            } else if (res.data.success == false) {
                                Swal.fire({
                                    title: 'Error!',
                                    text: res.data.msg,
                                    icon: 'error',
                                    confirmButtonText: 'Okay'
                                })
                                this.setState({
                                    datas: res.data.datas,
                                    btn_text: "Get Report",
                                    is_exe: false,
                                })
                            } else {
                                this.setState({
                                    datas: res.data.datas,
                                    btn_text: "Get Report",
                                    is_exe: false,
                                })
                            }
                        }).catch((e) => {
                            console.log(e.message);
                        });
                    })
            }
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.label_order}</td>
                <td>{value.plate_no}</td>
                <td>{value.trailer_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.phone_no}</td>
                <td>{value.date_dispatch_from_dj}</td>
                <td><p title={value.long_name}>{value.short_name}</p></td>
                <td>{value.day_dispatch_from_dj}</td>
                <td>{value.allocated_date}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 px-3 py-1 text-left"><a href="#" onClick={this.btnexport_report}>Download .xlsx</a></div>
                    <div className="col-12">
                        <table id="report" className="table table-bordered table-responsive">
                            <thead>
                            <tr>
                                <th className="col-md-1">No</th>
                                <th className="col-md-2">Order</th>
                                <th className="col-md-1">Plate No</th>
                                <th className="col-md-1">Trailer No</th>
                                <th className="col-md-1">Driver Name</th>
                                <th className="col-md-1">Phone Number</th>
                                <th className="col-md-1">Date Dispatched From Dj</th>
                                <th className="col-md-1">Current Location</th>
                                <th className="col-md-1">Day Since DJ dispatch</th>
                                <th className="col-md-1">Date Assigned</th>
                                <th className="col-md-1">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="report_status"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header"
                                     style={{backgroundColor: myconfig.color_1, color: myconfig.color_3}}>
                                    <h3 className="card-title">Status Report</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-header" style={{
                                                    backgroundColor: myconfig.color_2,
                                                    color: myconfig.color_3
                                                }}>
                                                    <h3 className="card-title">Report Criteria</h3>
                                                    <div className="card-tools">
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Clients</label>
                                                                <Select
                                                                    value={this.state.client_selected}
                                                                    onChange={this.handelchange_client}
                                                                    options={this.state.clients}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Orders</label>
                                                                <Select
                                                                    value={this.state.order_selected}
                                                                    onChange={this.handelchange_order}
                                                                    options={this.state.orders}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <button className="btn btn-block btn-sm"
                                                                        onClick={this.get_report} style={{
                                                                    color: myconfig.color_3,
                                                                    backgroundColor: myconfig.color_1
                                                                }}> {this.state.btn_text}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8"></div>
                                        <div className="col-md-12">
                                            {dis}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </>
        )
    }
}

export default Report_status;
