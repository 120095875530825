import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Opex_fuel_giver extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',

            fules:[],
            fuel_details:[],
            fright_data:{},

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_opex_fuel_giver;
        let fright_no = new URLSearchParams(this.props.location.search).get("fright_no");
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
            wiz:1,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no: fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                },()=>{
                    if(res.data.isfound){
                        let temp=this.state.filtered.filter(v=>{
                            if(v.finance_cost_id===res.data.finance_cost_id)
                                return v
                        })
                        if(temp.length>0){
                            this.manage(temp[0])
                        }
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.plate_no.toUpperCase().includes(value.toUpperCase()) ||
                    v.ref_no.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_txt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        if (isNaN(value)) {
            value = "";
        }
        let temp = this.state.fuel_details.map(v => {
            if (v.fuel_data_id == name){
                v.fuel = value;
            }

            return v;
        });
        this.setState({
            [name]: value,
            fuel_details: temp,
        })
    }
    inputmgt_cbo = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.fuel_details.map(v => {
            if (v.fuel_data_id == name){
                v.type = value;
            }

            return v;
        });
        this.setState({
            [name]: value,
            fuel_details: temp,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 3,
        })
    }
    btn_manage = (event,value) => {
        event.preventDefault();
        this.manage(value)
        //get the history of the vehicle with requested and fine
    }
    manage = (value) => {
        this.setState({
            fules:[],
            fuel_details:[],
            fright_data:{},
        },()=>{
            let fules=[];
            let fuel_details=[];
            let fright_data={};
            let temp=this.state.filtered.filter(v=>{
                if(v.finance_cost_id===value.finance_cost_id)
                    return v;
            })
            if(temp.length>0){
                fules=temp[0].fright_data.fules;
                fuel_details=temp[0].fright_data.fuel_details;
                fright_data=temp[0].fright_data;
            }
            this.setState({
                wiz: 2,
                finance_cost_id:value.finance_cost_id,
                vehicle_id:value.id,
                plate_no:value.plate_no,
                amount:value.amount,
                fules:fules,
                fuel_details:fuel_details,
                fright_data:fright_data,
            })
        })

        //get the history of the vehicle with requested and fine
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btnpaid = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to approve the record? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.opex_fuel_giver;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    finance_cost_id: this.state.finance_cost_id,
                    fuel_details: this.state.fuel_details,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            if(res.data.redirect)
                            {
                                let url_2=myconfig.redirecttofinance_document+"?ref_no="+res.data.ref_nos;
                                var win = window.open(url_2, "_blank");
                            }
                            this.fetchdata()
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            return (
                <tr>
                    <td>
                        <a href="" onClick={event => this.btn_manage(event,value)}>Issue Fuel</a>
                    </td>
                    <td>{value.plate_no}</td>
                    <td>{value.fright_no}</td>
                    <td>{value.amount}</td>
                    <td>{value.remark}</td>
                    <td>{value.req_by}</td>
                    <td>{value.req_at}</td>
                    <td>{value.app_by}</td>
                    <td>{value.app_at}</td>
                    <td>{value.ref_no}</td>
                </tr>
            )
        })

        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed table-responsive">
                        <thead>
                        <tr>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-2">Truck No</th>
                            <th className="col-md-1">Fright #</th>
                            <th className="col-md-1">Amount</th>
                            <th className="col-md-1">Remark</th>
                            <th className="col-md-1">REQ BY</th>
                            <th className="col-md-1">REQ AT</th>
                            <th className="col-md-1">APP BY</th>
                            <th className="col-md-1">APP AT</th>
                            <th className="col-md-1">Ref_No</th>

                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let ls=this.state.fules.map(v=>{
            return (<li>{v.station}: ({v.fuel})</li>)
        })
        let af=0;
        let tdata=this.state.fuel_details.map(v=>{
            if(!isNaN(v.fuel) && v.fuel!=="")
                af=(af*1)+(v.fuel*1);
            return (
                <tr>
                    <td>{v.station}</td>
                    <td>{v.fuel_bud}</td>
                    <td><input className="form-control form-control-sm"
                               name={v.fuel_data_id}
                               value={v.fuel}
                               onChange={this.inputmgt_txt}
                    /> </td>
                    <td>
                        <select name={v.fuel_data_id} className="form-control form-control-sm" value={v.type} onChange={this.inputmgt_cbo}>
                            <option value="-1">--Select Type--</option>
                            <option value="Pad">Pad</option>
                            <option value="Coupon">Coupon</option>
                            <option value="Birr">Birr</option>
                        </select>
                    </td>
                </tr>)
        })
        let tdata_2=null;
        if(this.state.fuel_details.length>0){
            let loops=[];
            af=(af*1).toFixed(2)
            let dif=(this.state.amount*1)-af;
            dif=(dif*1).toFixed(2)
            loops.push(
                {
                    d1:"BUGETED FUEL",
                    d2:(this.state.amount*1).toFixed(2),
                },
                {
                    d1:"ACTUAL FUEL",
                    d2:af,
                },
                {
                    d1:"BF-AF",
                    d2:dif,
                },
            )
            tdata_2=loops.map(v=>{
                return (
                    <tr>
                        <th>{v.d1}</th>
                        <td>{v.d2}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>)
            })
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header "
                             style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Fuel Issue</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Reference #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.fright_data.ref_no}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate No</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.fright_data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.fright_data.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer No</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.fright_data.trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Client Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.fright_data.client_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>ORG</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.fright_data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>DES</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.fright_data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Expected Fuel (L)</label>
                                        <ul>
                                            {ls}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fuel from PRV trip (L)</label>
                                        <p>{this.state.fright_data.dif}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fuel to be Issue (L)</label>
                                        <p>{this.state.amount}</p>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="form-group">
                                        <label>Fuel Detail</label>
                                        <table className="table table-sm">
                                            <tr>
                                                <th className="col-md-3">STATION</th>
                                                <th className="col-md-3">FUEL BUDGETED</th>
                                                <th className="col-md-3">FUEL ACTUAL</th>
                                                <th className="col-md-3">FILL TYPE</th>
                                            </tr>
                                            {tdata}
                                            {tdata_2}
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                onClick={this.btnpaid}> Fuel Issue </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz: 1,
                                                    })
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="opex_fuel_giver"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">OPEX Vehicle FUEL ISSUE</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_fuel_giver;
