import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Order_update extends Component {

    constructor() {
        super();
        this.state = {
            wiz:1,
            len:[],
            data:{},
            client:'',
            des:'',
            new_des:'',
            ini_loc:'',
            fin_loc:'',
            cargo:'',
            st_date:'',
            ed_date:'',
            new_st_date:'',
            new_ed_date:'',
            weight:'',
            transport_rate:'',
            new_weight:'',
            new_transport_rate:'',
            purchase_no:'',
            new_purchase_no:'',
            clients: [],
            cargos: [],
            locations: [],
            urls: [],

            client_selected: null,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,

            reports: [],
            display_detail: false,
            allocation:'',
            arivalatloading:'',
            loading:'',
            departure:'',
            arivalatunloading:'',
            unloading:'',
            finished:'',
        }
    }

    componentDidMount() {

    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    btn_get = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_open_update;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            no:this.state.no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id:res.data.data.id,
                    data:res.data.data,
                    urls:res.data.urls,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    draw_approval = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.draw_approval_order_update;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        wiz: 1,
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_1=()=> {
        return (<>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header"
                         style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                        <h3 className="card-title">Order Information</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input className="form-control" type="text"
                                               name="no" value={this.state.no}
                                               onChange={this.inputmgt} placeholder="Order #"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn float-right"
                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                onClick={this.btn_get}>Get Transport Order
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let imgs=this.state.urls.map(v=>{
            return (<div className="col-md-11 text-center">
                <img src={v.url}/>
            </div> )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header"
                             style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                            <h3 className="card-title">Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-9">

                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.client}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea rows={5} className="form-control form-control-sm"
                                                  onChange={this.inputmgt} readOnly={true}>{this.state.data.des_good}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Modal Of Transport</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.mode_of_transport}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>PO #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.purchase_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.weight}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.payment_type}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Bank Deposit #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.bank_tt}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Agreement #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.agreement_no}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                </div>
                                {imgs}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                onClick={this.draw_approval}> Draw Approval
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-sm"
                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}> Close
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="order_update"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Transport Order Update </h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Update</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
    //
}

export default Order_update;
