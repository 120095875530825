import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";

let source = axios.CancelToken.source();

class Mobile_customer_report extends Component {

    constructor() {
        super();
        this.state = {
            client_token:'',
            datas:[],
            sums:[],
            dis:1,

            order_id:-1,
            sums_2:[],
            filter_sums_2:[],
            filter:"all",
        }
    }

    componentDidMount() {
        let client_token = new URLSearchParams(this.props.location.search).get("client_token");
        this.setState({
            client_token: client_token,
        }, () => {
            this.fetchdata()
        })
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.mobile_customer_report;
        let client_token = new URLSearchParams(this.props.location.search).get("client_token");

        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            client_token:client_token,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                this.setState({
                    dis:2,
                })
            } else {
                this.setState({
                    datas:res.data.datas,
                    sums:res.data.sums,
                    sums_2:res.data.sums_2,
                    dis:3,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    render() {
        let url=myconfig.redirecttomobile_home+"?client_token="+this.state.client_token
        let dis=null;

        if(this.state.dis===1)
            dis=<p>Loading...</p>

        else if(this.state.dis===2)
            dis=<p>Unauthorized usage</p>

        else if(this.state.dis===3) {
            let sums = this.state.sums.map(v => {
                return (
                    <p>
                        <a href="" onClick={event => {
                            event.preventDefault()

                            let filter_sums_2=this.state.sums_2.filter(v2=>{
                                if(v2.order_id===v.order_id)
                                    return v2;
                            })
                            this.setState({
                                filter_sums_2:filter_sums_2,
                                order_id:v.order_id,
                                dis:4,
                            })
                        }}>
                            {v.order_label} ({v.count})
                        </a>
                    </p>
                )
            })
            dis = <>
                {sums}
            </>
        }

        else if(this.state.dis===4) {
            let sums = this.state.filter_sums_2.map(v => {
                return (
                    <p>
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let filter="all"
                            if(v.label=="Operation Not Done")
                                filter="not_done"
                            else if(v.label=="Operation Done")
                                filter="done"
                            this.setState({
                                filter:filter
                            })
                        }}>
                            {v.label} ({v.count})
                        </a>
                    </p>
                )
            })
            let count = 0;
            let filtered_datas=[]

            if(this.state.filter=="all"){
                filtered_datas=this.state.datas.filter(v=>{
                    if(v.order_id==this.state.order_id)
                        return v;
                })
                console.log(filtered_datas)
            }
            else if(this.state.filter=="done")
            {
                filtered_datas=this.state.datas.filter(v=>{
                    if(
                        v.order_id==this.state.order_id
                        &&
                        (
                            v.status=="OPERATION DONE"||
                            v.status=="GPS NOT WORKING"
                        )
                    )
                        return v;
                })
            }
            else{
                filtered_datas=this.state.datas.filter(v=>{
                    if(
                        v.order_id==this.state.order_id
                        &&
                        (
                            v.status!="OPERATION DONE" &&
                            v.status!="GPS NOT WORKING"
                        )
                    )
                        return v;
                })
            }

            let tdata = filtered_datas.map(value => {
                count=count+1;
                return (<tr>
                    <td>{count}</td>
                    <td>{value.plate_no}</td>
                    <td>{value.trailer_no}</td>
                    <td>{value.driver_name}</td>
                    <td>{value.phone_no}</td>
                    <td>{value.date_dispatch_from_dj}</td>
                    <td><p title={value.long_name}>{value.short_name}</p></td>
                    <td>{value.day_dispatch_from_dj}</td>
                    <td>{value.allocated_date}</td>
                    <td>{value.status}</td>
                </tr>)
            })
            dis = <>
                <a href="" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        dis:3
                    })
                }}>Back 2</a>
                {sums}
                <table>
                    <tr>
                        <th>No</th>
                        <th>Plate No</th>
                        <th>Trailer No</th>
                        <th>Driver Name</th>
                        <th>Phone Number</th>
                        <th>Date Dispatched From Dj</th>
                        <th>Current Location</th>
                        <th>Day Since DJ dispatch</th>
                        <th>Date Assigned</th>
                        <th>Status</th>
                    </tr>
                    {tdata}
                </table>
            </>
        }
        return (
            <div>
                <a href={url}>Back</a>
                <p>Customer Report</p>
                {dis}
            </div>
        )
    }
}

export default Mobile_customer_report;
