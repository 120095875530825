import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Opex_fuel extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            selected_datas: [],
            ret_datas: [],
            ret_plate: [],

            txtsearch: '',

            wiz: 1,
            iscreate: false,
            id:-1,
            name:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_opex_fuel;
        this.setState({
            datas: [],
            filtered: [],
            selected_datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            name: '',
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                name: temp[0].name,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            name: '',
        })
    }
    btnnext = (event) => {
        event.preventDefault();
        if(this.state.selected_datas.length==0){
            Swal.fire({
                title: 'Error!',
                text: "At least one record must be selected",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.next_1_for_opex_fuel;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicles:this.state.selected_datas,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        wiz:2,
                        ret_datas:res.data.ret_datas,
                        ret_plate:res.data.ret_plate,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnnext_2 = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to proceed the Fuel Issues. You can not undone the operation?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.next_2_for_opex_fuel;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    ret_datas: this.state.ret_datas,
                }, {cancelToken: source.token}).then(res => {
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            }, () => {
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_cargo;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            name:this.state.name,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata1 = this.state.filtered.map(value => {
            return (<tr>
                <td>
                    <a href="" onClick={event => {
                        event.preventDefault();
                        let filtered=this.state.filtered.filter(v=>{
                            if(v.id!==value.id)
                                return v;
                        });
                        let datas=this.state.datas.filter(v=>{
                            if(v.id!==value.id)
                                return v;
                        });
                        let selected_datas=this.state.selected_datas;
                        selected_datas.push({
                            id:value.id,
                            plate_no:value.plate_no,
                            driver_name:value.driver_name,
                            trailer_no:value.trailer_no,
                        })
                        this.setState({
                            filtered:filtered,
                            datas:datas,
                            selected_datas:selected_datas,
                        })
                    }}>Fuel Issue</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
            </tr>)
        })
        let tdata2 = this.state.selected_datas.map(value => {
            return (<tr>
                <td>
                    <a href="" onClick={event => {
                        event.preventDefault();
                        let selected_datas=this.state.selected_datas.filter(v=>{
                            if(v.id!==value.id)
                                return v;
                        });
                        let datas=this.state.datas;
                        datas.push({
                            id:value.id,
                            plate_no:value.plate_no,
                            driver_name:value.driver_name,
                            trailer_no:value.trailer_no,
                        })
                        this.setState({
                            filtered:datas,
                            datas:datas,
                            selected_datas:selected_datas,
                        })
                    }}>Remove From Fuel</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <h4>Fuel Data's</h4>
                        <table id="report" className="table table-head-fixed">
                            <thead>
                            <tr>
                                <th className="col-md-3">Action</th>
                                <th className="col-md-3">Plate #</th>
                                <th className="col-md-3">Driver Name</th>
                                <th className="col-md-3">Trailer No</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata1}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <h4>Selected Data's</h4>
                        <table id="report" className="table table-head-fixed">
                            <thead>
                            <tr>
                                <th className="col-md-3">Action</th>
                                <th className="col-md-3">Plate #</th>
                                <th className="col-md-3">Driver Name</th>
                                <th className="col-md-3">Trailer No</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata2}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button className="btn float-right"
                        style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                        onClick={this.btnnext}
                >Next</button>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata = this.state.ret_plate.map(value => {
            let datas=this.state.ret_datas.filter(value2=>{
                if(value2.vehicle_id==value.id)
                    return value2;
            })
            let tdata2=datas.map(value3=>{
                return (<tr>
                    <td>{value3.reason}</td>
                    <td>{value3.amount}</td>
                    <td>{value3.fuel_station}</td>
                    <td>{value3.fuel_rate}</td>
                    <td>{value3.req_by}</td>
                    <td>{value3.app_by}</td>
                    <td>{value3.app_at}</td>
                </tr>)
            })
            return (<>
                    <tr>
                        <th className="col-md-2">{value.plate_no}</th>
                        <th className="col-md-1"></th>
                        <th className="col-md-2"></th>
                        <th className="col-md-1"></th>
                        <th className="col-md-2"></th>
                        <th className="col-md-2"></th>
                        <th className="col-md-2"></th>
                    </tr>
                    <tr>
                        <th className="col-md-2">{value.driver_name}</th>
                        <th className="col-md-1"></th>
                        <th className="col-md-2"></th>
                        <th className="col-md-1"></th>
                        <th className="col-md-2"></th>
                        <th className="col-md-2"></th>
                        <th className="col-md-2"></th>
                    </tr>
                    <tr>
                        <th className="col-md-2">Reason</th>
                        <th className="col-md-1">Amount(L)</th>
                        <th className="col-md-2">Station </th>
                        <th className="col-md-1">Rate (ETB)</th>
                        <th className="col-md-2">REQ BY</th>
                        <th className="col-md-2">APP BY</th>
                        <th className="col-md-2">APP AT</th>
                    </tr>
                    {tdata2}
                </>
            )
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <h4>Selected Data</h4>
                    <table id="report" className="table table-head-fixed table-bordered table-sm">
                        {tdata}
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button className="btn float-right m-3"
                        style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                        onClick={this.btnnext_2}
                >Next</button>
                <button className="btn float-right m-3"
                        style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                        onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz:1,
                                filtered:this.state.datas,
                            })
                        }}
                >Previous</button>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="opex_fuel"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header" style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Fuel Management</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_fuel;
