import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Opex_summuary extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            reports: [],
            vehicles:[],
            vehicle_selected: null,
        }
    }
    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected: vehicle_selected,
        })
    }
    componentDidMount() {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_opex_summuary;
        this.setState({
            reports: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.datas,
                },()=>{
                    let vehicle_id = new URLSearchParams(this.props.location.search).get("vehicle_id");
                    if (vehicle_id !== null && vehicle_id !== "") {
                        this.fetch_veh_obj(vehicle_id)
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetch_veh_obj = (vehicle_id) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_obj_for_r_movement;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id: vehicle_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(res.data.isfound) {
                    this.setState({
                        st: res.data.st_interface,
                        ed: res.data.ed_interface,
                        vehicle_selected: res.data.vehicle_selected,
                    }, () => {
                        this.getreport(res.data.st, res.data.ed, true)
                    })
                }
               /* Swal.fire({
                    title: 'Success!',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
               */
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btnget_report = (event,st,ed,isdatetime) => {
        event.preventDefault();
        this.getreport(st,ed,isdatetime)
    }
    getreport = (isall,istop5) => {
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.opex_summuary;
            this.setState({
                reports: [],
            })
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                st: this.state.st,
                ed: this.state.ed,
                isall: isall,
                istop5: istop5,
                vehicle_id: this.state.vehicle_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: "Report fetched successfully",
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    this.setState({
                        reports: res.data.reports,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    tabledata=()=> {
        let retdata = this.state.reports.map(value => {
            return (<tr>
                <td>{value.allocated_date}</td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.tra}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.client_name}</td>
                <td>{value.fright_no}</td>
                <td>{value.budjeted_fuel}</td>
                <td>{value.actual_fuel}</td>
                <td>{value.v_fuel}</td>
                <td>{value.budjeted_cost}</td>
                <td>{value.actual_cost}</td>
                <td>{value.v_cost}</td>
            </tr>)
        });
        return (<>
            {retdata}
        </>)
    }
    render() {
        return (
            <>
                <Nav type="opex_summuary"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">OPEX Summary</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Truck No</label>
                                                            <Select
                                                                value={this.state.vehicle_selected}
                                                                onChange={this.handelchange_vehicle}
                                                                options={this.state.vehicles}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="st" value={this.state.st}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="ed" value={this.state.ed}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <button type="button" className="btn float-right mr-2"
                                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        this.getreport(false,true)
                                                    }}>Get Last 3
                                            </button>
                                            <button type="button" className="btn float-right mr-2"
                                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        this.getreport(true,false)
                                                    }}>Get All
                                            </button>
                                            <button type="button" className="btn float-right mr-2"
                                                    style={{color:myconfig.color_3, backgroundColor:myconfig.color_2}}
                                                    onClick={event => {
                                                        event.preventDefault();
                                                        this.getreport(false,false)
                                                    }}>Get By Date
                                            </button>



                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_2,color:myconfig.color_3}}>
                                            <h3 className="card-title">Report Result</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                                               onClick={this.btnexport_report}>Download .xlsx</a></div>
                                                <div className="table" id="section_to_print">
                                                    <table id="report" className="table table-responsive">
                                                        <thead>
                                                        <tr>
                                                            <th>Allocated Date</th>
                                                            <th>Truck No</th>
                                                            <th>Driver Name</th>
                                                            <th>Trailer No</th>
                                                            <th>ORG</th>
                                                            <th>DES</th>
                                                            <th>Client Name</th>
                                                            <th>Fright No</th>
                                                            <th>BUDGETED FUEL</th>
                                                            <th>ACTUAL FUEL</th>
                                                            <th>VARIANCE (BF-AF)</th>
                                                            <th>BUDGETED COST</th>
                                                            <th>ACTUAL COST</th>
                                                            <th>VARIANCE (BC-AC)</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.tabledata()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_summuary;
