import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";

let source = axios.CancelToken.source();

class Mobile_home extends Component {

    constructor() {
        super();
        this.state={
            client_token:'',
        }
    }

    componentDidMount() {
        let client_token = new URLSearchParams(this.props.location.search).get("client_token");
        this.setState({
            client_token:client_token,
        })
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    render() {
        let url_current_status=myconfig.redirecttomobile_current_status+"?client_token="+this.state.client_token
        let url_customer_report=myconfig.redirecttomobile_customer_report+"?client_token="+this.state.client_token
        return (
            <div>
               <p><a href={url_current_status}>Current Status</a></p>
               <p><a href={url_customer_report}>Customer Report</a></p>
            </div>
        )
    }
}

export default Mobile_home;
