import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up_distance_calc extends Component {

    constructor() {
        super();
        this.state = {
            lat1:'',
            lon1:'',
            lat2:'',
            lon2:'',
            result:'',

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btncalc = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.follow_up_distance_calc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            lat1:this.state.lat1,
            lon1:this.state.lon1,
            lat2:this.state.lat2,
            lon2:this.state.lon2,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        result: res.data.distance,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    render() {
        return (
            <>
                <Nav type="follow_up_distance_calc"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header"
                                             style={{backgroundColor:myconfig.color_1,color:myconfig.color_3}}>
                                            <h3 className="card-title">Distance Calculator</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Latitude-1</label>
                                                        <input type="text" className="form-control form-control-sm" name="lat1"
                                                               value={this.state.lat1} onChange={this.inputmgt}
                                                               placeholder="Latitude-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Longitude-1</label>
                                                        <input type="text" className="form-control form-control-sm" name="lon1"
                                                               value={this.state.lon1} onChange={this.inputmgt}
                                                               placeholder="Longitude-1"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Latitude-2</label>
                                                        <input type="text" className="form-control form-control-sm" name="lat2"
                                                               value={this.state.lat2} onChange={this.inputmgt}
                                                               placeholder="Latitude-2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Longitude-2</label>
                                                        <input type="text" className="form-control form-control-sm" name="lon2"
                                                               value={this.state.lon2} onChange={this.inputmgt}
                                                               placeholder="Longitude-2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Result</label>
                                                        <input type="text" className="form-control form-control-sm" name="result"
                                                               value={this.state.result} onChange={this.inputmgt}
                                                               placeholder="Result"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-sm"
                                                                style={{color:myconfig.color_3, backgroundColor:myconfig.color_1}}
                                                                onClick={this.btncalc}> Calculate </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_distance_calc;
